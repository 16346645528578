export default {
  chat: {
    recipients: "Destinataires",
    subject: "Objet",
    message: "Message",
    report: "Signaler",
    report_confirm: "Êtes-vous sûr de vouloir signaler ce message ?",
    report_success: "Merci pour votre signalement !",
    message_success: "Votre message a été envoyé avec succès !",
    signaled: "Vous avez signalé un abus",
    message_notifs: "nouveaux messages",
    message_notif: "nouveau message",
    message_error: "Oups, il y'a eu une erreur lors de l'envoi",
    create_message: "Nouveau message",
    to: "Pour",
    choose_recipients: "Choix des destinataires",
    email_answer_notif: "Recevoir une notification des réponses par email",
    email_recipient_notif:
      "Envoyer une notification aux destinataires par email",
    archived_messages: "Voir les messages archivés",
    archived_messages_list: "Messages archivés",
    receipt_messages: "Voir les messages reçus",
    sending: "Envoi en cours...",
    archive_confirm: "Êtes vous sûr de vouloir archiver ce message ?",
    unarchive_confirm: "Êtes vous sûr de vouloir désarchiver ce message ?",
    delete_title: "Supprimer une conversation",
    delete_confirm: "Êtes vous sûr de vouloir supprimer cette conversation ?",
    delete_success: "Conversation supprimée avec succès !",
    placeholder: "Saisissez votre message...",
    placeholder_subject: "Sujet...",
    select_message: "Cliquez sur une conversation",
  },
};
