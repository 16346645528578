<template>
  <div v-if="questionnaire.useComments" :class="{ onQuestion: onQuestion }">
    <button
      v-if="!commenting"
      class="answer__comment"
      v-tooltip="{ title: this.$t(onQuestion ? 'answerComment' : 'comment') }"
      @click="toggleComment"
    >
      <FontAwesomeIcon icon="fa-light fa-message" />
    </button>
    <form>
      <div
        v-if="commenting"
        class="mt-2 d-flex justify-content-between align-items-start answer__input"
      >
        <div class="w-100">
          <InputTypeComponent :model="commentModel" />
        </div>
        <button
          type="submit"
          class="btn btnStyle btn-success ms-2"
          v-tooltip="{ title: this.$t('sendComment') }"
          @click="sendComment($event)"
        >
          <FontAwesomeIcon icon="fa-light fa-paper-plane" />
        </button>
        <button
          class="btn btn-danger ms-2"
          v-tooltip="{ title: this.$t('cancel') }"
          @click="toggleComment"
        >
          <FontAwesomeIcon icon="fa-light fa-xmark" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { mapState } from "vuex";
import { formValidator } from "@/services/form/form-validator";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "QuestionnaireAddCommentComponent",
  components: {
    InputTypeComponent,
  },
  props: {
    commenting: {
      type: Boolean,
      required: true,
      default: false,
    },
    onQuestion: {
      type: Boolean,
      required: true,
      default: false,
    },
    item: {
      type: [],
      required: true,
      default: [],
    },
    answer: {
      type: [],
      required: true,
      default: [],
    },
  },
  data() {
    return {
      commentModel: null,
    };
  },
  computed: {
    ...mapState({
      questionnaire: (state) => state.questionnaire.questionnaire,
      triad: (state) => state.triad.triad,
      model: (state) => state.questionnaire.model,
    }),
  },
  mounted() {
    this.setCommentModel();
  },
  methods: {
    setCommentModel() {
      this.commentModel = {
        vars: {
          block_prefixes: ["", "text"],
          id: "comment-" + this.answer.id,
          value: "",
          required: true,
          attr: {
            placeholder: this.$t("commentHere"),
          },
        },
      };
    },

    toggleComment() {
      this.commentModel.vars.value = "";
      this.$emit("toggleComment");

      setTimeout(() => {
        const input = document.getElementById("comment-" + this.answer.id);
        console.log(input);
        if (input) {
          input.focus();
        }
      }, 20);
    },

    sendComment(e) {
      e.preventDefault();
      this.$emit("isLoading", true);
      const error = formValidator.validModel(this.commentModel);
      if (!error.length) {
        questionnaireManager
          .addComment(
            this.triad.id,
            this.questionnaire.id,
            this.answer.id,
            this.commentModel.vars.value,
          )
          .then((comment) => {
            this.$emit("commentAdded", {
              comment: comment,
              answer: this.answer,
            });
            this.$emit("isLoading", false);
            this.toggleComment();
            notificationManager.showNotification(
              "success",
              this.$t("comment_add_success"),
            );
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.onQuestion {
  text-align: right;
  margin-top: 5px;

  .answer {
    &__comment {
      position: relative;
      right: initial;
      bottom: initial;
    }
  }
}

.answer {
  &__comment {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: white;
    border: 1px solid var(--profile-color);
    border-radius: 5px;
    color: var(--profile-color);
    transition: all 300ms ease-in-out;

    &:hover,
    &:focus {
      background: var(--profile-color);
      color: $white;
    }
  }

  &__input {
    border-top: 1px dashed $m-color_4;
    padding-top: 8px;
  }
}
</style>
