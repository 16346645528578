<template>
  <div class="container-fluid">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AbsenceLayoutView",
  components: {},
};
</script>

<style lang="scss" scoped></style>
