import store from "@/store";
import { apiConnection } from "@/services/api-connection";
import { fileManager } from "@/services/file/file-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";

const URL_PDF = "/api/attestations/get-pdf";

class AttestationManager {
  getPdf = (year, month) => {
    store.commit("FILE_DOWNLOAD_REQUEST");
    return apiConnection
      .get(
        URL_PDF,
        {
          triad: store.state.triad.triad.id,
          year: year,
          month: month,
        },
        true,
      )
      .then((success) => {
        if (success.data) {
          fileManager.saveFile(
            success.fileName,
            success.data,
            "application/pdf",
          );
        } else {
          notificationManager.showAlert("error", i18n.global.t("error_file"));
        }
        store.commit("FILE_DOWNLOAD_SUCCESS");
      });
  };
}

export const attestationManager = new AttestationManager();
