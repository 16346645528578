import { triadManager } from "@/services/triad/triad-manager";
import { getIdb, setIdb } from "@/indexed-db";
import store from "@/store";
import { skillManager } from "@/services/skill/skill-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";

const state = {
  triad: {},
  triads: {},
  contract: {},
  skills: {},
  subjects: {},
  webLinks: {},
  timelines: {},
  pedagogicDocuments: {},
  generalDocuments: {},
  documentCategories: {},
  documents: {},
  notepads: {},
  triadLoaded: false,
  sessions: {},
  currentSession: {},
  currentSessionSkillReply: {},
};

const mutations = {
  SET_TIMELINES(state, triad) {
    state.timelines = triad.timelines;
  },
  SET_DOCUMENTS(state, triad) {
    state.documents = triad.documents;
    const pedagogicDocuments = triad.documents.find(
      (item) => item.code === "pedagogic",
    );
    if (pedagogicDocuments) {
      state.pedagogicDocuments = pedagogicDocuments.documents;
    }

    const generalDocuments = triad.documents.find(
      (item) => item.code === "general",
    );
    if (generalDocuments) {
      state.generalDocuments = generalDocuments.documents;
    }

    const documentCategories = triad.documents.filter(
      (item) => !["pedagogic", "general"].includes(item.code),
    );
    if (documentCategories) {
      state.documentCategories = documentCategories;
    }
  },
  SET_NOTEPADS(state, triad) {
    state.notepads = triad.notepads;
  },
  SET_TRIAD(state, triad) {
    setIdb(parseInt(triad.id), JSON.stringify(triad), "triad");
    state.triad = triad;
    state.triadLoaded = true;
  },
  INIT_TRIAD_MODULE(state) {
    state.triad = {};
    state.triads = {};
  },
  SET_SKILLS(state, skills) {
    state.skills = skills;
  },
  SET_SKILL_REPLY(state, skillReply) {
    state.triad.lastSkillReply = skillReply;
  },
  SET_CONTRACT(state, triad) {
    state.contract = triad.activeContract ? triad.activeContract : {};
  },
  SET_LESSONS(state, triad) {
    state.subjects =
      triad.trainingCourse && triad.trainingCourse.subjects
        ? triad.trainingCourse.subjects
        : {};
  },
  SET_WEBLINKS(state, triad) {
    state.webLinks =
      triad.trainingCourse && triad.trainingCourse.webLinks
        ? triad.trainingCourse.webLinks
        : {};
  },
  SET_SESSION(state, sessions) {
    state.sessions = sessions;
  },
  SET_CURRENT_SESSION(state, currentSession) {
    state.currentSession = currentSession;
  },
  SET_CURRENT_SESSION_SKILL_REPLY(state, currentSessionSkillReply) {
    state.currentSessionSkillReply = currentSessionSkillReply;
  },
};

const actions = {
  changeTriad({ commit, dispatch }, payload) {
    if (!payload.disableLoading) {
      commit("SET_APP_LOADING", true);
    }
    commit("TOGGLE_TRAINING_COURSE", payload.trainingCourseId);
    window.localStorage.setItem("studea-oldTriad", payload.triadId);
    window.localStorage.setItem(
      "studea-oldTrainingCourse",
      payload.trainingCourseId,
    );
    if (store.state.isInternet) {
      return triadManager.loadOne(payload.triadId).then((triad) => {
        if (triad && typeof triad === "object") {
          dispatch("initTriadDatas", triad);
        }
      });
    } else {
      return getIdb(parseInt(payload.triadId), "triad").then((triad) => {
        if (triad && typeof triad === "object") {
          dispatch("initTriadDatas", JSON.parse(triad));
        }
      });
    }
  },
  initTriadDatas({ commit }, triad) {
    commit("SET_TRIAD", triad);
    commit("SET_TRAINING_COURSE_INFOS", triad);
    commit(
      "SET_SKILLS",
      triad.trainingCourse && triad.trainingCourse.skillCategories
        ? triad.trainingCourse.skillCategories
        : {},
    );
    commit("SET_LESSONS", triad);
    commit("SET_WEBLINKS", triad);
    commit("SET_TIMELINES", triad);
    commit("SET_DOCUMENTS", triad);
    commit("SET_NOTEPADS", triad);
    commit("SET_CONTRACT", triad);
    commit("SET_QUESTIONNAIRE_LIST", triad.questionnaires);
    commit("SET_QUESTIONNAIRE_TO_DO_AND_PROGRESS", {
      triad: triad,
      questionnaires: triad.questionnaires,
    });
    commit("SET_SESSION", triad.trainingCourse.skillSessions);
    setTimeout(function () {
      commit("SET_APP_LOADING", false);
    }, 500);
  },
  sendSkills({ commit }, skills) {
    commit("SAVING_REQUEST");
    return skillManager.postSkills(skills).then((success) => {
      notificationManager.showNotification(
        "success",
        i18n.global.t("Skills saved successfully"),
      );
      commit("SAVING_SUCCESS");
      return success;
    });
  },
  fetchSkills({ commit }) {
    return skillManager.getTriadSkills().then((skills) => {
      commit("SET_SKILLS", skills.skillCategories);
      commit("SET_SKILL_REPLY", skills.lastSkillReply);
      return skills;
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
