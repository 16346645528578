import { apiConnection } from "@/services/api-connection";

const URL_POST = "/api/document/post";
const URL_REMOVE = "/api/document/remove";

class DocumentManager {
  postNewDocument = (object) => {
    return apiConnection
      .post(
        URL_POST,
        {
          id: object.id,
          triad: object.triad,
          title: object.title,
          fileData: object.fileData,
          fileName: object.fileName,
          filePath: object.filePath,
          notif: object.notif,
          integrate: object.integrate,
          type: object.type,
          note: object.text,
          rights: object.rights,
        },
        true,
      )
      .then((response) => response);
  };

  removeDocument = (id, triad) => {
    return apiConnection
      .post(
        URL_REMOVE,
        {
          triad: triad,
          id: id,
        },
        true,
      )
      .then((response) => response);
  };

  form(trainingCourseId, triadId, params = {}) {
    return apiConnection
      .get(
        "/app/document/" + trainingCourseId + "/" + triadId + "/form",
        params,
      )
      .then((response) => response);
  }

  list(trainingCourseId, triadId, type = null, params = {}) {
    return apiConnection
      .get(
        "/app/document/" +
          trainingCourseId +
          "/" +
          triadId +
          "/list" +
          (type ? "-" + type : ""),
        params,
      )
      .then((response) => response);
  }

  post(trainingCourseId, params = {}, triadId = null) {
    const url = "/app/document/" + trainingCourseId + "/" + triadId + "/";
    return apiConnection.post(url, params);
  }
}

export const documentManager = new DocumentManager();
