export default {
  page: {
    home: "Accueil",
    search: "Recherche",
    skills: "Les Compétences",
    dashboard: "Tableau de bord",
    questionnaires: "Les questionnaires",
    weblinks: "Vos liens utiles",
    gradebook: "Vos bulletins de notes",
    attestations: "Vos attestations de présence",
    message: "Messagerie",
    shared_docs: "Vos documents partagés",
    lessons: "Vos cours",
    timetable: "Votre emploi du temps",
    absence: "Gestion de l'assiduité",
    profile: "Profil",
    questionnaire: "Questionnaire",
    training_course_list: "Liste des formations",
    login: "Connexion",
    new_message: "Nouveau message",
  },
};
