<template>
  <div>
    <VueDraggableNext
      class="element"
      v-for="variable in model.vars.choices"
      :key="variable.value"
    >
      <span class="badge bg-success btnStyle me-2 mb-2"
        >%{{ variable.value }}%</span
      >
    </VueDraggableNext>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";

export default {
  name: "TemplateVariableTypeComponent",
  components: { VueDraggableNext },
  props: {
    model: {
      default: [],
      type: [],
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.element {
  display: inline;
}
</style>
