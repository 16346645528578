<template>
  <div class="form-group mt-3 text-center">
    <button
      :class="
        'btn btn-primary' +
        (defaultColors ? '' : ' btnStyle') +
        (itemModel && itemModel.stacked ? ' w-100 mb-2' : ' mx-2')
      "
      @click="submit($event)"
    >
      {{
        itemModel && itemModel.submitText ? itemModel.submitText : "Enregistrer"
      }}
    </button>
    <button
      v-if="
        !itemModel ||
        !Object.keys(itemModel).length ||
        (itemModel && itemModel.vars && !itemModel.vars.hideCancel)
      "
      :class="
        'btn btn-danger' + (itemModel && itemModel.stacked ? ' w-100' : ' mx-2')
      "
      @click="cancel($event)"
    >
      {{ itemModel && itemModel.cancelText ? itemModel.cancelText : "Annuler" }}
    </button>
  </div>
</template>

<script>
export default {
  name: "SubmitTypeComponent",
  props: {
    model: {
      type: [],
      required: false,
      default: [],
    },
    defaultColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      itemModel: this.model,
    };
  },
  components: {},
  methods: {
    submit(e) {
      e.preventDefault();
      this.$emit("submitForm");
    },
    cancel(e) {
      e.preventDefault();
      this.$emit("cancelForm");
    },
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}
</style>
