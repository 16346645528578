<template>
  <div class="widget widget-summary-1">
    <div class="widget-heading">
      <h2 class="textStyle">{{ $t("admin.statistic.title") }}</h2>
    </div>
    <div class="widget-content">
      <div class="panel-body rounded-pills-icon tabs">
        <ul
          id="statisticTab"
          class="nav nav-pills mb-4 mt-3 justify-content-center flex-md-row flex-column"
          role="tablist"
        >
          <li class="nav-item ms-md-2 me-md-2 me-0 ms-0" role="presentation">
            <a
              id="tab-general-tab"
              ref="ref-general"
              aria-controls="tab-general"
              :aria-selected="true"
              class="nav-link mb-2 text-center bgStyle active"
              href="#tab-general"
              data-bs-toggle="pill"
              role="tab"
            >
              <FontAwesomeIcon icon="fa-light fa-gear" />
              {{ $t("admin.statistic.general") }}
            </a>
          </li>
          <li class="nav-item ms-md-2 me-md-2 me-0 ms-0" role="presentation">
            <a
              id="tab-formular-tab"
              ref="ref-formular"
              aria-controls="tab-formular"
              :aria-selected="false"
              class="nav-link mb-2 text-center bgStyle"
              href="#tab-formular"
              data-bs-toggle="pill"
              role="tab"
            >
              <FontAwesomeIcon icon="fa-light fa-file-signature" />
              {{ $t("admin.statistic.formular") }}
            </a>
          </li>
          <li class="nav-item ms-md-2 me-md-2 me-0 ms-0" role="presentation">
            <a
              id="tab-absence-tab"
              ref="ref-absence"
              aria-controls="tab-absence"
              :aria-selected="false"
              class="nav-link mb-2 text-center bgStyle"
              href="#tab-absence"
              data-bs-toggle="pill"
              role="tab"
            >
              <FontAwesomeIcon icon="fa-light fa-calendar-days" />
              {{ $t("admin.statistic.absence") }}
            </a>
          </li>
          <li class="nav-item ms-md-2 me-md-2 me-0 ms-0" role="presentation">
            <a
              id="tab-course-tab"
              ref="ref-course"
              aria-controls="tab-course"
              :aria-selected="false"
              class="nav-link mb-2 text-center bgStyle"
              href="#tab-course"
              data-bs-toggle="pill"
              role="tab"
            >
              <FontAwesomeIcon icon="fa-light fa-book" />
              {{ $t("admin.statistic.course") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="filters">
      <FormComponent
        @update="update"
        :models="filter"
        :disableFocusAnimation="true"
        :hideSubmit="true"
      />
    </div>
    <div class="widget-content">
      <div id="statisticTabContent" class="tab-content">
        <div
          id="tab-general"
          aria-labelledby="tab-general-tab"
          class="tab-pane fade show active"
          role="tabpanel"
        >
          <NumberCardChart :stats="stats" />
        </div>
        <div
          id="tab-formular"
          aria-labelledby="tab-formular-tab"
          class="tab-pane fade"
          role="tabpanel"
        >
          <NumberCardChart :stats="stats" />
          <ApexChart
            :options="chartOptions"
            :series="chartSeries"
            height="325"
            type="bar"
          />
        </div>
        <div
          id="tab-absence"
          aria-labelledby="tab-absence-tab"
          class="tab-pane fade"
          role="tabpanel"
        >
          <NumberCardChart :stats="stats" />
          <ApexChart
            :options="chartOptions"
            :series="chartSeries"
            height="325"
            type="bar"
          />
        </div>
        <div
          id="tab-course"
          aria-labelledby="tab-course-tab"
          class="tab-pane fade"
          role="tabpanel"
        >
          <NumberCardChart :stats="stats" />
          <ApexChart
            :options="chartOptions"
            :series="chartSeries"
            height="325"
            type="bar"
          />
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import NumberCardChart from "@/components/charts/NumberCardChart.vue";
import ApexChart from "vue3-apexcharts";
import { adminManager } from "@/services/admin/admin-manager";

export default {
  name: "StatisticView",
  components: { ApexChart, NumberCardChart, BlockLoader, FormComponent },
  data() {
    return {
      loading: false,
      params: [],
      filter: {
        institution: {
          vars: {
            name: "institution",
            label: "Organisme",
            id: "institution",
            value: "",
            block_prefixes: ["", "choice"],
            expandable: false,
            multiple: false,
            row_attr: {
              class: "col-md-3",
            },
            choices: [
              {
                label: "Toutes",
                value: "all",
                data: "all",
              },
              {
                label: "CFA Agricole de Dardilly",
                value: "2",
                data: "2",
              },
              {
                label: "AFIP",
                value: "1",
                data: "1",
              },
            ],
          },
        },
        training: {
          vars: {
            name: "training",
            label: "Formation",
            id: "training",
            value: "",
            block_prefixes: ["", "choice"],
            expandable: false,
            multiple: false,
            row_attr: {
              class: "col-md-3",
            },
            choices: [
              {
                label: "Toutes",
                value: "all",
                data: "all",
              },
              {
                label: "BTS - Test Studea",
                value: "2",
                data: "2",
              },
              {
                label: "DUT - Test",
                value: "1",
                data: "1",
              },
            ],
          },
        },
        trainingCourse: {
          vars: {
            name: "trainingCourse",
            label: "Promotion",
            id: "trainingCourse",
            value: "",
            block_prefixes: ["", "choice"],
            expandable: false,
            multiple: false,
            row_attr: {
              class: "col-md-3",
            },
            choices: [
              {
                label: "Toutes",
                value: "all",
                data: "all",
              },
              {
                label: "BTS - Test Studea",
                value: "2",
                data: "2",
              },
              {
                label: "DUT - Test",
                value: "1",
                data: "1",
              },
            ],
          },
        },
        archive: {
          vars: {
            name: "archive",
            label: "Archivé",
            id: "archive",
            value: false,
            block_prefixes: ["", "switch"],
            expandable: false,
            multiple: false,
            row_attr: {
              class: "col-md-3",
            },
          },
        },
      },
      stats: [
        {
          label: "Formations et promotions actives",
          datas: [
            {
              label: "Formation / UFA",
              total: 59,
            },
            {
              label: "Promotions",
              total: 92,
            },
            {
              label: "Trinômes",
              total: 1381,
            },
          ],
        },
        {
          label: "Utilisateurs sur promotions actives",
          datas: [
            {
              label: "Utilisateurs",
              total: 4063,
              connected: 89.4,
              unconnected: 10.6,
            },
            {
              label: "Apprentis",
              total: 1381,
              connected: 90.6,
              unconnected: 9.4,
              download: true,
            },
            {
              label: "Tuteurs",
              total: 1223,
              connected: 97.6,
              unconnected: 2.4,
              download: true,
            },
            {
              label: "Maîtres d'apprentissage",
              total: 1372,
              connected: 81.2,
              unconnected: 18.8,
              download: true,
            },
            {
              label: "Chargés de Livret",
              total: 87,
              connected: 86.2,
              unconnected: 13.8,
              download: true,
            },
          ],
        },
      ],
      chartOptions: {
        chart: {
          fontFamily: "Nunito, sans-serif",
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        title: {
          display: true,
          text: "Taux de signature",
          font: { weight: "normal", fontFamily: "Nunito, sans-serif" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, curve: "smooth", width: 2, lineCap: "square" },
        dropShadow: {
          enabled: true,
          opacity: 0.2,
          blur: 10,
          left: -7,
          top: 22,
        },
        colors: this.isDark
          ? ["#2196f3", "#e7515a", "#f8a007", "#6ac7a4"]
          : ["#1b55e2", "#e7515a", "#f8a007", "#6ac7a4"],
        markers: {
          discrete: [
            {
              seriesIndex: 0,
              dataPointIndex: 6,
              fillColor: "#1b55e2",
              strokeColor: "#fff",
              size: 7,
            },
          ],
        },
        xaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
          crosshairs: { show: true },
          labels: {
            offsetX: 0,
            offsetY: 5,
            style: {
              fontSize: "12px",
              fontFamily: "Nunito, sans-serif",
              cssClass: "apexcharts-xaxis-title",
            },
          },
          categories: [
            "Global",
            "Apprenti",
            "Tuteur",
            "Maître d'apprentissage",
          ],
        },
        grid: {
          borderColor: this.isDark ? "#191e3a" : "#e0e6ed",
          strokeDashArray: 5,
          xaxis: { lines: { show: true } },
          yaxis: { lines: { show: false } },
          padding: { top: 0, right: 0, bottom: 0, left: 0 },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetY: 0,
          fontSize: "16px",
          fontFamily: "Nunito, sans-serif",
          markers: {
            width: 10,
            height: 10,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: { horizontal: 20, vertical: 5 },
        },
        tooltip: { theme: "dark", marker: { show: true }, x: { show: false } },
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: this.isDark ? 0.19 : 0.28,
            opacityTo: 0.05,
            stops: this.isDark ? [100, 100] : [45, 100],
          },
        },
      },
      chartSeries: [
        {
          data: [42, 45, 52, 42],
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getDatas();
    },

    update() {
      this.params = formManager.processForm(this.filter);
      this.getDatas();
    },

    getDatas() {
      this.loading = true;
      adminManager.list("training").then((data) => {
        console.log(data);
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.export {
  height: 100%;

  &__btn {
    width: 100%;
    color: $white;
    padding: 10px 15px;
    height: 100%;
    border-radius: 6px;
    border: 1px dashed;

    &:hover {
      background: $white !important;
      color: $admin-color !important;
    }
  }

  h6 {
    color: inherit;
    font-size: 14px;
    margin-top: 5px;
  }
}

.filters {
  padding: 20px 20px 10px 20px;
  border-bottom: 1px dashed $m-color_3;
  border-top: 1px dashed $m-color_3;
}

a.nav-link {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px !important;
  background: $light-grey;
  color: var(--secondary-color) !important;

  @include up-to-md {
    width: 100% !important;
  }

  &.active {
    background-color: var(--secondary-color) !important;
    color: $white !important;
  }

  svg {
    font-size: 25px;
  }
}
</style>
