<template>
  <div ref="infinite">
    <slot />
    <div class="text-center">
      <span
        v-if="busy"
        class="mb-2 spinner-border align-self-center loader-sm"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfiniteScrollComponent",
  props: {
    busy: {
      type: Boolean,
      required: true,
      default: false,
    },
    complete: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    this.initScrollEvent();
  },
  beforeUnmount() {
    this.removeScrollEvent();
  },
  methods: {
    initScrollEvent() {
      window.addEventListener("scroll", this.onScroll);
    },

    removeScrollEvent() {
      window.removeEventListener("scroll", this.onScroll);
    },

    onScroll() {
      let load =
        document.documentElement.offsetHeight +
          document.documentElement.scrollTop >
        this.$refs.infinite.offsetHeight + 130;
      if (load && !this.busy && !this.complete) {
        this.page++;
        this.$emit("load", this.page);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
