import { useHead } from "@vueuse/head";
import { computed, unref } from "vue";
import store from "@/store";

let siteTitle = "hello";
let separator = "|";

export const usePageTitle = (pageTitle) =>
  useHead(
    computed(() => ({
      title: `${unref(pageTitle)} ${separator} ${siteTitle}`,
    })),
  );

export const useMeta = (data) => {
  return useHead({
    ...data,
    title: `${data.title} | ` + store.state.platform.name,
  });
};
