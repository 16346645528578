<template>
  <ModalFormComponent
    :models="models"
    :title="$t(modalTitle)"
    :storageKey="modalStorageKey"
    @search="submitModal"
  />
  <div class="dashboard__container">
    <BlockLoader :loading="loading"></BlockLoader>
    <div
      :class="'widget dashboard__wrapper ' + (!isFullScreen ? 'editing' : '')"
    >
      <div class="widget-heading">
        <h2 class="textStyle">
          {{ $t("studea_manager.dashboard.list.title") }}
        </h2>
        <ToggleFullScreenComponent
          :isFullScreen="isFullScreen"
          @toggle-full-screen="toggleFullScreen"
        />
      </div>
      <div class="position-relative">
        <FilterBlock
          @export="exportPdf"
          @filter="filter"
          @reset="resetFilter"
          :isFiltered="isFiltered"
        />
        <AccordionTableComponent
          v-if="!loading"
          :categories="categories"
          :crews="crews"
          type="questionnaire"
          @manage="manage"
        />
      </div>
    </div>
    <div :class="'action ' + (!isFullScreen ? 'editing' : '')">
      <ActionsBlock
        :actions="actions"
        :mobileCollapsable="true"
        :collapsable="false"
        :title="$t('global.actions.title')"
        collapseId="actions"
        @export-dashboard-to-excel="exportDashboardToExcel"
        @export-visit-date="exportVisitDate"
        @mail-relance="mailRelance"
        @export-questionnaires-to-pdf="exportQuestionnairesToPdf"
      />
    </div>
  </div>
</template>

<script>
import AccordionTableComponent from "@/components/table/accordion/AccordionTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import FilterBlock from "@/components/blocks/FilterBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import ToggleFullScreenComponent from "@/components/utilities/ToggleFullScreenComponent.vue";
import { formManager } from "@/services/form/form-manager";

export default {
  name: "DashboardView",
  components: {
    ToggleFullScreenComponent,
    BlockLoader,
    ModalFormComponent,
    AccordionTableComponent,
    ActionsBlock,
    FilterBlock,
  },

  data() {
    return {
      actions: [
        {
          translation: "studea_manager.dashboard.mail_relance",
          icon: "envelope",
          type: "success",
          actionType: "button",
          action: "mailRelance",
          cfaStyle: false,
        },
        {
          translation: "studea_manager.dashboard.export.visit_date",
          icon: "calendar-days",
          type: "success",
          actionType: "button",
          action: "exportVisitDate",
          cfaStyle: false,
        },
        {
          translation: "studea_manager.dashboard.export.dashboard_excel",
          icon: "file-excel",
          type: "success",
          actionType: "button",
          action: "exportDashboardToExcel",
          cfaStyle: false,
        },
        {
          translation: "studea_manager.dashboard.export.questionnaires_pdf",
          icon: "file-pdf",
          type: "success",
          actionType: "button",
          action: "exportQuestionnairesToPdf",
          cfaStyle: false,
        },
      ],
      models: [],
      modalTitle: "",
      isFiltered:
        sessionStorage.getItem("questionnaire-dashboard") &&
        sessionStorage.getItem("questionnaire-dashboard").length,
      loading: true,
      isFullScreen: true,
      isMobile: window.innerWidth < 992,
      categories: [],
      crews: [],
      params: {},
      modalType: "",
      modalStorageKey: "",
    };
  },
  mounted() {
    if (!this.isMobile) {
      this.$store.commit("TOGGLE_SIDE_BAR");
    }
    this.getDashboardData();
  },
  beforeUnmount() {
    if (!this.isMobile && !this.$store.state.sideBarOpened) {
      this.$store.commit("TOGGLE_SIDE_BAR");
    }
  },
  methods: {
    filter() {
      this.models = [];
      this.modalTitle = "";
      this.modalStorageKey = "questionnaire-dashboard-filter";
      studeaManagerManager
        .form(
          "dashboard",
          this.$route.params.trainingCourseId,
          null,
          {},
          false,
          true,
        )
        .then((data) => {
          this.models = data;
          this.modalType = "filter";
          this.modalTitle = "studea_manager.dashboard.filter";
        });
    },

    exportPdf() {
      this.models = [];
      this.modalTitle = "";
      this.modalStorageKey = "questionnaire-dashboard-export";
      studeaManagerManager
        .form("dashboard/export", this.$route.params.trainingCourseId)
        .then((data) => {
          this.models = data;
          this.modalType = "export";
          this.modalTitle = "studea_manager.dashboard.export.title";
        });
    },

    submitModal(params) {
      this.params = {
        search: params,
      };
      if (this.modalType === "filter") {
        this.loading = true;
        this.getDashboardData();
        this.isFiltered = true;
      } else if (this.modalType === "export") {
        studeaManagerManager.export(
          "dashboard",
          this.$route.params.trainingCourseId,
          this.params,
        );
      }
    },

    resetFilter() {
      this.models = [];
      this.modalTitle = "";
      this.params = [];
      this.isFiltered = false;
      this.getDashboardData(this.$route.params.trainingCourseId);
      sessionStorage.removeItem("questionnaire-dashboard");
    },

    exportDashboardToExcel() {
      studeaManagerManager.exportToExcel("questionnaires");
    },

    exportVisitDate() {
      console.log("Exporter les dates de visite");
    },

    mailRelance() {
      this.models = [];
      this.modalTitle = "";
      studeaManagerManager
        .form(
          "skill-category",
          this.$route.params.trainingCourseId,
          "",
          "",
          true,
        )
        .then((data) => {
          this.models = data;
          this.modalTitle = "studea_manager.dashboard.mail_relance";
        });
    },

    exportQuestionnairesToPdf() {
      console.log("Exporter les questionnaires en pdf");
    },

    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.$store.commit("TOGGLE_SIDE_BAR");
    },

    checkParams() {
      if (
        sessionStorage.getItem("questionnaire-dashboard") &&
        sessionStorage.getItem("questionnaire-dashboard").length
      ) {
        this.params.search = formManager.getQuery(
          JSON.parse(sessionStorage.getItem("questionnaire-dashboard")),
        );
      }
    },

    getDashboardData() {
      this.loading = true;
      this.checkParams();
      studeaManagerManager
        .getDashboardData(this.$route.params.trainingCourseId, this.params)
        .then((response) => {
          if (
            response &&
            response.categories &&
            response.trainingCourse &&
            response.trainingCourse.crews
          ) {
            this.categories = response.categories;
            this.crews = response.trainingCourse.crews;
            this.loading = false;
          }
        });
    },

    manage(payload) {
      const expired =
        new Date().getTime() > new Date(payload.reply.endDate).getTime();
      if (
        payload.reply.icon === "check" ||
        (payload.reply.icon === "lock" && expired)
      ) {
        studeaManagerManager
          .unlockReply(
            Number(payload.profile),
            payload.questionnaire,
            payload.triad.id,
          )
          .then(() => {
            if (payload.reply.icon === "lock") {
              payload.reply.icon = "lock-open";
            }
            if (payload.reply.icon === "check") {
              payload.reply.icon = "hourglass-start";
              payload.reply.signedAt = null;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard__container {
  width: 100%;
  display: flex;
  @include up-to-lg {
    flex-direction: column;
  }
}

.action,
.dashboard__wrapper {
  transition: all 300ms ease-in-out;
}

.action {
  overflow: hidden;
  width: 0;

  @include up-to-lg {
    width: 100%;
    order: 1;
  }

  &.editing {
    width: 24%;
  }
}

.dashboard__wrapper {
  width: 100%;
  margin-right: 0;

  &.editing {
    width: 75%;
    margin-right: 15px;
  }

  @include up-to-lg {
    width: 100%;
    order: 2;
  }
}
</style>
