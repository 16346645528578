<template>
  <div class="dashboard__categories">
    <div
      v-for="(category, index) in categories"
      :key="category.id"
      :aria-expanded="!index"
      :class="
        'dashboard__category widget category-' +
        category.id +
        (!index ? ' on' : '')
      "
      :style="
        '--max-width:' +
        175 * (totalColumnArray[index] ? totalColumnArray[index] : 1) +
        'px'
      "
    >
      <h3 :id="'heading' + category.id" class="dashboard__header">
        <button
          class="dashboard__category__title"
          @click="toggle(category.id, 'category-', true)"
        >
          {{ category.title }}
          <FontAwesomeIcon icon="fa-light fa-chevron-right"></FontAwesomeIcon>
        </button>
      </h3>
      <div class="dashboard__questionnaires">
        <div
          v-for="skill in category.skills"
          :key="skill.skillId"
          class="dashboard__questionnaires__item"
        >
          <div
            :class="
              'dashboard__questionnaires__title ' +
              (!skill.skillBlocks ? 'no-border' : '')
            "
            :style="
              '--max-width-skillBlocks:' +
              175 *
                (skill.skillBlocks && skill.skillBlocks.length
                  ? skill.skillBlocks.length
                  : 1) +
              'px'
            "
          >
            {{ skill.skillName }}
          </div>
          <div
            v-if="skill.skillBlocks && skill.skillBlocks.length"
            class="dashboard__skillBlocks"
          >
            <div
              v-for="item in skill.skillBlocks"
              :key="item.skillBlockId"
              class="dashboard__skillBlocks__item"
            >
              {{ item.skillBlockName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { mapState } from "vuex";

export default {
  name: "AccordionHeadlineSkillComponent",
  data() {
    return {
      totalColumn: 0,
      totalColumnArray: [],
    };
  },
  mounted() {
    this.countSkillBlockByCategory();
  },
  computed: {
    ...mapState({
      categories: (state) => state.studeaManagerSkillDashboard.data.categories,
    }),
  },
  methods: {
    countSkillBlockByCategory() {
      this.categories.forEach((category) => {
        category.skills.forEach((skill) => {
          if (skill.skillBlocks) {
            skill.skillBlocks.forEach(() => this.totalColumn++);
          } else {
            this.totalColumn++;
          }
        });
        this.totalColumnArray.push(this.totalColumn);
        this.totalColumn = 0;
      });
    },

    toggle(item, name, isMultipleElement) {
      studeaManagerManager.toggle(item, name, isMultipleElement);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__header {
    margin-bottom: 0;
  }

  &__category {
    transition: all 300ms ease-in-out;
    width: 130px;
    margin-right: 10px;
    margin-bottom: 10px;
    height: fit-content;
    overflow: hidden;
    border: 1px solid #e0e6ed;

    &:last-child {
      margin-right: 0;
    }

    &__title {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      background-color: var(--primary-color);
      color: $white;
      border-radius: 6px;
      border: none;
      height: 280px;
      width: 130px;
      font-size: 0.6rem;
      transition: all 300ms ease-in-out;

      svg {
        transition: all 300ms ease-in-out;
        transform: rotate(0);
        margin-left: 20px;
      }
    }

    &.on {
      width: var(--max-width);

      .dashboard__category__title {
        width: var(--max-width);
        height: 100px;
        font-size: 0.875rem;

        svg {
          transform: rotate(180deg);
        }
      }

      .dashboard__questionnaires {
        height: 180px;
        width: var(--max-width);
      }
    }
  }

  &__skillBlocks {
    display: flex;
    height: 100%;

    &__item {
      width: 175px;
      padding: 10px 20px;
      font-size: 0.7rem;
      border-right: 1px solid #e0e6ed;

      &:last-child {
        border-right: none;
      }
    }
  }

  &__questionnaires {
    display: flex;
    background-color: $lighter-grey;
    position: relative;
    transition: all 300ms ease-in-out;
    height: 0;
    width: 130px;
    overflow: hidden;

    &__title {
      padding: 20px;
      border-bottom: 1px solid #e0e6ed;

      &.no-border {
        border-bottom: none;
      }
    }

    &__item {
      overflow: hidden;
      position: relative;
      width: var(--max-width-skillBlocks);
      border-right: 2px solid #e0e6ed;
    }

    &__actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: translateY(63px);
      transition: transform 200ms ease-in-out;

      &.on {
        transform: translateY(0);

        .dashboard__questionnaires__actions__button {
          .icon {
            transform: rotate(180deg);
          }
        }
      }

      &__button {
        width: 100%;
        border: none;
        font-size: 0.7rem;
        padding: 2px 5px;
        border-radius: 6px 6px 0 0;

        .icon {
          transform: rotate(0);
          transition: all 300ms ease-in-out;
        }
      }

      &__container {
        display: flex;
        padding: 10px;
        background-color: $white;
      }
    }
  }
}
</style>
