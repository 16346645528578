export default {
  triadList: "Liste des trînomes",
  triadTrainingList: "Liste des trinômes par ",
  promotion: "promotion | promotions",
  youHaveAccess: "Vous avez accès actuellement à {number} {label} {active}",
  female_active: "active | actives",
  male_active: "actif | actifs",
  female_archived: "archivée | archivées",
  male_archived: "archivé | archivés",
  loadingTriads: "Chargement des trînomes ...",
  homeTriad: "Accueil trînome",
  apprenticeShort: "A",
  tutorShort: "TP",
  apprenticeMasterShort: "MA",
  Apprentice: "Alternant",
  Tutor: "Tuteur",
  "Apprentice master": "Maitre d'apprentissage",
  "Apprentice master 2": "Maitre d'apprentissage 2",
  "Company manager": "Gestionnaire d'entreprise",
  Parent: "Parent",
  "Studea manager": "Chargé de livret",
  apprentices: "Alternants",
  tutors: "Tuteurs",
  apprenticeMasters: "Maitres d'apprentissage",
  studeaManagers: "Chargés de livret",
  map: "Map",
  myContract: "Mon contrat",
  myContracts: "Mes contrats",
  help: "Aide en ligne",
  company: "Entreprise",
  pedagogic_tutor: "Tuteur pédagogique",
  company_tutor: "Tuteur entreprise",
  apprentice: "Alternant",
  tutor: "Tuteur",
  apprenticeMaster: "Maitre d'apprentissage",
  studeaManager: "Chargé de livret",
  legal_responsible: "Responsable légal",
};
