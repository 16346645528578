<template>
  <div ref="tableTop" class="list">
    <SortTableFiltersComponent
      @search="search"
      :datas="datas"
      :columns="columns"
      :checkable="checkable"
      @limitList="limitList"
      @changeSortColumns="changeSortColumns"
      @checkAll="checkAll"
      @changeSortType="changeSortType"
    />
    <SortTableFirstLineComponent
      :datas="datas"
      :columns="columns"
      :checkable="checkable"
      :nbColumns="nbColumns"
      :actions="actions"
      @checkAll="checkAll"
      @sort="sort"
    />
    <div
      v-for="(row, id) in rows"
      :key="id"
      :class="'list__item ' + (checkable ? 'checkable' : '')"
    >
      <SortTableRowComponent
        :nbColumns="nbColumns"
        :row="row"
        :actions="actions"
        :checkable="checkable"
        :columns="columns"
        :checkAllRows="listCheckAll"
        :id="id"
        :idKey="idKey"
        :type="type"
        :path="path"
        @initModel="initModel"
        @clickAction="clickAction"
      />
    </div>
    <SortTablePaginationComponent
      :nbPages="nbPages"
      :activePage="activePage"
      @changePage="changePage"
    />
    <div
      v-if="!Object.keys(this.datas).length && !loading"
      class="list__noresult blockStyle"
    >
      {{ $t("No data available in the table") }}<br />
      {{ $t("Please enter or modify your search") }}
      <button
        v-if="isSearchAvailable"
        class="btn btn-success btnStyle mt-3"
        @click="searchAction"
      >
        <FontAwesomeIcon icon="fa-light fa-magnifying-glass" class="me-2" />
        {{ $t("search") }}
      </button>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import { sortManager } from "@/services/utilities/sort-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { isProxy } from "vue";
import SortTableFiltersComponent from "@/components/table/sortTable/SortTableFiltersComponent.vue";
import SortTableFirstLineComponent from "@/components/table/sortTable/SortTableFirstRowComponent.vue";
import SortTableRowComponent from "@/components/table/sortTable/SortTableRowComponent.vue";
import SortTablePaginationComponent from "@/components/table/sortTable/SortTablePaginationComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "SortTableComponent",
  components: {
    FontAwesomeIcon,
    SortTablePaginationComponent,
    SortTableRowComponent,
    SortTableFirstLineComponent,
    SortTableFiltersComponent,
    BlockLoader,
  },
  props: {
    datas: {
      default: [],
      type: [],
      required: true,
    },
    columns: {
      default: [],
      type: [],
      required: true,
    },
    actions: {
      default: [],
      type: [],
      required: false,
    },
    loading: {
      default: false,
      type: Boolean,
      required: false,
    },
    checkable: {
      default: false,
      type: Boolean,
      required: false,
    },
    isSearchAvailable: {
      default: false,
      type: Boolean,
      required: false,
    },
    idKey: {
      default: 0,
      type: Number,
      required: false,
    },
    path: {
      default: "",
      type: String,
      required: false,
    },
    dateFormat: {
      default: "short",
      type: String,
      required: false,
    },
    apiFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: false,
    },
    modalTitle: {
      type: String,
      required: false,
    },
    maxResult: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      sortType: "ASC",
      sortColumn: "",
      offset: 0,
      limit: 10,
      nbPages: 0,
      nbRows: 0,
      activePage: 1,
      sliceLimit: 10,
      nbColumns: 0,
      filteredDatas: this.datas,
      listCheck: [],
      listCheckAll: 0,
      listParams: [],
      rows: [],
      keywordSearch: {
        vars: {
          name: "keyword",
          value: "",
          id: "keyword",
          required: false,
          block_prefixes: ["filter", "text"],
          disabled: false,
          attr: {
            placeholder: this.$t("filter"),
          },
          row_attr: {
            class: "h-100",
          },
        },
      },
    };
  },
  watch: {
    datas() {
      this.filteredDatas = this.datas;
      this.processRows();

      if (!this.apiFilters) {
        this.initSort();
      }
      this.initPagination(false);
    },
    sortType() {
      if (this.sortColumn !== null && !this.apiFilters) {
        this.filteredDatas = sortManager.sort(
          this.filteredDatas,
          this.sortColumn,
          this.sortType,
        );
      }
      if (this.apiFilters && this.sortColumn !== null) {
        this.refreshList();
      }
      this.initPagination();
    },
    sortColumn() {
      if (this.apiFilters && this.sortColumn !== null) {
        this.refreshList();
      }
    },
    listCheck() {
      this.listCheckAll =
        this.listCheck.length === Object.keys(this.datas).length;
      this.$emit("updated", this.listCheck);
    },
  },
  mounted() {
    this.filteredDatas = this.datas;
    this.processRows();
    this.initPagination(false);
  },
  methods: {
    processRows() {
      this.sliceLimit = this.offset + parseInt(this.limit);
      let rows = {};
      if (Object.keys(this.filteredDatas).length) {
        const sliced = Object.entries(this.filteredDatas).slice(
          this.offset,
          this.sliceLimit,
        );

        sliced.forEach((row) => {
          rows[row[0]] = row[1];
        });
      }
      this.rows = rows;
    },

    sort(e, column) {
      this.sortColumn = column.index ? column.index : column.name;
      const target = e.currentTarget;
      this.initSort();
      switch (this.sortType) {
        case "DESC":
          this.sortType = "ASC";
          this.sortWay = "up";
          column.sortWay = "up";
          target.classList.remove("down");
          target.classList.add("up");
          break;
        case "ASC":
          this.sortType = "DESC";
          column.sortWay = "down";
          target.classList.remove("up");
          target.classList.add("down");
          break;
      }

      if (this.apiFilters) {
        this.refreshList();
      }
    },
    initSort() {
      const sortables = document.getElementsByClassName("list__sortable");
      for (let i = 0; i < sortables.length; i++) {
        sortables[i].classList.remove("up");
        sortables[i].classList.remove("down");
      }
      Object.values(this.columns).forEach(function (column) {
        column.sortWay = "";
      });
    },

    limitList(payload) {
      this.limit = payload;
      if (this.apiFilters) {
        this.refreshList();
        this.activePage = 1;
      } else {
        this.processRows();
        this.initPagination();
      }
    },

    refreshList() {
      let search = "";
      let i = 0;
      const keywords = this.keywordSearch.vars.value;
      if (!this.apiFilters) {
        Object.values(this.columns).forEach(function (column) {
          search = search.concat((i ? "&" : "") + column.name + "=" + keywords);
          i++;
        });
      } else {
        search = keywords;
      }

      this.listParams = {
        keywords: search,
        sortByField: this.sortColumn,
        sortOrder: this.sortType,
        limit: this.limit,
        offset: this.offset,
      };

      this.$emit("refresh", this.listParams);
    },
    initPagination(scroll = true) {
      this.sliceLimit = this.limit;
      if (!this.apiFilters) {
        this.activePage = 1;
      }
      this.offset = 0;
      this.nbRows = this.maxResult
        ? this.maxResult
        : Object.keys(this.filteredDatas).length;
      this.nbPages = Math.ceil(this.nbRows / this.limit);
      this.scrollToTableTop(scroll);
      this.setNbColumns();
      this.keywordSearch.editable = Object.keys(this.filteredDatas).length
        ? 1
        : 0;
      this.processRows();
    },
    changePage(payload) {
      this.activePage = payload;
      this.offset = (payload - 1) * this.limit;
      this.sliceLimit = this.offset + parseInt(this.limit);
      this.scrollToTableTop();
      if (!this.apiFilters) {
        this.processRows();
      } else {
        this.refreshList();
      }
    },

    searchAction() {
      this.$emit("openSearch");
    },

    scrollToTableTop(scroll = true) {
      if (scroll) {
        const $this = this;
        setTimeout(function () {
          if ($this.$refs.tableTop) {
            window.scrollTo({
              behavior: "smooth",
              top:
                $this.$refs.tableTop.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                130,
            });
          }
        }, 50);
      }
    },
    clickAction(action) {
      this.$emit(action.action.action, action);
    },
    setNbColumns() {
      this.nbColumns = Object.keys(this.columns).length;
    },
    search(payload) {
      this.keywordSearch = payload;
      if (this.apiFilters) {
        this.activePage = 1;
        this.refreshList();
      } else {
        const value = this.keywordSearch.vars.value
          ? this.keywordSearch.vars.value.toLowerCase()
          : "";
        const $this = this;
        let rows = [];
        let add = false;
        if (value) {
          Object.values(this.datas).filter(function (row) {
            add = false;
            Object.values($this.columns).some(function (column) {
              if (
                column.filterActive &&
                row[column.index ? column.index : column.name] &&
                !isProxy(row[column.index ? column.index : column.name]) &&
                row[column.index ? column.index : column.name]
                  .toString()
                  .toLowerCase()
                  .includes(value)
              ) {
                add = true;
              }
            });
            if (add) {
              rows.push(row);
            }
          });
        } else {
          rows = this.datas;
        }
        if (this.sortColumn) {
          rows = sortManager.sort(rows, this.sortColumn, this.sortType);
        }
        this.filteredDatas = rows;
        this.initPagination();
      }
    },
    checkAll(e, listCheck) {
      this.listCheckAll = !listCheck;
      const value = e.currentTarget.checked;
      if (value) {
        Object.values(this.filteredDatas).forEach((row) => {
          const id = this.idKey ? row[this.idKey] : row.id;
          if (!this.listCheck.includes(id)) {
            this.listCheck.push(id);
          }
        });
      } else {
        Object.values(this.filteredDatas).forEach((row) => {
          const id = this.idKey ? row[this.idKey] : row.id;
          if (this.listCheck.includes(id)) {
            this.listCheck.splice(this.listCheck.indexOf(id));
          }
        });
      }
      this.$emit("updated", this.listCheck);
    },

    duplicateTrainingCourseOnNextYear(id) {
      this.$emit("duplicateTrainingCourseOnNextYear", id);
    },

    duplicateTrainingCourse(id) {
      this.$emit("duplicateTrainingCourse", id);
    },

    removeTrainingCourse(id) {
      this.$emit("removeTrainingCourse", id);
    },
    initModel(row, columnIndex, columnName) {
      this.$emit("initModel", row, columnIndex, columnName);
    },

    changeSortColumns(payload) {
      this.sortColumn = payload;
    },

    changeSortType(payload) {
      this.sortType = payload;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  min-height: 350px;
  position: relative;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 8px;
    position: relative;

    @include up-to-lg {
      flex-direction: column;
    }

    @include dark-theme {
      color: $white;
    }

    &.-heading {
      font-weight: 700;
      font-size: 14px;

      @include up-to-lg {
        display: none;
      }

      .list__sortable {
        cursor: pointer;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &.checkable {
      @include up-to-lg {
        padding-top: 2px;
        padding-left: 25px;
      }
    }
  }

  &__noresult {
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $light-grey;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
    font-size: 16px;
    max-width: 375px;
    width: 100%;

    @include up-to-md {
      position: relative;
      transform: translateX(-50%);
      top: initial;
      font-size: 14px;
      text-align: center;
      width: 100%;
      padding: 15px;
    }
  }
}
</style>
