import { apiConnection } from "@/services/api-connection";
import store from "@/store";

const URL_DESIGN = "/app/design";
const URL_DOCUMENTS = "/app/document/home";
const URL_PLATFORM = "/app/platform";
const URL_CONFIG = "/app/config";
const URL_DATA_AGREEMENT = "/app/data-agreement";
const URL_AVAILABLE_PLATFORM = "/app/user/available-platforms/";

class PlatformManager {
  loadWebsites = (username) => {
    const url =
      process.env.VUE_APP_API_URL +
      "/" +
      store.state.theme.locale +
      URL_AVAILABLE_PLATFORM +
      username;
    return apiConnection
      .fetchApi("get", url, {}, false, true)
      .then((response) => response);
  };

  loadDesign = () => {
    return apiConnection
      .get(URL_DESIGN, {}, false)
      .then((response) => response);
  };

  loadDocuments = () => {
    return apiConnection
      .get(URL_DOCUMENTS, {}, false)
      .then((response) => response);
  };

  loadQuestionnaireConfig = () => {
    return apiConnection.get(URL_CONFIG, {}, true).then((response) => response);
  };

  loadPlatform = () => {
    const urlParams = new URLSearchParams({
      frontHostname: window.location.host,
    });
    var url =
      process.env.VUE_APP_API_URL +
      "/" +
      store.state.theme.locale +
      URL_PLATFORM;
    url += "?" + urlParams.toString();
    return apiConnection
      .fetchApi("GET", url, null, false, true)
      .then((response) => response);
  };

  loadDataAgreement = () => {
    return apiConnection
      .get(URL_DATA_AGREEMENT, {}, false)
      .then((response) => response);
  };
}

export const platformManager = new PlatformManager();
