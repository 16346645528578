<template>
  <label
    v-if="itemModel.vars.label"
    :class="
      'form-label' +
      (itemModel.vars.label_attr && itemModel.vars.label_attr.class
        ? ' ' + itemModel.vars.label_attr.class
        : '') +
      (itemModel.vars.required ? ' required' : '')
    "
  >
    {{ index ? index + ". " : "" }}{{ itemModel.vars.label
    }}{{ itemModel.vars.required ? "*" : "" }}
    <span
      v-if="itemModel.vars.help && !isMobile()"
      class="form-info"
      v-tooltip="{ title: itemModel.vars.help }"
    >
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
    </span>
  </label>
  <div v-if="isMobile() && itemModel.vars.help" class="form-info">
    <FontAwesomeIcon icon="fa-light fa-circle-question" />
    {{ itemModel.vars.help }}
  </div>

  <VueTelInput
    v-model="itemModel.vars.value"
    :disabled="itemModel.vars.disabled"
    :inputOptions="options"
    :class="itemClass"
    :preferredCountries="preferredCountries"
    mode="international"
    @blur="blur"
    @change="change"
    @focus="focus"
    @input="input"
    @validate="validate"
  />

  <div v-if="itemModel.vars.error" class="invalid-feedback d-block">
    {{ itemModel.vars.error }}
  </div>
</template>

<script>
import { formValidator } from "@/services/form/form-validator";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VueTelInput } from "vue-tel-input";
import { mapState } from "vuex";

export default {
  name: "PhoneTypeComponent",
  watch: {
    "itemModel.vars.error"() {
      this.getClass();
    },
  },
  mounted() {
    this.preferredCountries = [this.locale];
  },
  data() {
    return {
      itemModel: this.model,
      itemClass: "form-control phone-input",
      phone: "",
      load: false,
      preferredCountries: [],
      validFormat: true,
      options: {
        id: this.model.vars.id,
        name: this.model.vars.name,
        required: this.model.vars.required,
        type: "tel",
        placeholder:
          this.model.vars.attr && this.model.vars.attr.placeholder
            ? this.model.vars.attr.placeholder
            : "",
      },
    };
  },
  props: {
    model: {
      type: [],
      required: true,
      default: [],
    },
    language: {
      type: String,
      required: false,
      default: "fr",
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState({
      locale: (state) => state.theme.locale,
    }),
  },
  methods: {
    getClass() {
      if (
        (!this.validFormat ||
          formValidator.validModel(this.itemModel).length) &&
        this.model.vars.required &&
        !this.model.vars.value
      ) {
        this.itemClass =
          "form-control phone-input is-invalid " +
          (this.model.vars.attr && this.model.vars.attr.class
            ? this.model.vars.attr.class
            : "");
      } else {
        this.itemClass =
          "form-control phone-input is-valid " +
          (this.model.vars.attr && this.model.vars.attr.class
            ? this.model.vars.attr.class
            : "");
      }
    },
    blur() {
      this.valid(true);
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    input() {
      this.valid();
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    validate(object) {
      this.validFormat = object.valid;
      if (this.load) {
        this.valid();
      }
      this.load = true;
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    change() {
      this.$emit("draft", this.itemModel);
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    focus() {
      this.$emit("focus");
    },
    isMobile() {
      return window.innerWidth < 992;
    },
    valid(blur = false) {
      if (blur) {
        this.$emit("save");
      }
      this.getClass();
      if (!this.validFormat && this.model.vars.value) {
        this.itemModel.vars.error = this.$i18n.t("phoneError");
      } else {
        delete this.itemModel.vars.error;
        formValidator.validModel(this.itemModel, this.language);
      }
    },
  },
  components: {
    FontAwesomeIcon,
    VueTelInput,
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}

.phone-input {
  padding: 0 30px 0 0;
  border-radius: 6px;
  border-color: $m-color_4;
  margin-bottom: 7px;

  &.is-valid,
  &.is-invalid {
    padding: 0 30px 0 0;
  }
}
</style>
