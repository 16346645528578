export default {
  error: {
    message: {
      not_found: "La page que vous avez demandée n'a pas été trouvée!",
      internal: "Erreur interne du serveur!",
      unavailable: "Service non disponible!",
      not_authorized: "Vous n'etes pas authorisé à accéder à cette page",
      unauthenticated: "Vous devez vous authentifier pour accéder à cette page",
    },
  },
};
