<template>
  <FormTabsBlockComponent
    :key="componentKey"
    :loading="loading"
    :tabs="tabs"
    :title="title"
    @formCancel="cancel"
    @formSubmit="submit"
  />
</template>

<script>
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "SkillGenerateQuestionnaireView",
  components: { FormTabsBlockComponent },
  data() {
    return {
      tabs: [],
      componentKey: 0,
      loading: false,
      title: "",
      backPath: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    submit() {
      let response = [];
      this.loading = true;
      this.invalidTab = null;

      Object.values(this.tabs).forEach((tab) => {
        if (!tab.hidden) {
          response = {
            ...response,
            ...formManager.processForm(tab.models, false),
          };
        }
      });
      let urlCall =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/questionnaire?skillSession=" +
        this.$route.params.currentSessionId;
      apiConnection.post(urlCall, response).then((data) => {
        if (data && !data.hasError) {
          this.$router.push({
            name: "studeaManagerQuestionnaireEdit",
            params: { id: data.responses[0].object.id },
            query: { time: Date.now() },
          });
          notificationManager.showNotification(
            "success",
            this.$t("studea_manager.skill_generate.success"),
          );
        } else if (data.responses && data.responses[0].validationErrors) {
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
          Object.entries(this.tabs).forEach((tab) => {
            const errors = formValidator.processResponses(
              data.responses[0].validationErrors,
              tab[1].models,
            );
            if (errors.length) {
              this.invalidTab = this.id ? tab[1] : tab[0];
            }
          });
        }
        this.loading = false;
        this.componentKey++;
      });
    },

    cancel() {
      this.$router.push({
        name: this.backPath,
        params: { entity: this.alias },
      });
    },

    init() {
      this.loading = true;
      this.backPath = this.$route.meta.backPath;
      this.title = this.$t("studea_manager.skill_generate.title");

      studeaManagerManager
        .form("questionnaire", this.$route.params.trainingCourseId, "add", {
          skillSession: this.$router.currentRoute.value.params.currentSessionId,
        })
        .then((data) => {
          this.tabs = data && data.tabs ? data.tabs : [];
          this.componentKey++;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
