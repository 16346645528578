export default {
  triadList: "Liste des trînomes",
  triadTrainingList: "Liste des trinômes par formations",
  loadingTriads: "Chargement des trînomes ...",
  homeTriad: "Accueil trînome",
  apprenticeShort: "A",
  tutorShort: "TP",
  apprenticeMasterShort: "MA",
  Apprentice: "Alternant",
  Tutor: "Tuteur",
  "Apprentice master": "Maitre d'apprentissage",
  "Apprentice master 2": "Maitre d'apprentissage 2",
  "Company manager": "Gestionnaire d'entreprise",
  Parent: "Parent",
  studea_manager: "Chargé de livret",
  apprentices: "Alternants",
  tutors: "Tuteurs",
  apprentice_masters: "Maitres d'apprentissage",
  studeaManagers: "Chargés de livret",
  map: "Map",
  myContract: "Mon contrat",
  company: "Entreprise",
  pedagogic_tutor: "Tuteur pédagogique",
  company_tutor: "Tuteur entreprise",
};
