export default {
  login: "Connexion",
  loginTo: "Connexion à",
  loginMobile:
    "Veuillez saisir votre identifiant ou votre email pour vous connecter",
  loading: "Chargement...",
  password: "Mot de passe",
  username: "Identifiant",
  organization: "Organisme",
  showPassword: "Voir le mot de passe",
  keepMeLoggedIn: "Rester connecté",
  forgotPasswordLink: "Mot de passe oublié ?",
  loginValidation: "Veuillez indiquer vos identifiants pour vous connecter",
  loginLoginValidation: "Veuillez saisir votre identifiant ou votre email",
  loginPasswordValidation: "Veuillez saisir votre mot de passe",
  alertIdentify: "Vous êtes identifiés",
  alertIdentifyError: "Erreur lors de l'identification",
  logout: "Déconnexion",
  badCredentials: "Identifiants erronés",
  password_reset_info:
    "Veuillez saisir votre identifiant ou votre email afin de réinitialiser votre mot de passe",
  reset_password: "Mot de passe oublié",
  reset_password_confirm:
    "Un lien pour générer un nouveau mot de passe a été envoyé dans votre boîte mail",
  not_available: "Indisponible",
  accessAccountDenied: "Vous n'avez pas accès à l'application sur ce compte",
  legal_notice: "Mentions légales",
  personal_data: "Données personnelles",
  contact_us: "Nous contacter",
  terms_and_conditions: "Conditions d'utilisation",
};
