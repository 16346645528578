<template>
  <label
    v-if="itemModel.vars.label"
    :class="
      'form-label' +
      (itemModel.vars.label_attr && itemModel.vars.label_attr.class
        ? ' ' + itemModel.vars.label_attr.class
        : '') +
      (itemModel.vars.required ? ' required' : '')
    "
  >
    {{ index ? index + ". " : "" }}{{ itemModel.vars.label
    }}{{ itemModel.vars.required ? "*" : "" }}
    <span
      v-if="itemModel.vars.help && !isMobile()"
      class="form-info ms-1"
      v-tooltip="{ title: itemModel.vars.help }"
    >
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
    </span>
  </label>
  <div v-if="isMobile() && itemModel.vars.help" class="form-info">
    <FontAwesomeIcon icon="fa-light fa-circle-question" />
    {{ itemModel.vars.help }}
  </div>
  <div
    :class="{
      'position-relative': itemModel.vars.block_prefixes[1] === 'number',
    }"
  >
    <input
      :id="itemModel.vars.id"
      v-model="itemModel.vars.value"
      v-if="!itemModel.vars.hide"
      :readonly="itemModel.vars.attr && itemModel.vars.attr.readonly"
      :class="
        itemClass +
        ' ' +
        (itemModel.vars.attr && itemModel.vars.attr.class
          ? itemModel.vars.attr.class
          : '')
      "
      :disabled="itemModel.vars.disabled === true"
      :name="
        language ? itemModel.vars.name + '-' + language : itemModel.vars.name
      "
      :placeholder="
        itemModel.vars.attr && itemModel.vars.attr.placeholder
          ? itemModel.vars.attr.placeholder
          : ''
      "
      :required="itemModel.vars.required === true"
      :type="itemModel.vars.block_prefixes[1]"
      @blur="blur"
      @change="change"
      @focus="focus"
      @input="input"
    />
    <div
      class="input__nav"
      v-if="itemModel.vars.block_prefixes[1] === 'number'"
    >
      <button type="button" @click="stepUp">
        <FontAwesomeIcon icon="fa-light fa-plus" />
      </button>
      <button type="button" @click="stepDown">
        <FontAwesomeIcon icon="fa-light fa-minus" />
      </button>
    </div>
  </div>
  <div v-if="model.vars.error" class="invalid-feedback d-block">
    {{ model.vars.error }}
  </div>
</template>

<script>
import { formValidator } from "@/services/form/form-validator";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "InputTypeComponent",
  watch: {
    "model.vars.error"() {
      this.getClass();
    },
  },
  data() {
    return {
      itemModel: this.model,
      itemClass: "form-control",
    };
  },
  props: {
    model: {
      type: [],
      required: true,
      default: [],
    },
    language: {
      type: String,
      required: false,
      default: "fr",
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: {
    getClass() {
      const errors = formValidator.validModel(this.itemModel);
      if (this.model.vars.error || errors.length) {
        this.itemClass = "form-control is-invalid";
      } else if (
        !errors.length &&
        !this.model.vars.error &&
        this.model.vars.required
      ) {
        this.itemClass = "form-control is-valid";
      } else {
        this.itemClass = "form-control";
      }
    },
    blur() {
      this.valid(true);
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    input() {
      this.valid();
      this.$emit("update", this.itemModel.vars.value, this.index);
      this.$emit("input", this.itemModel.vars.value, this.index);
    },
    change() {
      this.$emit("draft", this.itemModel);
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    focus() {
      this.$emit("focus");
    },
    isMobile() {
      return window.innerWidth < 992;
    },
    valid(blur = false) {
      if (blur) {
        this.$emit("save");
      }
      this.getClass();
    },

    stepUp() {
      document.getElementById(this.itemModel.vars.id).stepUp();
      this.itemModel.vars.value++;
    },

    stepDown() {
      document.getElementById(this.itemModel.vars.id).stepDown();
      this.itemModel.vars.value--;
    },
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input__nav {
  display: flex;
  position: absolute;
  top: 8px;
  right: 0;

  button {
    border: none;
    background: none;
    color: #3b3f5c;
    display: flex;
    align-items: center;
    padding: 5px 10px;

    &:hover {
      color: var(--primary-color);
    }

    &:first-child {
      border-right: 1px solid $grey;
    }
  }
}

input[type="number"] {
  &.form-control.is-valid,
  &.form-control.is-invalid {
    background: none;
  }
}
</style>
