<template>
  <div class="card-body">
    <div v-if="search" class="filter">
      <InputTypeComponent :model="searchModel" @update="filter" />
    </div>
    <div class="w-100">
      <div v-if="Object.keys(filteredTriads).length">
        <div
          v-for="crew in filteredTriads"
          :key="crew.id"
          :class="{ crew: Object.values(filteredTriads).length > 1 }"
        >
          <div
            class="crew__title"
            v-if="
              Object.values(filteredTriads).length > 1 &&
              Object.keys(crew.triads).length
            "
          >
            {{ crew.title }}
          </div>
          <div class="row">
            <div
              v-for="triad in crew.triads"
              :key="triad.id"
              :class="{
                'col-4': lateralTab,
                'col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-sm-6': !lateralTab,
              }"
            >
              <div
                :class="
                  'widget apprentice' +
                  (this.triad.id && this.triad.id === triad.id
                    ? ' -active'
                    : '')
                "
                v-tooltip="{
                  title:
                    $t('Tutor') +
                    ' : ' +
                    (triad.tutor ? triad.tutor.firstName : '') +
                    ' ' +
                    (triad.tutor ? triad.tutor.lastName : '') +
                    '\n' +
                    $t('Apprentice master') +
                    ' : ' +
                    (triad.apprenticeMaster
                      ? triad.apprenticeMaster.firstName
                      : '') +
                    ' ' +
                    (triad.apprenticeMaster
                      ? triad.apprenticeMaster.lastName
                      : ''),
                }"
                @click="goDashboard(triad.id, trainingCourse.id)"
              >
                <AvatarComponent
                  :disablePicture="true"
                  :user="triad.apprentice"
                  profile="apprentice"
                />
                <div class="name">
                  {{
                    triad.apprentice && triad.apprentice.firstName
                      ? triad.apprentice.firstName
                      : ""
                  }}
                  {{
                    triad.apprentice && triad.apprentice.lastName
                      ? triad.apprentice.lastName
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>{{ $t("dashboard.no_triad") }}</div>
    </div>
  </div>
</template>

<script>
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { mapState } from "vuex";

export default {
  name: "TriadUsersListComponent",
  components: { InputTypeComponent, AvatarComponent },
  props: {
    lateralTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    preventClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    trainingCourse: {
      type: [],
      required: true,
      default: [],
    },
  },
  data() {
    return {
      searchModel: {
        vars: {
          name: "searchTriad",
          id: "searchTriad",
          block_prefixes: ["", "text"],
          value: "",
          attr: {
            placeholder: "Filtrer",
          },
        },
      },
      filteredTriads: [],
    };
  },
  computed: {
    ...mapState({
      triad: (state) => state.triad.triad,
    }),
  },
  watch: {
    "trainingCourse.crews"() {
      if (this.trainingCourse.crews) {
        this.filteredTriads = this.trainingCourse.crews;
      }
    },
  },
  mounted() {
    if (this.trainingCourse.crews) {
      this.filteredTriads = this.trainingCourse.crews;
    }
  },
  methods: {
    goDashboard(triadId, trainingCourseId) {
      const oldTriad = window.localStorage.getItem("studea-oldTriad")
        ? parseInt(window.localStorage.getItem("studea-oldTriad"))
        : null;
      if (!this.triad.id || triadId !== oldTriad) {
        this.$store
          .dispatch("changeTriad", {
            triadId: triadId,
            trainingCourseId: trainingCourseId,
          })
          .then(() => {
            this.$emit("triadLoaded");
            this.$router.push({ name: "Dashboard" });
          });
      } else {
        this.$emit("triadLoaded");
        this.$router.push({
          name: "Dashboard",
        });
      }
    },

    filter(keyword) {
      keyword = keyword ? keyword.toLowerCase() : "";
      this.filteredTriads = {};
      Object.values(this.trainingCourse.crews).forEach((crew) => {
        if (crew) {
          this.filteredTriads[crew.id] = {};
          this.filteredTriads[crew.id]["id"] = crew.id;
          this.filteredTriads[crew.id]["title"] = crew.title;
          this.filteredTriads[crew.id]["triads"] = Object.values(
            crew.triads,
          ).filter((triad) => {
            return (
              triad.apprentice.firstName.toLowerCase().indexOf(keyword) !==
                -1 ||
              triad.apprentice.lastName.toLowerCase().indexOf(keyword) !== -1
            );
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  &.apprentice {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;
    height: 65px;

    @include mobile {
      width: 100%;
      margin-right: 0;
    }

    &.-active {
      border-color: $blue;
    }

    &:hover {
      background: $light-blue;
    }

    @include dark-theme {
      color: $white;

      &:hover {
        background: $m-color_9 !important;
      }
    }
  }

  .name {
    margin-left: 5px;
  }

  .card {
    order: 2;

    &.-first {
      order: 1;

      .card-header {
        background: $light-blue;

        @include dark-theme {
          background: $m-color_18;
          color: $white;
        }
      }
    }

    .card-body {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.filter {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #e0e6ed;
}

.crew {
  border-bottom: 1px dashed $m-color_6;
  margin-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
}
</style>
