<template>
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <div class="loader-block">
        <div v-if="skillTypes.length">
          <div class="widget">
            <div class="widget-heading flex-column flex-md-row">
              <h2 class="textStyle">
                <button
                  class="btn-icon"
                  v-tooltip="{ title: $t('back') }"
                  @click="$router.back()"
                >
                  <FontAwesomeIcon icon="fa-light fa-arrow-left" />
                </button>
                {{
                  $tc("global.list.title", 1, {
                    name: $tc(
                      "studea_manager.skillType.title",
                      1,
                    ).toLowerCase(),
                  })
                }}
              </h2>
            </div>
            <div class="widget-content">
              <div v-if="Object.keys(skillTypes).length" class="list">
                <div class="list__lesson row -heading">
                  <div class="col-md-11 col-9">
                    {{ $t("global.label.name") }}
                  </div>
                  <div class="col-md-1 col-3"></div>
                </div>
                <VueDraggableNext
                  :animation="200"
                  :delay="200"
                  :delay-on-touch-only="true"
                  :list="skillTypes"
                  :disabled="
                    !accessManager().isAvailableForUser(accessManager().EDIT)
                  "
                  handle=".grip"
                  @change="sort"
                >
                  <div
                    class="list__lesson row grip"
                    v-for="skillType in skillTypes"
                    :key="skillType.id"
                  >
                    <div class="col-md-11 col-12 mb-2 mb-md-0">
                      {{ skillType.name }}
                    </div>
                    <div class="col-md-1 col-4 pe-0">
                      <div class="list__action">
                        <router-link
                          :to="{
                            name: 'studeaManagerSkillTypeEdit',
                            params: { id: skillType.id, alias: 'skill-type' },
                          }"
                          class="list__button"
                          v-access:[$store.state.user.permissions]="
                            accessManager().EDIT
                          "
                          v-tooltip="{
                            title: $tc('global.edit.title', 1, {
                              name: this.$tc(
                                'studea_manager.skill.title',
                                1,
                              ).toLowerCase(),
                            }),
                          }"
                        >
                          <FontAwesomeIcon icon="fa-light fa-edit" />
                        </router-link>
                        <button
                          class="list__button"
                          v-tooltip="{
                            title: $t('global.delete.title', 1, {
                              name: this.$tc('studea_manager.skill.title', 1),
                            }),
                          }"
                          v-access:[$store.state.user.permissions]="
                            accessManager().DELETE
                          "
                          @click="remove(skillType.id)"
                        >
                          <FontAwesomeIcon icon="fa-light fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </VueDraggableNext>
              </div>
              <div v-else class="mt-2">
                <div class="textStyle fst-italic">
                  {{
                    $tc("global.empty.title", 1, {
                      name1: this.$tc(
                        "studea_manager.skillType.title",
                        1,
                      ).toLowerCase(),
                      name2: this.$tc(
                        "studea_manager.skill.title",
                        1,
                      ).toLowerCase(),
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">
              {{
                $t("global.list.title", {
                  name: this.$tc("studea_manager.skill.title", 2).toLowerCase(),
                })
              }}
            </h2>
          </div>
          <div class="widget-content">
            <div class="text-center">
              <div class="noResult blockStyle">
                {{ $t("No data available") }}<br />
                {{ $t("Please click on the add button") }}
              </div>
              <router-link
                :to="{
                  name: 'studeaManagerSkillTypeAdd',
                  params: { alias: 'skill-type' },
                }"
                class="btn btn-primary mb-3"
                v-access:[$store.state.user.permissions]="accessManager().EDIT"
                >{{
                  $t("global.add.title", {
                    name: this.$tc(
                      "studea_manager.skillType.title",
                      2,
                    ).toLowerCase(),
                  })
                }}
              </router-link>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            :collapsable="false"
            collapseId="actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import studeaManager from "../../../router/studea-manager/studea-manager";
import { i18n } from "@/i18n";
import { accessManager } from "@/services/security/access-manager";

export default {
  name: "SkillTypeListView",
  computed: {
    i18n() {
      return i18n;
    },
    studeaManager() {
      return studeaManager;
    },
  },
  components: {
    BlockLoader,
    ActionsBlock,
    VueDraggableNext,
  },
  data() {
    return {
      skillTypes: [],
      loading: false,
      actions: [
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.skillType.title", 2).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillTypeAdd",
          cfaStyle: true,
          params: { alias: "skill-type" },
          access: accessManager.EDIT,
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    init(loading = true) {
      this.loading = loading;
      studeaManagerManager
        .list("skill-type", this.$route.params.trainingCourseId, false)
        .then((res) => {
          this.skillTypes = res;
          this.loading = false;
        });
    },

    sort(event) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        studeaManagerManager
          .sort("skill-type", this.$route.params.trainingCourseId, id, position)
          .then(() => {
            this.init(false);
          });
      }
    },

    remove(id) {
      studeaManagerManager
        .remove("skill-type", this.$route.params.trainingCourseId, id, 1)
        .then(() => {
          this.init();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
}

.grip {
  cursor: grab;
}

.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__lesson {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &.-action {
      width: 15%;
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }
}
</style>
