import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

const state = {
  menu: [],
  configMenu: [],
  moduleMenu: [],
  pilotageMenu: [],
};

const mutations = {
  SET_MENU(state, menu) {
    state.menu = menu;

    state.configMenu = [];
    state.moduleMenu = [];
    state.pilotageMenu = [];
    if (menu && typeof menu === "object") {
      Object.values(menu).forEach((subMenu) => {
        if (subMenu.id === "parametrage") {
          Object.values(subMenu.children.items).forEach((item) => {
            state.configMenu.push(item);
          });
        } else if (subMenu.id === "modules") {
          Object.values(subMenu.children.items).forEach((item) => {
            state.moduleMenu.push(item);
          });
        } else if (subMenu.id === "pilotage") {
          Object.values(subMenu.children.items).forEach((item) => {
            state.pilotageMenu.push(item);
          });
        }
      });
    }
  },

  INIT_STUDEA_MANAGER_GENERAL_MODULE(state) {
    state.menu = [];
    state.configMenu = [];
    state.moduleMenu = [];
    state.pilotageMenu = [];
  },

  SET_MODULE_VISIBILITY(state, payload) {
    const module = state.moduleMenu.find((item) => item.id === payload.id);
    module.disabled = payload.visible;
  },
};

const actions = {
  fetchStudeaManagerMenu({ commit }, payload) {
    if (payload.refresh) {
      commit("SET_MENU", []);
    }
    return studeaManagerManager
      .getMenu(payload.trainingCourseId)
      .then((menu) => {
        if (!menu.hasError) {
          commit("SET_MENU", menu.items);
        }
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
