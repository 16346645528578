export default {
  yourSkills: "Vos compétences",
  skills: "Compétences",
  last_skill_update: "Dernier enregistrement",
  duration: "Durée",
  Category: "Categorie",
  "Skills saved successfully": "Compétences enregistrées avec succès",
  next: "Suivante",
  previous: "Précédente",
  seeHistoric: "Afficher l'historique",
  resetSkill: "Réinitialiser la compétence",
  justificativePieceSend: "Pièce justificatives envoyées",
  sendJustificativePiece: "Envoyer des pièces justificatives",
  impossibleJustificativeSending:
    "Ajout de pièces jsutificatives possible après la notation",
  stats: {
    no_data: "Aucune donnée encore saisie",
  },
  no_skills: "Aucune compétence n'est renseignée pour cette catégorie",
  no_skill_available: "Aucune compétence n'est renseignée pour le moment",
  no_skill_complete: "Aucune compétence n'a été complété",
};
