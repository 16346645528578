<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-0">
        <div class="sticky">
          <TriadMemberBlock />
        </div>
      </div>
      <div class="col-lg-9 order-1 order-lg-1">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle text-capitalize">
              {{ $t("page.attestations") }}
            </h2>
          </div>
          <div class="widget-content">
            <SimpleListComponent
              :actions="actions"
              :datas="triad.attestations"
              icon="file-text"
              titleKey="identity"
              @download="download"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import SimpleListComponent from "@/components/table/SimpleListComponent.vue";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { mapState } from "vuex";
import { attestationManager } from "@/services/attestation/attestation-manager";

export default {
  name: "AttestationsView",
  data() {
    return {
      pageTitle: "",
      actions: [
        {
          translation: "PDF export",
          icon: "download",
          type: "primary",
          action: "download",
          cfaStyle: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      triad: (state) => state.triad.triad,
    }),
  },
  created() {
    this.pageTitle = trainingCourseManager.getPageDatas("attestations");
    this.$route.meta.title = this.pageTitle;
  },
  methods: {
    download(row) {
      this.downloadFile(row.year, row.month);
    },
    downloadFile(year, month) {
      attestationManager.getPdf(year, month);
    },
  },
  components: {
    SimpleListComponent,
    TriadMemberBlock,
  },
};
</script>

<style scoped></style>
