import { apiConnection } from "@/services/api-connection";
import store from "@/store";

const URL_POST = "/app/message/post";
const URL_GET = "/app/message/list/";
const URL_REMOVE = "/app/message/remove/";
const URL_OPEN = "/app/message/opened/";
const URL_REPORT = "/app/message/report/";

class MessageManager {
  postNewMessage = (message) => {
    return apiConnection
      .post(URL_POST, { message }, true)
      .then((response) => response);
  };

  getMessages = (archived) => {
    return apiConnection
      .get(
        URL_GET,
        {
          archived: archived,
        },
        true,
      )
      .then((response) => response);
  };

  openMessage = (message) => {
    return apiConnection
      .put(URL_OPEN + message, {})
      .then((response) => response);
  };

  removeMessage = (message) => {
    return apiConnection
      .delete(URL_REMOVE + message, {})
      .then((response) => response);
  };

  reportMessage = (message) => {
    return apiConnection
      .put(URL_REPORT + message.id, {})
      .then((response) => response);
  };

  loadRecipients = (trainingCourse = null) => {
    const url =
      "/app/" +
      (trainingCourse ? trainingCourse + "/" : "") +
      "message/recipients" +
      (trainingCourse ? "" : "/" + store.state.triad.triad.id);
    return apiConnection.get(url).then((response) => response);
  };
}

export const messageManager = new MessageManager();
