<template>
  <div class="expand" @click="$emit('toggleFullScreen')">
    <FontAwesomeIcon
      v-if="isFullScreen"
      icon="fa-light fa-down-left-and-up-right-to-center"
    />
    <FontAwesomeIcon
      v-if="!isFullScreen"
      icon="fa-light fa-up-right-and-down-left-from-center"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ToggleFullScreenComponent",
  components: { FontAwesomeIcon },
  props: {
    isFullScreen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.expand {
  @include up-to-lg {
    display: none;
  }
  cursor: pointer;
}
</style>
