import { openDB } from "idb";

const dbPromise = openDB("studea-db", 1, {
  upgrade(db) {
    db.createObjectStore("questionnaire");
    db.createObjectStore("ufaUser");
    db.createObjectStore("design");
    db.createObjectStore("triad");
  },
});

export async function getIdb(key, entity) {
  return (await dbPromise).get(entity, key);
}

export async function setIdb(key, val, entity) {
  return (await dbPromise).put(entity, val, key);
}

export async function keys(entity) {
  return (await dbPromise).getAllKeys(entity);
}

export async function getAll(entity) {
  return (await dbPromise).getAll(entity);
}
