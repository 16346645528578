import { createI18n } from "vue-i18n";
import { en } from "@/translations/en";
import { fr } from "@/translations/fr";

const messages = {
  en,
  fr,
};

const datetimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    short_time: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    long_date: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    short_time: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },

    long_date: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
  },
};

export const i18n = createI18n({
  locale: navigator.language.split("-")[0].toLowerCase(),
  fallbackLocale: "fr",
  messages,
  datetimeFormats,
});
