import Swal from "sweetalert2/dist/sweetalert2";
import { i18n } from "@/i18n";

class NotificationManager {
  constructor() {}

  showNotification(
    type,
    title,
    timer = 4000,
    position = "top-end",
    showButton = false,
    customClass = "",
  ) {
    const toast = Swal.mixin({
      toast: true,
      position: position,
      showConfirmButton: showButton,
      timer: timer,
      customClass: {
        popup: customClass,
      },
    });
    return toast.fire({
      icon: type,
      title: title,
    });
  }

  showAlert(
    type,
    title,
    subTitle = "",
    closeButton = false,
    cancelButton = false,
    confirmButtonText = null,
    cancelButtonText = null,
  ) {
    const swal = new Swal({
      icon: type,
      title: title,
      text: subTitle,
      padding: "2em",
      showCloseButton: closeButton,
      showCancelButton: cancelButton,
      cancelButtonText: cancelButtonText
        ? cancelButtonText
        : i18n.global.t("cancel"),
      confirmButtonText: confirmButtonText
        ? confirmButtonText
        : i18n.global.t("confirm"),
    });

    const container = document.querySelector(".swal2-container");
    container.addEventListener("click", function (e) {
      e.stopPropagation();
    });

    return swal;
  }
}

export const notificationManager = new NotificationManager();
