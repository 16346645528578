<template>
  <div class="col-xxl-2 col-lg-3 mb-3 mb-lg-0">
    <div class="tab-title note-menu-show">
      <div class="sticky">
        <ul class="nav nav-pills d-block" id="pills-tab3" role="tablist">
          <li class="nav-item">
            <button
              class="nav-link list-actions w-100 text-start"
              :class="{ active: activeFilter === 'all' }"
              @click="filterNotes('all')"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-pen-to-square" />
              {{ $t("notepad.all") }}
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link list-actions w-100 text-start"
              :class="{ active: activeFilter === 'pinned' }"
              @click="filterNotes('pinned')"
            >
              <FontAwesomeIcon class="me-2" icon="fa-solid fa-star" />
              {{ $t("notepad.pinned") }}
            </button>
            <button
              class="nav-link list-actions w-100 text-start"
              :class="{ active: activeFilter === 'unpinned' }"
              @click="filterNotes('unpinned')"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-star" />
              {{ $t("notepad.unpinned") }}
            </button>
          </li>
        </ul>

        <hr />

        <p class="group-section">
          <FontAwesomeIcon icon="fa-light fa-users" />
          {{ $t("notepad.profiles") }}
        </p>

        <ul
          class="nav nav-pills d-block group-list"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link list-actions g-dot-1"
              @click="filterNotes('apprentice')"
              :class="{ active: activeFilter === 'apprentice' }"
              >{{ apprenticeProfileLabel.title }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link list-actions g-dot-2"
              @click="filterNotes('tutor')"
              :class="{ active: activeFilter === 'tutor' }"
              >{{ tutorProfileLabel.title }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link list-actions g-dot-3"
              @click="filterNotes('apprenticeMaster')"
              :class="{ active: activeFilter === 'apprenticeMaster' }"
              >{{ apprenticeMasterProfileLabel.title }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link list-actions g-dot-4"
              @click="filterNotes('studeaManager')"
              :class="{ active: activeFilter === 'studeaManager' }"
              >{{ studeaManagerProfileLabel.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "NotepadFilterComponent",
  components: { FontAwesomeIcon },
  props: {
    activeFilter: {
      type: String,
      required: false,
      default: "all",
    },
  },
  computed: {
    ...mapState({
      apprenticeProfileLabel: (state) => state.platform.apprenticeProfileLabel,
      tutorProfileLabel: (state) => state.platform.tutorProfileLabel,
      apprenticeMasterProfileLabel: (state) =>
        state.platform.apprenticeMasterProfileLabel,
      studeaManagerProfileLabel: (state) =>
        state.platform.studeaManagerProfileLabel,
    }),
  },
  methods: {
    filterNotes(type) {
      this.$emit("filter", type);
    },
  },
};
</script>

<style scoped lang="scss">
span {
  &.--1 {
    color: $green;
  }

  &.--2 {
    color: $blue;
  }

  &.--3 {
    color: $red;
  }

  &.--4 {
    color: $orange;
  }
}
</style>
