<template>
  <div
    v-if="Object.keys(contract).length"
    class="widget"
    data-bs-target="#contractModal"
    data-bs-toggle="modal"
  >
    <div class="widget-heading">
      <h2>{{ $t("myContract") }}</h2>
    </div>
    <div class="widget-content">
      <div class="row">
        <div
          v-if="contract.contractType && contract.contractType.title"
          class="col-5"
        >
          {{ $t("dashboard.contract.type") }}<br />
        </div>
        <div
          v-if="contract.contractType && contract.contractType.title"
          class="col-7"
        >
          <span class="fw-bold textStyle">{{
            contract.contractType.title
          }}</span
          ><br />
        </div>
        <div
          v-if="contract.company && contract.company.companyName"
          class="col-5"
        >
          {{ $t("dashboard.contract.company") }}<br />
        </div>
        <div
          v-if="contract.company && contract.company.companyName"
          class="col-7"
        >
          <span class="fw-bold textStyle">{{
            contract.company.companyName
          }}</span
          ><br />
        </div>
        <div v-if="contract.startDate && contract.endDate" class="col-5">
          {{ $t("dashboard.contract.dates") }}<br />
        </div>
        <div v-if="contract.startDate && contract.endDate" class="col-7">
          <span class="fw-bold textStyle"
            >{{ $t("dashboard.contract.from") }}
            {{ dateFormat(contract.startDate) }}
            {{ $t("dashboard.contract.to") }}
            {{ dateFormat(contract.endDate) }}</span
          ><br />
        </div>
      </div>
    </div>
  </div>
  <div
    id="contractModal"
    aria-hidden="true"
    aria-labelledby="contractModal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">
            {{ $t("myContracts") }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div
          v-for="allContract in triad.contracts"
          :key="allContract"
          class="modal-body"
        >
          <h4
            v-if="allContract.company && allContract.company.companyName"
            class="modal-heading mb-4 mt-2"
          >
            {{ $t("dashboard.contract.company") }}
            {{ allContract.company.companyName }}
          </h4>
          <div class="row">
            <div
              v-if="allContract.contractType && allContract.contractType.title"
              class="col-4 mb-2"
            >
              {{ $t("dashboard.contract.type") }}<br />
            </div>
            <div
              v-if="allContract.contractType && allContract.contractType.title"
              class="col-8"
            >
              <span class="fw-bold textStyle">{{
                allContract.contractType.title
              }}</span
              ><br />
            </div>
          </div>

          <div
            v-if="
              triad.profileLabels &&
              (allContract.apprenticeMaster ||
                allContract.apprenticeMaster2 ||
                allContract.manager)
            "
            class="mb-2 row"
          >
            <div v-if="allContract.apprenticeMaster" class="col-4">
              {{ triad.profileLabels.apprenticeMaster }} :<br />
            </div>
            <div v-if="allContract.apprenticeMaster" class="col-8">
              <span class="fw-bold textStyle">{{
                allContract.apprenticeMaster.fullName +
                " (" +
                allContract.apprenticeMaster.email +
                ")"
              }}</span
              ><br />
            </div>

            <div v-if="allContract.apprenticeMaster2" class="col-4">
              {{ triad.profileLabels.apprenticeMaster }}
              {{ $t("dashboard.contract.secondary") }} :<br />
            </div>
            <div v-if="allContract.apprenticeMaster2" class="col-8">
              <span class="fw-bold textStyle">{{
                allContract.apprenticeMaster2.fullName +
                " (" +
                allContract.apprenticeMaster2.email +
                ")"
              }}</span
              ><br />
            </div>

            <div v-if="allContract.manager" class="col-4">
              {{ triad.profileLabels.manager }} :<br />
            </div>
            <div v-if="allContract.manager" class="col-8">
              <span class="fw-bold textStyle">{{
                allContract.manager.fullName +
                " (" +
                allContract.manager.email +
                ")"
              }}</span
              ><br />
            </div>
          </div>

          <div class="row">
            <div
              v-if="allContract.company && allContract.company.companyName"
              class="col-4"
            >
              {{ $t("dashboard.contract.company") }}<br />
            </div>
            <div
              v-if="allContract.company && allContract.company.companyName"
              class="col-8"
            >
              <span class="fw-bold textStyle">{{
                allContract.company.companyName
              }}</span
              ><br />
            </div>
            <div
              v-if="allContract.company && allContract.company.siretNumber"
              class="col-4"
            >
              {{ $t("dashboard.contract.siret") }}<br />
            </div>
            <div
              v-if="allContract.company && allContract.company.siretNumber"
              class="col-8"
            >
              <span class="fw-bold textStyle">{{
                allContract.company.siretNumber
              }}</span
              ><br />
            </div>
            <div
              v-if="
                allContract.company &&
                allContract.company.address1 &&
                allContract.company.postCode &&
                allContract.company.city
              "
              class="col-4"
            >
              {{ $t("dashboard.contract.address") }}<br />
            </div>
            <div
              v-if="
                allContract.company &&
                allContract.company.address1 &&
                allContract.company.postCode &&
                allContract.company.city
              "
              class="col-8"
            >
              <span class="fw-bold textStyle"
                >{{ allContract.company.address1 }}
                {{ allContract.company.postCode }}
                {{ allContract.company.city }}</span
              ><br />
            </div>
            <div
              v-if="allContract.startDate && allContract.endDate"
              class="col-4"
            >
              {{ $t("dashboard.contract.dates") }}<br />
            </div>
            <div
              v-if="allContract.startDate && allContract.endDate"
              class="col-8"
            >
              <span class="fw-bold textStyle"
                >{{ $t("dashboard.contract.from") }}
                {{ dateFormat(allContract.startDate) }}
                {{ $t("dashboard.contract.to") }}
                {{ dateFormat(allContract.endDate) }}</span
              ><br />
            </div>
            <div v-if="allContract.ruptureDate" class="col-4">
              {{ $t("dashboard.contract.rupture_date") }}<br />
            </div>
            <div v-if="allContract.ruptureDate" class="col-8">
              <span class="fw-bold textStyle"
                >{{ dateFormat(allContract.ruptureDate) }} </span
              ><br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "ContractBlock",
  computed: {
    ...mapState({
      contract: (state) => state.triad.contract,
      triad: (state) => state.triad.triad,
    }),
  },
  methods: {
    dateFormat(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  }
}

.modal-body {
  border-bottom: 1px dashed #e0e6ed;
}

.modal-body:last-child {
  border-bottom: none;
}
</style>
