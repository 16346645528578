import store from "@/store";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { dateFormatter } from "@/services/utilities/date-formatter";

const state = {
  questionnaires: {},
  questionnaire: {},
  model: {},
  toSign: [],
  toComplete: [],
  questionnaireLoading: false,
  userProgression: 0,
  triadProgression: 0,
  total: 0,
  totalTriad: 0,
};

const mutations = {
  SET_QUESTIONNAIRE_LIST(state, questionnaires) {
    state.questionnaires = questionnaires;
  },

  SET_QUESTIONNAIRE_TO_DO_AND_PROGRESS(state, payload) {
    state.toComplete = [];
    state.toSign = [];
    state.totalTriad = 0;
    state.total = 0;
    state.userProgression = 0;
    state.triadProgression = 0;
    Object.values(payload.questionnaires).forEach(function (year) {
      Object.values(year.categories).forEach(function (category) {
        Object.values(category.questionnaires).forEach(
          function (questionnaire) {
            if (questionnaire.userIsSignatory) {
              state.total++;
            }
            if (questionnaire.userHasToComplete) {
              state.toComplete.push({
                title: questionnaire.title,
                date: dateFormatter.format(
                  questionnaire.completenessDate
                    ? questionnaire.completenessDate
                    : questionnaire.userDates.endDate,
                  "short",
                ),
                id: questionnaire.id,
              });
            } else if (questionnaire.userHasToSign) {
              state.toSign.push({
                title: questionnaire.title,
                date: dateFormatter.format(
                  questionnaire.completenessDate
                    ? questionnaire.completenessDate
                    : questionnaire.userDates.endDate,
                  "short",
                ),
                id: questionnaire.id,
              });
            }

            if (questionnaire.userHasSigned) {
              state.userProgression++;
            }

            state.totalTriad += questionnaire.signedBy.length;

            Object.values(questionnaire.lastReply).forEach((reply) => {
              if (reply && reply.signedAt) {
                state.triadProgression++;
              }
            });
          },
        );
      });
    });
  },

  SET_QUESTIONNAIRE(state, questionnaire) {
    state.questionnaire = questionnaire;
  },
  SET_QUESTIONNAIRE_MODEL(state, model) {
    state.model = model;
  },
  SET_ANSWER(state, payload) {
    if (state.model[payload.id]) {
      state.model[payload.id].vars.value = payload.value;
      if (payload.comments) {
        state.model[payload.id].vars.comments = payload.comments;
      }
    }
  },
  INIT_QUESTIONNAIRE_MODULE(state) {
    state.questionnaires = {};
    state.questionnaire = {};
    state.toSign = [];
    state.toComplete = [];
    state.questionnaireLoading = false;
    state.model = {};
    state.displayModel = {};
  },
  SET_QUESTIONNAIRE_REQUEST(state) {
    state.questionnaireLoading = true;
  },
  SET_QUESTIONNAIRE_SUCCESS(state) {
    state.questionnaireLoading = false;
  },
};

const actions = {
  fetchQuestionnaires({ commit }, id) {
    return questionnaireManager
      .loadQuestionnaires(id)
      .then((questionnaires) => {
        commit("SET_QUESTIONNAIRE_LIST", questionnaires);
        commit("SET_QUESTIONNAIRE_TO_DO_AND_PROGRESS", {
          triad: store.state.triad.triad,
          questionnaires: questionnaires,
        });
      });
  },
  fetchQuestionnaire({ commit, dispatch }, payload) {
    if (!payload.refresh) {
      commit("SET_QUESTIONNAIRE_REQUEST");
    }
    if (store.state.isInternet) {
      commit("SET_QUESTIONNAIRE_MODEL", []);
      return questionnaireManager
        .loadQuestionnaire(payload.questionnaire, payload.triad)
        .then((questionnaire) => {
          if (questionnaire) {
            dispatch("initQuestionnaireDatas", questionnaire);
          }
        });
    }
  },
  initQuestionnaireDatas({ commit }, questionnaire) {
    commit("SET_QUESTIONNAIRE", questionnaire);
    const model = questionnaireManager.buildModel();
    commit("SET_QUESTIONNAIRE_MODEL", model);
    commit("SET_QUESTIONNAIRE_SUCCESS");
  },
};

export default {
  state,
  mutations,
  actions,
};
