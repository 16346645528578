<template>
  <div
    class="form-group mt-2 mt-md-none d-flex flex-column justify-content-between flex-sm-row align-items-sm-center"
  >
    <div
      class="d-flex align-items-center justify-content-between mt-3 mt-sm-0 pagination"
      v-if="nbPages > 1"
    >
      <button
        :disabled="activePage === 1"
        class="btn icon"
        type="button"
        @click="changePage(activePage - 1)"
      >
        <FontAwesomeIcon icon="fa-light fa-chevron-left" />
      </button>
      <div class="d-flex align-items-center">
        <button
          :disabled="activePage === 1"
          :class="'btn' + (1 === activePage ? ' active' : '')"
          type="button"
          @click="changePage(1)"
        >
          1
        </button>
        <button
          v-if="activePage > 4"
          :disabled="true"
          class="btn"
          type="button"
        >
          ...
        </button>
        <div v-for="n in nbPages" :key="n">
          <button
            v-if="showButton(n)"
            :disabled="activePage === n"
            :class="'btn' + (n === activePage ? ' active' : '')"
            type="button"
            @click="changePage(n)"
          >
            {{ n }}
          </button>
        </div>
        <button
          v-if="activePage <= nbPages - 4"
          :disabled="true"
          class="btn"
          type="button"
        >
          ...
        </button>
        <button
          :disabled="activePage === nbPages"
          :class="'btn' + (nbPages === activePage ? ' active' : '')"
          type="button"
          @click="changePage(nbPages)"
        >
          {{ nbPages }}
        </button>
        <button
          :disabled="activePage === nbPages"
          class="btn icon"
          type="button"
          @click="changePage(activePage + 1)"
        >
          <FontAwesomeIcon icon="fa-light fa-chevron-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PaginationBlock",
  props: {
    data: {
      type: [],
      required: true,
    },
    offset: {
      type: Number,
      required: true,
      default: 0,
    },
    sliceLimit: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
  },
  components: { FontAwesomeIcon },
  data() {
    return {
      limitedDataList: [],
      activePage: 1,
      nbPages: 1,
      offsetValue: this.offset,
      sliceLimitValue: this.sliceLimit,
    };
  },
  mounted() {
    this.nbPages = Math.ceil(this.data.length / this.limit);
  },
  methods: {
    createLimiteddatalist(limit) {
      return (this.limitedDataList = this.data.slice(this.offsetValue, limit));
    },
    changePage(page) {
      this.activePage = page;
      this.offsetValue = (page - 1) * this.limit;
      this.sliceLimitValue = this.offsetValue + parseInt(this.limit);
      this.limitedDataList = this.createLimiteddatalist(this.sliceLimitValue);
      this.$emit("emitDataList", this.limitedDataList);
      setTimeout(function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 1);
    },
    showButton(n) {
      let mobile = window.innerWidth < 768;
      let rightOffset = mobile ? 2 : 5;
      let leftOffset = mobile ? 1 : 3;
      let delta = mobile ? 1 : 2;

      if (this.activePage >= this.nbPages - leftOffset) {
        leftOffset = mobile ? 2 : 4;
      }

      if (n === 1 || n === this.nbPages) {
        return false;
      }
      if (this.activePage < rightOffset && n <= rightOffset) {
        return true;
      } else if (
        this.activePage > this.nbPages - leftOffset &&
        n >= this.nbPages - leftOffset
      ) {
        return true;
      } else if (
        this.activePage >= rightOffset &&
        n < this.activePage + delta &&
        n > this.activePage - delta
      ) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  font-weight: 700;
  height: 40px;
  @include mobile {
    height: auto;
  }

  .form-control {
    font-weight: 700;
    width: 50px;
    height: 35px;
    padding: 0 10px;
    margin: 0 5px;
  }
}
.pagination {
  .btn {
    margin-right: 5px;
    border-color: $blue;
    background-color: $blue;
    color: $white;
    width: 35px;
    height: 35px;
    @include mobile {
      width: 30px;
      height: 30px;
    }

    &.active {
      color: $blue;
      border: 1px solid $blue;
      background-color: $white;
      &:disabled {
        opacity: 1;
      }
    }

    &.icon {
      border-color: $blue;
      background-color: $blue;
      color: $white;

      &:active,
      &:hover {
        border-color: $blue;
        background-color: $white;
        color: $blue;
      }
    }
  }

  .icon[disabled] {
    border-color: #7381b2;
    background-color: #7381b2 !important;
    opacity: 1 !important;
    color: $white;
  }
}
</style>
