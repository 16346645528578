import store from "@/store";
import { apiConnection } from "@/services/api-connection";
import { fileManager } from "@/services/file/file-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";

class SkillManager {
  getPrefixURL = () => {
    return (
      "/app/skill/" +
      store.state.trainingCourse.trainingCourse.id +
      "/" +
      store.state.triad.triad.id
    );
  };

  postSkills = (skills) => {
    return apiConnection
      .post(
        this.getPrefixURL() + "/save/" + store.state.triad.currentSession.id,
        {
          skills: skills,
        },
        true,
      )
      .then((response) => response);
  };

  getTriadSkills = () => {
    return apiConnection
      .get(this.getPrefixURL() + "/list", {})
      .then((response) => response);
  };

  downloadSkillToPdf = () => {
    return apiConnection
      .get(
        this.getPrefixURL() + "/export/" + store.state.triad.currentSession.id,
        {},
      )
      .then((response) => response);
  };

  getPdf = () => {
    store.commit("FILE_DOWNLOAD_REQUEST");
    return apiConnection
      .get(
        this.getPrefixURL() + "/export",
        {
          triad: store.state.triad.triad.id,
          finalEval: 0,
        },
        true,
      )
      .then((success) => {
        if (success.data) {
          fileManager.saveFile(
            success.fileName,
            success.data,
            "application/pdf",
          );
        } else {
          notificationManager.showAlert("error", i18n.global.t("error_file"));
        }
        store.commit("FILE_DOWNLOAD_SUCCESS");
      });
  };

  getTriadSkillsHistoric = (skill) => {
    return apiConnection
      .get(this.getPrefixURL() + "/historic" + "/" + skill, {}, true)
      .then((response) => response);
  };

  getJustificativeForm(id = null) {
    return apiConnection
      .get(
        "/app/skill/" +
          store.state.trainingCourse.trainingCourse.id +
          "/" +
          store.state.triad.triad.id +
          "/justificative-form" +
          (id ? "/" + id : ""),
      )
      .then((response) => response);
  }

  submitJustificativeForm(id, result) {
    return apiConnection
      .put(
        "/app/skill/" +
          store.state.trainingCourse.trainingCourse.id +
          "/" +
          store.state.triad.triad.id +
          "/justificative-post" +
          (id ? "/" + id : ""),
        result,
      )
      .then((response) => response);
  }

  initSession() {
    store.commit(
      "SET_CURRENT_SESSION",
      store.state.triad.sessions.find(
        (session) =>
          new Date(session.startDate) < Date.now() &&
          new Date(session.endDate) > Date.now(),
      ),
    );
  }
}

export const skillManager = new SkillManager();
