import Vuex from "vuex";
import user from "@/store/user/user";
import platform from "@/store/platform/platform";
import questionnaire from "@/store/questionnaire/questionnaire";
import theme from "@/store/theme/theme";
import trainingCourse from "@/store/training-course/training-course";
import triad from "@/store/triad/triad";
import document from "@/store/document/document";
import questionnaireEditor from "@/store/studea_manager/questionnaire/questionnaire";
import admin from "@/store/admin/admin";
import chatbot from "@/store/chatbot/chatbot";
import general from "@/store/studea_manager/general/general";
import news from "@/store/news/news";
import studeaManagerSkillDashboard from "@/store/studea_manager/dashboard/dashboardSkill";
import studeaManagerNoteDashboard from "@/store/studea_manager/dashboard/dashboardNote";
import error from "@/store/error/error";
import message from "@/store/message/message";

// import {apiConnection} from "@/services/api-connection";

const store = new Vuex.Store({
  modules: {
    user,
    platform,
    questionnaire,
    theme,
    trainingCourse,
    triad,
    document,
    questionnaireEditor,
    admin,
    chatbot,
    general,
    news,
    studeaManagerSkillDashboard,
    studeaManagerNoteDashboard,
    error,
    message,
  },
  state() {
    return {
      isInternet: navigator.onLine,
      isCheckingInternet: false,
      isMobile: false,
      appLoading: false,
      fileDownloading: false,
      saving: false,
      trainingCourseMenu: [],
      breadcrumb: [],
      sideBarOpened: window.innerWidth > 768,
      helpDocuments: [],
      showHelpDocuments: false,
      windowWidth: 1920,
      trainingCoursePilotage: [
        {
          title: "Tableau de bord",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Tableau des notes",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Tableau de bord compétences",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Exports",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Suivi des mails",
          pathName: "trainingCourseMessaging",
        },
        {
          title: "Historique de connexion",
          pathName: "trainingCourseMessaging",
        },
      ],
    };
  },
  mutations: {
    SET_APP_LOADING(state, value) {
      state.appLoading = value;
    },
    SET_HELP_DOCUMENTS(state, helpDocuments) {
      state.helpDocuments = helpDocuments;
    },
    SET_SHOW_HELP_DOCUMENTS(state, value) {
      state.showHelpDocuments = value;
    },
    SET_MOBILE_APP(state, value) {
      state.isMobile = value;
    },
    IS_CHEKING_INTERNET_CONNEXION(state, value) {
      state.isCheckingInternet = value;
    },
    FILE_DOWNLOAD_REQUEST(state) {
      state.fileDownloading = true;
    },
    FILE_DOWNLOAD_SUCCESS(state) {
      state.fileDownloading = false;
    },
    SET_INTERNET(state, value) {
      state.isInternet = value;
    },
    SET_TRAINING_MENU(state, list) {
      state.trainingCourseMenu = list;
    },
    SET_LIST(state, payload) {
      state[payload.storeKey][payload.listKey] = payload.list;
    },
    SAVING_REQUEST(state) {
      state.saving = true;
    },
    SAVING_SUCCESS(state) {
      state.saving = false;
    },
    TOGGLE_SIDE_BAR(state) {
      state.sideBarOpened = !state.sideBarOpened;
    },
    CLOSE_SIDE_BAR(state) {
      state.sideBarOpened = false;
    },
    OPEN_SIDE_BAR(state) {
      state.sideBarOpened = true;
    },
    INIT_BREADCRUMB(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    SET_WINDOW_WIDTH(state, width) {
      state.windowWidth = width;
    },
  },
  actions: {
    initStore({ commit }) {
      commit("INIT_QUESTIONNAIRE_MODULE");
      commit("INIT_TRAINING_COURSE_MODULE");
      commit("INIT_USER_MODULE");
      commit("INIT_WEBSITE_MODULE");
      commit("INIT_TRIAD_MODULE");
      commit("INIT_STUDEA_MANAGER_GENERAL_MODULE");
      commit("INIT_ADMIN_MODULE");
    },
  },
});

export default store;
