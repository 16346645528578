import { adminManager } from "@/services/admin/admin-manager";

const state = {
  menu: [],
  modules: [],
};

const mutations = {
  SET_MENU(state, menu) {
    state.menu = menu;
  },

  SET_MODULES(state, menu) {
    state.modules = menu;
  },

  INIT_ADMIN_MODULE(state) {
    state.menu = [];
    state.modules = [];
  },
};

const actions = {
  fetchMenu({ commit }, init) {
    if (init) {
      commit("SET_MENU", []);
    }
    return adminManager.getMenu().then((menu) => {
      if (menu && typeof menu === "object") {
        commit("SET_MENU", menu.items);
      }
      commit("SET_APP_LOADING", false);
    });
  },

  fetchModules({ commit }) {
    return adminManager.list("module", false).then((modules) => {
      if (modules && typeof modules === "object") {
        commit("SET_MODULES", modules);
      }
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
