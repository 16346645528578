<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-0">
        <div class="sticky">
          <TriadMemberBlock />
        </div>
      </div>
      <div class="col-lg-9 order-1 order-lg-1">
        <div v-if="Object.keys(subjects).length">
          <div v-if="!id">
            <template v-for="subject in subjects" :key="subject.id">
              <div v-if="Object.keys(subject.lessons).length" class="widget">
                <div class="widget-heading">
                  <h2 class="textStyle text-capitalize">{{ subject.name }}</h2>
                </div>
                <div class="widget-content">
                  <SimpleListComponent
                    :actions="actions"
                    :datas="subject.lessons"
                    icon="person-chalkboard"
                  />
                </div>
              </div>
            </template>
          </div>
          <div v-else class="col-lg-9 order-1 order-lg-1">
            <div class="widget">
              <div v-if="this.watching.title" class="widget-heading">
                <h2 class="textStyle">{{ this.watching.title }}</h2>
              </div>
              <div class="widget-content">
                <div v-if="this.watching.subject" class="mb-3">
                  <h3 class="textStyle">{{ $t("Taught subject") }}</h3>
                  <p class="subject">{{ this.watching.subject }}</p>
                </div>
                <div v-if="this.watching.activity" class="mb-3">
                  <h3 class="textStyle">{{ $t("activityDone") }}</h3>
                  <p v-html="this.watching.activity"></p>
                </div>
                <div v-if="this.watching.workToDo" class="mb-3">
                  <h3 class="textStyle">{{ $t("workToDo") }}</h3>
                  <p v-html="this.watching.workToDo"></p>
                </div>
                <div v-if="this.watching.link" class="mb-3">
                  <h3 class="textStyle">{{ $t("lessonLink") }}</h3>
                  <a :href="this.watching.link" target="_blank">{{
                    this.watching.link
                  }}</a>
                </div>
                <h3 v-if="isFiles()" class="textStyle d-flex mb-3">
                  {{ $t("attachments") }}
                </h3>
                <div>
                  <template v-for="n in 4" :key="n">
                    <div
                      v-if="this.watching['lessonFile' + (n > 1 ? n : '')]"
                      class="allLessonFile"
                    >
                      <p class="textStyle mt-2">
                        {{ $t("attachment") }} {{ n }}
                      </p>
                      <img
                        v-if="
                          this.watching['lessonFile' + (n > 1 ? n : '')]
                            .isPicture
                        "
                        :alt="
                          this.watching['lessonFile' + (n > 1 ? n : '')]
                            .originalName
                        "
                        :src="
                          this.watching['lessonFile' + (n > 1 ? n : '')].imgSrc
                        "
                        class="rounded img-thumbnail mb-2"
                      />
                      <p
                        v-if="
                          !this.watching['lessonFile' + (n > 1 ? n : '')]
                            .isPicture
                        "
                      >
                        <FontAwesomeIcon icon="fa-light fa-file" />
                        {{
                          this.watching["lessonFile" + (n > 1 ? n : "")]
                            .originalName
                        }}
                      </p>
                      <a
                        :href="
                          this.watching['lessonFile' + (n > 1 ? n : '')].dlSrc
                        "
                        class="btn btn-primary btnStyle mt-1 mb-3"
                      >
                        {{ $t("download") }}
                      </a>
                    </div>
                  </template>
                </div>
              </div>
              <div class="widget-footer">
                <button
                  class="btn btn-success btnStyle"
                  type="button"
                  @click="closeLesson"
                >
                  <FontAwesomeIcon icon="fa-light fa-arrow-left" />
                  {{ $t("back") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{
            $tc("global.empty.dashboard", 1, {
              name: this.$tc("studea_manager.lesson.title", 2).toLowerCase(),
            })
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleListComponent from "@/components/table/SimpleListComponent.vue";
import { mapState } from "vuex";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { pictureManager } from "@/services/utilities/picture-manager";
import { fileManager } from "@/services/file/file-manager";

export default {
  name: "LessonView",
  data() {
    return {
      watching: false,
      id: this.$router.currentRoute.value.params.id
        ? parseInt(this.$router.currentRoute.value.params.id)
        : 0,
      pageTitle: "",
      actions: [
        {
          translation: "Watch",
          icon: "eye",
          type: "primary",
          action: "lesson",
          cfaStyle: true,
          actionType: "router",
        },
      ],
    };
  },
  mounted() {
    this.retrieveLessonToWatch();
  },
  created() {
    trainingCourseManager.setPageTitle(
      trainingCourseManager.MODULE_STUDEA_COURSE,
    );
  },
  computed: {
    ...mapState({
      subjects: (state) => state.triad.subjects,
      design: (state) => state.platform.design,
    }),
  },
  watch: {
    "$route.params.id"() {
      this.retrieveLessonToWatch();
    },
    subjects() {
      this.retrieveLessonToWatch();
      trainingCourseManager.setPageTitle(
        trainingCourseManager.MODULE_STUDEA_COURSE,
      );
    },
  },
  methods: {
    getPictureSrc(file) {
      return pictureManager.setPicture(file);
    },
    getDownloadUrl(file) {
      return fileManager.getDownloadSrc(file);
    },
    retrieveLessonToWatch() {
      this.id = this.$route.params.id ? parseInt(this.$route.params.id) : 0;
      this.watching = false;
      if (this.subjects && Object.keys(this.subjects).length) {
        Object.values(this.subjects).forEach((subject) => {
          if (!this.watching) {
            this.watching = subject.lessons.find((item) => item.id === this.id);
            if (this.watching) {
              this.watching["subject"] = subject.name;
              this.checkFile();
            }
          }
        });
      }
    },

    checkFile() {
      if (this.watching.lessonFile) {
        this.setPicture(this.watching.lessonFile);
      }
      if (this.watching.lessonFile2) {
        this.setPicture(this.watching.lessonFile2);
      }
      if (this.watching.lessonFile3) {
        this.setPicture(this.watching.lessonFile3);
      }
      if (this.watching.lessonFile4) {
        this.setPicture(this.watching.lessonFile4);
      }
    },

    setPicture(file) {
      file["isPicture"] = this.isPicture(file);
      if (file.isPicture) {
        file["imgSrc"] = this.getPictureSrc(file);
      }
      file["dlSrc"] = this.getDownloadUrl(file);
    },

    isPicture(file) {
      return new RegExp("([./])(gif|jpe?g|png|svg+xml)$").test(
        file.originalName,
      );
    },

    isFiles() {
      return (
        this.watching &&
        (this.watching.lessonFile ||
          this.watching.lessonFile2 ||
          this.watching.lessonFile3 ||
          this.watching.lessonFile4)
      );
    },

    closeLesson() {
      this.$router.push({ name: "MODULE_STUDEA_COURSE" });
    },
  },
  components: {
    FontAwesomeIcon,
    TriadMemberBlock,
    SimpleListComponent,
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
}

div img {
  max-width: 100%;
}

.allLessonFile {
  border-top: 1px dashed #e0e6ed;

  &:nth-child(1) {
    border: none;
  }
}
</style>
