import { i18n } from "@/i18n";

class DateFormatter {
  format(date, type) {
    const locale = i18n.global.locale;
    const object = new Date(date);
    return i18n.global.d(object, type, locale);
  }
}

export const dateFormatter = new DateFormatter();
