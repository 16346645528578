export default {
  tutorPart: ({ plural, named }) =>
    plural([
      "Vous êtes tuteur sur <strong>une</strong> formation",
      `Vous êtes tuteur sur <strong>${named("number")}</strong> formations`,
    ]),
  studeaManagerPart: ({ plural, named }) =>
    plural([
      "Vous êtes actuellement Admin sur <strong>une</strong> formation",
      `Vous êtes actuellement Admin sur <strong>${named("number")}</strong> formations`,
    ]),
  apprenticeMasterPart: ({ plural, named }) =>
    plural([
      "Vous êtes actuellement maître d'apprentissage sur <strong>une</strong> formation",
      `Vous êtes actuellement maître d'apprentissage sur <strong>${named("number")}</strong> formations`,
    ]),
  promotion: "Promotion",
  "Training courses list": "Liste des formations",
  loadingTrainingCourses: "Chargement des formations ...",
  trainingCourseInfos: "Informations sur la formation",
  "Useful links": "Liens utiles",
};
