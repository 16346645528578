<template>
  <div v-if="dataLength" class="progress progress-bar-stack" min="0">
    <span :class="invertColor ? 'progress__text white' : 'progress__text'"
      >{{ title }} {{ progress }}/{{ dataLength }}</span
    >
    <div
      :aria-valuenow="progress"
      :style="'width:' + getProgressPurcent + '%'"
      aria-valuemax="100"
      aria-valuemin="0"
      class="progress-bar bg-gradient-success"
      role="progressbar"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ScrollProgressBarComponent",
  props: {
    dataLength: {
      type: Number,
      required: true,
      default: 0,
    },
    elementId: {
      type: String,
      required: false,
      default: "",
    },
    step: {
      type: Number,
      required: false,
      default: 0,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      progress: 1,
      invertColor: false,
    };
  },
  computed: {
    getProgressPurcent() {
      return (this.progress / this.dataLength) * 100;
    },
  },
  watch: {
    step() {
      this.initProgressBar();
    },
  },
  mounted() {
    this.initEvents();
    document.getElementById("content").classList.add("-progressBar");
  },
  unmounted() {
    this.clearEvents();
    document.getElementById("content").classList.remove("-progressBar");
  },
  methods: {
    clearEvents() {
      window.removeEventListener("scroll", this.initProgressBar);
    },
    initEvents() {
      window.addEventListener("scroll", this.initProgressBar);
    },
    initProgressBar() {
      const element = document.getElementById(this.elementId);
      if (this.dataLength && element && this.elementId) {
        // const scrollY     = window.scrollY;
        const totalOffset = element.offsetHeight - element.offsetTop;
        let progress = Math.ceil(
          (this.dataLength * (window.scrollY + window.innerHeight / 2)) /
            totalOffset,
        );
        progress = progress > 0 ? progress : 1;
        progress = progress < this.dataLength ? progress : this.dataLength;

        this.progress = progress;
      }

      if (this.step) {
        this.progress = this.step;
      }
      this.invertColor =
        window.innerWidth < 768
          ? this.getProgressPurcent > 62
          : this.getProgressPurcent > 50;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: fixed;
  top: 112px;
  left: 0;
  right: 0;
  z-index: 1000;
  background: $white;
  height: 17px;
  font-size: 11px;

  @include up-to-lg {
    top: 92px;
  }

  @include dark-theme {
    background: $m-color_8;
  }

  &__text {
    color: $black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    transition: color 300ms ease-in-out;

    @include dark-theme {
      color: $white;
    }

    &.white {
      color: $white;
    }
  }
}
</style>
