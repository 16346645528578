<template>
  <FormTabsBlockComponent
    :key="componentKey"
    :loading="loading"
    :tabs="tabs"
    :keepHideValue="true"
    :removeSubmit="true"
    @formSubmit="submit"
  />
  <div class="widget">
    <SubmitTypeComponent :model="submitModel" @submitForm="submit()" />
  </div>
</template>

<script>
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { formValidator } from "@/services/form/form-validator";
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";

export default {
  name: "HomeView",
  components: {
    SubmitTypeComponent,
    FormTabsBlockComponent,
  },
  data() {
    return {
      componentKey: 0,
      loading: false,
      tabs: [],
      submitModel: {
        vars: {
          block_prefixes: ["", "submit"],
          hideCancel: true,
          display: true,
          id: "submit",
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      studeaManagerManager
        .form("home", this.$route.params.trainingCourseId)
        .then((data) => {
          this.tabs = data.tabs;
          this.loading = false;
        });
    },

    submit() {
      let response = [];
      this.loading = true;
      this.invalidTab = null;

      Object.values(this.tabs).forEach((tab) => {
        if (!tab.hidden) {
          response = {
            ...response,
            ...formManager.processForm(tab.models, false),
          };
        }
      });

      let urlCall =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/generic/training-course/post/" +
        this.$route.params.trainingCourseId;
      apiConnection.put(urlCall, response).then((data) => {
        if (data && !data.hasError) {
          this.$router.push({
            name: "studeaManagerSkin",
            query: { time: Date.now() },
          });
          notificationManager.showNotification(
            "success",
            this.$tc("global.edit.success", 1, {
              name: this.$t("studea_manager.skin.title"),
            }),
          );
        } else if (data.responses && data.responses[0].validationErrors) {
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
          Object.entries(this.tabs).forEach((tab) => {
            const errors = formValidator.processResponses(
              data.responses[0].validationErrors,
              tab[1].models,
            );
            if (errors.length) {
              this.invalidTab = this.id ? tab[1] : tab[0];
            }
          });
        }
        this.init();
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
