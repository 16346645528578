<template>
  <div
    :class="
      (loading ? 'backdrop -on ' : 'backdrop ') +
      ' ' +
      (position ? position : '')
    "
  >
    <div class="spinner-border text-primary align-self-center loader"></div>
  </div>
</template>

<script>
export default {
  name: "BlockLoader",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    position: {
      type: String,
      required: false,
      default: "center",
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
  z-index: 1000;
  border-radius: 6px;
  overflow: hidden;

  &.-on {
    opacity: 1;
    visibility: visible;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    opacity: 0.5;
    transition: all 300ms ease-in-out;
  }

  &.fixed {
    .loader {
      position: fixed;
      top: 50%;
    }
  }
}

.loader {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  z-index: 10001;
}

.spinner-border {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  text-indent: -999em;
  border: 0.25em solid;
  border-color: currentColor transparent currentColor currentColor;
  border-radius: 50%;
  animation-name: spinnerBorder;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spinnerBorder {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
