<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-9 order-1 order-lg-0">
        <div class="row app-notes" id="cancel-row">
          <div class="col-lg-12">
            <div class="app-container">
              <div class="app-note-container">
                <div class="row">
                  <NotepadFilterComponent
                    :activeFilter="activeFilter"
                    @filter="filterNotes"
                  />
                  <div
                    v-if="
                      this.filteredNotes &&
                      Object.keys(this.filteredNotes).length
                    "
                    class="col-xxl-10 col-lg-9 order-2 order-lg-1"
                  >
                    <div
                      id="ct"
                      class="note-container note-grid row"
                      v-if="filteredNotes.filter((note) => note.pinned).length"
                    >
                      <div
                        v-for="(note, index) in filteredNotes.filter(
                          (note) => note.pinned,
                        )"
                        class="note-item all-notes col-xl-6"
                        :key="index"
                      >
                        <NotepadNoteComponent
                          :note="note"
                          @show="show"
                          @openModal="openModal"
                          @initTriad="initTriad"
                        />
                      </div>
                      <div
                        v-if="
                          filteredNotes.filter((note) => !note.pinned).length
                        "
                        class="col-12"
                      >
                        <div class="separation"></div>
                      </div>
                    </div>
                    <div id="ct" class="note-container note-grid row">
                      <div
                        v-for="(note, index) in filteredNotes.filter(
                          (note) => !note.pinned,
                        )"
                        class="note-item all-notes col-xl-3 col-md-6"
                        :key="index"
                      >
                        <NotepadNoteComponent
                          :note="note"
                          @show="show"
                          @openModal="openModal"
                          @initTriad="initTriad"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
      <div class="col-lg-3 order-lg-2 order-0 order-lg-1">
        <div class="sticky">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            :key="key"
            @add="openModal"
          />
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent :models="models" @submit="submit" />
  <ModalComponent
    :title="modalTitle"
    :content="modalContent"
    :opened="modalOpened"
    @hidden="cancelShow"
  />
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { notepadManager } from "@/services/notepad/notepad-manager";
import { formManager } from "@/services/form/form-manager";
import { mapState } from "vuex";
import { notificationManager } from "@/services/utilities/notification-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import NotepadNoteComponent from "@/components/notepad/NotepadNoteComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import NotepadFilterComponent from "@/components/notepad/NotepadFilterComponent.vue";

export default {
  name: "NotepadView",
  components: {
    NotepadFilterComponent,
    NotepadNoteComponent,
    ModalComponent,
    BlockLoader,
    ModalFormComponent,
    ActionsBlock,
  },
  data() {
    return {
      models: [],
      filteredNotes: [],
      id: null,
      loading: false,
      modalTitle: "",
      modalContent: "",
      modalOpened: false,
      activeFilter: "all",
      key: 0,
      actions: [
        {
          translation: "new_note",
          icon: "clipboard-list-check",
          type: "primary",
          action: "add",
          actionType: "button",
          cfaStyle: true,
          disabled: false,
        },
      ],
      notes: [
        {
          title: "test",
          date: "21/02/2021",
          description: "Test description de la note",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      triad: (state) => state.triad.triad,
      trainingCourse: (state) => state.trainingCourse.trainingCourse,
      notepads: (state) => state.triad.notepads,
      user: (state) => state.user.user,
    }),
  },
  watch: {
    activeFilter() {
      this.applyFilter();
    },
    notepads() {
      this.applyFilter();
    },
    triad() {
      trainingCourseManager.setPageTitle(
        trainingCourseManager.MODULE_STUDEA_NOTEPAD,
      );
    },
    user() {
      this.checkUserRoles();
    },
  },
  mounted() {
    this.applyFilter();
    this.checkUserRoles();
  },
  created() {
    trainingCourseManager.setPageTitle(
      trainingCourseManager.MODULE_STUDEA_NOTEPAD,
    );
  },
  methods: {
    openModal(id = null) {
      this.loading = true;
      this.id = id;
      this.models = [];
      notepadManager.form(id).then((data) => {
        this.models = data;
        this.loading = false;
      });
    },

    checkUserRoles() {
      if (
        this.user &&
        this.user.roles &&
        this.user.roles.includes("ROLE_STUDEA_ADMIN")
      ) {
        this.actions[0].disabled = true;
      }
    },

    cancelShow() {
      this.modalOpened = false;
      this.modalContent = "";
      this.modalTitle = "";
    },

    show(note) {
      this.modalTitle = note.title;
      this.modalContent =
        "<div class='lead'>" +
        note.user.fullName +
        " - " +
        dateFormatter.format(note.date, "long") +
        "</div>";
      this.modalContent = this.modalContent.concat(note.text);
      this.modalOpened = true;
    },

    filterNotes(type) {
      this.activeFilter = type;
    },

    applyFilter() {
      if (this.activeFilter === "all") {
        this.filteredNotes = this.notepads;
      } else {
        this.filteredNotes = this.notepads.filter((note) => {
          return (
            (this.activeFilter === "pinned" && note.pinned) ||
            (this.activeFilter === "unpinned" && !note.pinned) ||
            (this.activeFilter === "apprentice" && note.profile === 1) ||
            (this.activeFilter === "tutor" && note.profile === 2) ||
            (this.activeFilter === "apprenticeMaster" && note.profile === 3) ||
            (this.activeFilter === "studeaManager" && note.profile === 4)
          );
        });
      }
    },

    initTriad() {
      this.$store
        .dispatch("changeTriad", {
          triadId: this.triad.id,
          trainingCourseId: this.trainingCourse.id,
          disableLoading: true,
        })
        .then(() => {
          this.loading = false;
        });
    },

    submit() {
      const result = formManager.processForm(this.models, false, this.id);
      result["triad"] = { id: this.triad.id };
      if (this.id) {
        result["id"] = this.id;
      }
      this.loading = true;
      notepadManager.post(result, this.id).then((data) => {
        if (data && !data.hasError) {
          const message = this.$tc(
            "global." + (this.id ? "edit" : "add") + ".success",
            2,
            {
              name: this.$t("studea_manager.notepad.title", 1),
            },
          );

          notificationManager.showNotification("success", message);
          this.models = [];
          this.initTriad();
        } else {
          notificationManager.showNotification(
            "error",
            data.message ? data.message : this.$t("errors"),
          );
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.separation {
  margin: 15px 0 30px 0;
  border-top: 1px dashed $m-color_4;
}
</style>
