<template>
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <div class="loader-block">
        <div v-if="units.length">
          <VueDraggableNext
            :animation="200"
            :delay="200"
            :delay-on-touch-only="true"
            :group="{ name: 'units' }"
            :list="units"
            handle=".grip"
            :disabled="
              !accessManager().isAvailableForUser(accessManager().EDIT)
            "
            @change="sortUnit"
          >
            <div v-for="unit in units" :key="unit.id" class="widget">
              <div class="widget-heading grip flex-column flex-md-row">
                <h2 class="textStyle">
                  <button
                    class="btn-icon"
                    v-tooltip="{ title: $t('back') }"
                    @click="$router.back()"
                  >
                    <FontAwesomeIcon icon="fa-light fa-arrow-left" />
                  </button>
                  {{ unit.name }}
                </h2>
                <div class="mt-2 mt-md-0">
                  <router-link
                    :to="{
                      name: 'studeaManagerSubjectAdd',
                      params: { alias: 'subject' },
                      query: {
                        set: JSON.stringify([
                          {
                            name: 'unit',
                            value: unit.id,
                          },
                        ]),
                      },
                    }"
                    v-access:[$store.state.user.permissions]="
                      accessManager().EDIT
                    "
                    class="list__button"
                    v-tooltip="{
                      title: $tc('global.add.title', 2, {
                        name: this.$tc(
                          'studea_manager.subject.title',
                          1,
                        ).toLowerCase(),
                      }),
                    }"
                  >
                    <FontAwesomeIcon icon="fa-light fa-plus" />
                  </router-link>
                  <router-link
                    :to="{
                      name: 'studeaManagerUnitEdit',
                      params: { id: unit.id, alias: 'unit' },
                    }"
                    class="list__button"
                    v-tooltip="{
                      title: $tc('global.edit.title', 2, {
                        name: this.$tc(
                          'studea_manager.unit.title',
                          1,
                        ).toLowerCase(),
                      }),
                    }"
                    v-access:[$store.state.user.permissions]="
                      accessManager().EDIT
                    "
                  >
                    <FontAwesomeIcon icon="fa-light fa-edit" />
                  </router-link>
                  <button
                    v-tooltip="{
                      title: $tc('global.delete.title', 2, {
                        name: this.$tc(
                          'studea_manager.unit.title',
                          1,
                        ).toLowerCase(),
                      }),
                    }"
                    v-access:[$store.state.user.permissions]="
                      accessManager().DELETE
                    "
                    class="list__button -skill"
                    @click="remove(unit.id, 'unit')"
                  >
                    <FontAwesomeIcon icon="fa-light fa-trash" />
                  </button>
                </div>
              </div>
              <div class="widget-content">
                <div
                  class="list"
                  v-if="
                    unit && unit.subjects && Object.keys(unit.subjects).length
                  "
                >
                  <div class="list__subject row -heading">
                    <div class="col-md-11 col-9">
                      {{ $t("global.label.name") }}
                    </div>
                    <div class="col-md-1 col-3"></div>
                  </div>
                  <VueDraggableNext
                    :animation="200"
                    :delay="200"
                    :delay-on-touch-only="true"
                    :group="{ name: 'subjects' }"
                    :list="unit.subjects"
                    :disabled="
                      !accessManager().isAvailableForUser(accessManager().EDIT)
                    "
                    @change="sortSubject($event, unit.id)"
                  >
                    <div
                      v-for="subject in unit.subjects"
                      :key="subject.id"
                      class="list__subject row grip"
                    >
                      <div class="col-md-11 col-12 mb-2 mb-md-0">
                        {{ subject.name }} (Réf : {{ subject.id }})
                      </div>
                      <div class="col-md-1 col-12 pe-0">
                        <div class="list__action">
                          <router-link
                            :to="{
                              name: 'studeaManagerSubjectEdit',
                              params: { id: subject.id, alias: 'subject' },
                            }"
                            class="list__button"
                            v-tooltip="{
                              title: $tc('global.edit.title', 2, {
                                name: this.$tc(
                                  'studea_manager.subject.title',
                                  1,
                                ).toLowerCase(),
                              }),
                            }"
                            v-access:[$store.state.user.permissions]="
                              accessManager().EDIT
                            "
                          >
                            <FontAwesomeIcon icon="fa-light fa-edit" />
                          </router-link>
                          <button
                            v-tooltip="{
                              title: $tc('global.delete.title', 2, {
                                name: this.$tc(
                                  'studea_manager.subject.title',
                                  1,
                                ).toLowerCase(),
                              }),
                            }"
                            class="list__button"
                            v-access:[$store.state.user.permissions]="
                              accessManager().DELETE
                            "
                            @click="remove(subject.id, 'subject')"
                          >
                            <FontAwesomeIcon icon="fa-light fa-trash" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </VueDraggableNext>
                </div>
                <div class="mt-2" v-else>
                  <div class="textStyle fst-italic">
                    {{
                      $tc("global.empty.title", 2, {
                        name1: this.$tc(
                          "studea_manager.unit.title",
                          1,
                        ).toLowerCase(),
                        name2: this.$tc(
                          "studea_manager.subject.title",
                          1,
                        ).toLowerCase(),
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
          </VueDraggableNext>
        </div>
        <div v-else class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">
              {{
                $tc("global.list.title", 2, {
                  name: this.$tc(
                    "studea_manager.subject.title",
                    2,
                  ).toLowerCase(),
                })
              }}
            </h2>
          </div>
          <div class="widget-content">
            <div class="text-center">
              <div class="noResult blockStyle">
                {{ $t("No data available") }}<br />
                {{ $t("Please click on the add button") }}
              </div>
              <router-link
                :to="{
                  name: 'studeaManagerUnitAdd',
                  params: { alias: 'unit' },
                }"
                class="btn btn-primary mb-3"
                v-access:[$store.state.user.permissions]="accessManager().EDIT"
                >{{
                  $tc("global.add.title", 2, {
                    name: this.$tc(
                      "studea_manager.unit.title",
                      1,
                    ).toLowerCase(),
                  })
                }}
              </router-link>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            :collapsable="false"
            collapseId="actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { accessManager } from "@/services/security/access-manager";

export default {
  name: "SubjectListView",
  components: {
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
    VueDraggableNext,
  },
  data() {
    return {
      units: [],
      loading: false,
      actions: [
        {
          translation: "global.add.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.unit.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerUnitAdd",
          cfaStyle: false,
          params: { alias: "unit" },
          access: accessManager.EDIT,
        },
        {
          translation: "global.add.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.subject.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSubjectAdd",
          cfaStyle: false,
          params: { alias: "subject" },
          access: accessManager.EDIT,
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    init(loading = true) {
      this.loading = loading;
      studeaManagerManager
        .list("subject", this.$route.params.trainingCourseId)
        .then((res) => {
          this.units = res;
          if (!this.units.length) {
            this.actions.pop();
          }

          this.loading = false;
        });
    },

    sortUnit(event) {
      const id = event.moved.element.id;
      const position = event.moved.newIndex;
      studeaManagerManager
        .sort("unit", this.$route.params.trainingCourseId, id, position)
        .then(() => {
          this.init(false);
        });
    },

    sortSubject(event, unitId) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        studeaManagerManager
          .sort("subject", this.$route.params.trainingCourseId, id, position)
          .then(() => {
            this.init(false);
          });
      } else if (event.added) {
        const id = event.added.element.id;
        studeaManagerManager
          .move("subject", this.$route.params.trainingCourseId, id, unitId, 2)
          .then(() => {
            this.init(false);
          });
      }
    },

    remove(id, alias) {
      studeaManagerManager
        .remove(alias, this.$route.params.trainingCourseId, id, 2)
        .then(() => {
          this.init();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
}

.grip {
  cursor: grab;
}

.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__subject {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &.-action {
      width: 15%;
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }
}
</style>
