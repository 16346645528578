export default {
  absence: {
    absence: "Absence",
    absences: "Absences",
    course: "Cours",
    company: "Entreprise",
    add_notification: "Envoyer une notification de nouvelle attestation",
    add_event: "Ajouter un évènement",
    edit_event: "Modifier un évènement",
    delete_event: "Supprimer un évènement",
    delete_events: "Supprimer des évènements",
    delete_event_sub_title:
      "Êtes-vous sûr de vouloir supprimer cet évènement ?",
    delete_events_sub_title:
      "Êtes-vous sûr de vouloir supprimer ces évènements ?",
    delete_event_success: "Évènement supprimé avec succès !",
    copy_event_success: "Évènement copié avec succès !",
    cut_event_success: "Évènement coupé avec succès !",
    delete_events_success: "Évènements supprimés avec succès !",
    post_event: "Évènement ajouté avec succès !",
    list_planning: "Lister le planning",
    list_planning_search: "Rechercher des évènements",
    import_planning: "Importer le planning",
    import_planning_success: "Planning importé avec succès !",
    export_planning: "Exporter le planning",
    export_planning_success: "Planning exporté avec succès !",
    add_absence: "Ajouter une absence(s)",
    edit_absence: "Modifier une absence",
    delete_absence: "Supprimer une absence",
    delete_absences: "Supprimer des absences",
    delete_absence_sub_title:
      "Êtes-vous sûr de vouloir supprimer cette absence ?",
    delete_absences_sub_title:
      "Êtes-vous sûr de vouloir supprimer ces absences ?",
    delete_absence_success: "Absence supprimée avec succès !",
    copy_absence_success: "Absence copiée avec succès !",
    cut_absence_success: "Absence coupée avec succès !",
    delete_absences_success: "Absences supprimées avec succès !",
    post_absence: "Absence ajoutée avec succès !",
    list_absences: "Lister les absences",
    list_absences_search: "Rechercher des absences",
    import_absences: "Importer les absences",
    import_absences_success: "Absences importées avec succès !",
    notification: "Notification d'attestation",
    absence_ask: "Demande d'autorisation d'absence exceptionnelle",
    display_apprentice_list: "Afficher la liste des",
    hide_apprentice_list: "Masquer la liste des apprentis",
    training_detail: "Voir le détail de la formation",
    apprentice_list: "Liste des",
    personnal_data: "Données personnelles",
    sheet_from: "Fiche de",
    event_moved_success: "Évènement déplacé avec succès !",
    absence_moved_success: "Absence déplacé avec succès !",
    justify: "Justifier",
    planning: "Évènement",
    not_justify: "Injustifiée",
    context_menu: {
      copy: "Copier",
      cut: "Couper",
      paste: "Coller",
      delete: "Supprimer",
      modify: "Modifier cet évènement",
      add: "Ajouter une évènement",
      add_absence: "Ajouter une absence",
    },
    actions: {
      planning: "Gestion du planning",
      absences: "Gestion des absences",
      options: "Options",
    },
  },
};
