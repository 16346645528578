export default {
  action_to_realize: "Actions à réaliser",
  sign_action: "Enregistrer et signer",
  complete: "Compléter",
  toComplete: "À compléter",
  due_date: "Échéance",
  toSign: "À signer",
  description: "Description",
  yearProgression: "Progression de l'année",
  progression: "Progression",
  your_progression: "Votre taux de remplissage",
  triad_progression: "Taux de remplissage trinôme",
  question: "Question",
  incoming: "En attente",
  signature: "Signature",
  questionnaire: "Questionnaire",
  questionnaires: "Questionnaires",
  yourAnswer: "Votre réponse : ",
  answerHere: "Répondez ici",
  commentHere: "Laissez un commentaire ici",
  sign: "Signer",
  draft: "Enregistrer en brouillon",
  sharedDraft: "Enregistrer et partager",
  youAnswered: "Vous avez répondu :",
  sendFile: "Envoyer un fichier",
  sendDocument: "Envoyer un document",
  takePicture: "Prendre une photo",
  fileSource: "Source du fichier",
  yesSign: "Oui, je signe",
  signMessage:
    "Êtes-vous sûr de vouloir signer le questionnaire ? Vous ne pourrez plus modifier vos réponses par la suite.",
  deleteMessage: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
  download_report: "Télécharger le rapport",
  signatory: "Signataire",
  signatories: "Signataires",
  expected_signature: "Signature prévu avant le",
  signed_on: "Signé le",
  commented_by: "Commenté par",
  success: "Sauvegardé avec succès !",
  draftSuccess: "Brouillon sauvegardé avec succès !",
  sharedSuccess: "Brouillon partagé avec succès !",
  signedSuccess: "Signé avec succès !",
  deletedQuestionnaire: "Ce questionnaire a été supprimé",
  addLine: "Ajouter une ligne",
  "Competence calculated by your answers to the questionnaires":
    "Compétence calculée par vos réponses aux questionnaires",
  open_from: "Ouverture du",
  open_to: "au",
  marks: "Notes",
  block_sign:
    "Signature du questionnaire possible uniquement après la validation des autres membres du trinôme.",
  no_mark: "Aucune note pour le moment",
  "Download dates": "Télécharger les dates",
  "Print all the questionnaires": "Imprimer l'ensemble des questionnaires",
  answered: "a répondu",
  to_be_completed_from: "À renseigner du",
  to: "au",
};
