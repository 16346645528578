<template>
  <div class="row justify-content-center">
    <div class="col-lg-9">
      <div class="widget">
        <div class="textStyle m-3">
          <router-link
            :to="{ name: 'adminNewsList' }"
            class="btn btnStyle btn-success d-flex align-items-center"
          >
            <FontAwesomeIcon icon="fa-ligth fa-chevron-left" />
            {{ $t("admin.news.list.go_back_newslist") }}
          </router-link>
        </div>
        <div v-if="newsItem && newsItem.title">
          <div class="widget-heading flex-column pt-0">
            <div class="textStyle">
              <h2 class="textStyle widget-title">
                {{ newsItem.title }}
              </h2>
              <span
                :class="
                  'fw-bold badge ' +
                  tagCorrespondance.find(
                    (element) => element.id === this.newsItem.tag,
                  ).color
                "
                >{{
                  newsItem.tag
                    ? tagCorrespondance.find(
                        (element) => element.id === newsItem.tag,
                      ).name
                    : $t("admin.news.list.title")
                }}</span
              >
              -
              {{ $t("admin.news.list.uploaded_date") }}
              {{ formatedDate(newsItem.dateToDisplay) }}
            </div>
          </div>
          <div class="widget-content news">
            <div class="news">
              <div class="news__card">
                <div class="news__content">
                  <div class="news__text_container">
                    <div
                      v-if="newsItem.subTitle"
                      class="news__subtitle"
                      v-html="newsItem.subTitle"
                    ></div>
                    <div class="news__text" v-html="newsItem.text"></div>
                  </div>
                  <div class="news__image d-sm-block d-none">
                    <img :alt="newsItem.title" :src="picture" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { pictureManager } from "@/services/utilities/picture-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "NewsView",
  components: { FontAwesomeIcon },
  data() {
    return {
      loading: true,
      picture: "",
    };
  },
  mounted() {
    if (Object.keys(this.news).length === 0) {
      this.$store.dispatch("fetchNews").then(() => {
        this.$store.commit("TOGGLE_NEWS", this.$route.params.id);
      });
    } else {
      this.$store.commit("TOGGLE_NEWS", this.$route.params.id);
    }
    this.setBreadcrumbTitle();
    this.picture = pictureManager.setPicture(this.newsItem.picture, "view");
  },
  updated() {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.setAttribute(
        "style",
        "width:100%;aspect-ratio:16/9;height:auto;max-width:600px;",
      );
    }
  },
  computed: {
    ...mapState({
      news: (state) => state.news.news,
      newsItem: (state) => state.news.newsItem,
      tagCorrespondance: (state) => state.news.tagCorrespondance,
    }),
  },
  methods: {
    setBreadcrumbTitle() {
      if (this.newsItem && this.newsItem.title) {
        // this.$route.meta.title = this.newsItem.title;
        window.localStorage.setItem(
          "studea-pageTitle",
          JSON.stringify({ name: this.newsItem.title }),
        );
      }
    },
    formatedDate(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>
<style scoped lang="scss">
.widget {
  .btn {
    width: fit-content;
  }

  .widget-heading {
    .widget-title {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #1d1d1d !important;
    }

    .redBadge {
      background-color: $red !important;
    }

    .blueBadge {
      background-color: $blue !important;
    }

    .greenBadge {
      background-color: $green !important;
    }

    .yellowBadge {
      background-color: $yellow !important;
    }

    a:hover {
      color: #21222a;
    }
  }
}

.news {
  &__card {
    border: none;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__image {
    margin-left: 20px;
    img {
      height: 450px;
      width: 100%;
      object-fit: cover;
      border-radius: 0.375rem;
      @include up-to-lg {
        height: 350px;
      }
    }
  }

  &__text_container {
    width: 80%;
    @include mobile {
      width: 100%;
    }
  }
  &__text {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 100%;
    overflow: unset;
    text-overflow: initial;
    -webkit-line-clamp: initial;
  }

  &__subtitle {
    width: 100%;
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 700;
  }
}
</style>
