import { accessManager } from "@/services/security/access-manager";

export const access = {
  mounted: function (el, binding) {
    check(el, binding);
  },
  updated: function (el, binding) {
    check(el, binding);
  },
};

function check(el, binding) {
  let code = null;
  let access;
  if (typeof binding.value === "object") {
    access = binding.value.access;
    code = binding.value.code;
  } else {
    access = binding.value;
  }
  el.hidden = !accessManager.isAvailableForUser(null, code, access);
}
