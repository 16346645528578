<template>
  <div v-for="category in skills" :key="category" class="widget">
    <div class="widget-heading grip flex-column flex-md-row">
      <h2 class="textStyle">{{ category.name }}</h2>
      <div class="mt-2 mt-md-0 d-flex justify-content-end">
        <router-link
          :to="{
            name: 'studeaManagerSkillAdd',
            params: { id: category.id, alias: 'skill' },
            query: {
              set: JSON.stringify([
                {
                  name: 'skillCategoryId',
                  value: category.id,
                },
              ]),
              currentSession: this.currentSession,
            },
          }"
          v-access:[$store.state.user.permissions]="accessManager().EDIT"
          class="list__button -skill"
          v-tooltip="{
            title: $tc('global.add.title', 2, {
              name: this.$tc('studea_manager.skill.title', 1).toLowerCase(),
            }),
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-plus" />
        </router-link>
        <router-link
          :to="{
            name: 'studeaManagerSkillCategoryEdit',
            params: { id: category.id, alias: 'skill-category' },
          }"
          v-access:[$store.state.user.permissions]="accessManager().EDIT"
          class="list__button -skill"
          v-tooltip="{
            title: $tc('global.edit.title', 2, {
              name: this.$tc(
                'studea_manager.skillCategory.title',
                1,
              ).toLowerCase(),
            }),
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-edit" />
        </router-link>
        <button
          v-tooltip="{
            title: $tc('global.copy.title', 2, {
              name: this.$tc(
                'studea_manager.skillCategory.title',
                1,
              ).toLowerCase(),
            }),
          }"
          v-access:[$store.state.user.permissions]="accessManager().EDIT"
          class="list__button -skill"
          @click="copyCategory(category.id)"
        >
          <FontAwesomeIcon icon="fa-light fa-copy" />
        </button>
        <button
          v-tooltip="{
            title: $tc('global.delete.title', 2, {
              name: this.$tc(
                'studea_manager.skillCategory.title',
                1,
              ).toLowerCase(),
            }),
          }"
          v-access:[$store.state.user.permissions]="accessManager().DELETE"
          class="list__button -skill"
          @click="deleteCategory(category.id)"
        >
          <FontAwesomeIcon icon="fa-light fa-trash" />
        </button>
      </div>
    </div>
    <div class="widget-content">
      <SkillListComponent
        :skills="category.skills"
        :category="category"
        @init="init"
      />
      <div class="mt-2" v-if="!category.skills.length">
        <div class="textStyle fst-italic">
          {{
            $tc("global.empty.title", 2, {
              name1: this.$tc("studea_manager.skill.title", 1).toLowerCase(),
              name2: this.$tc(
                "studea_manager.skillCategory.title",
                1,
              ).toLowerCase(),
            })
          }}
        </div>
      </div>
    </div>
    <div
      v-if="category.categories && Object.keys(category.categories).length"
      class="subCategories"
    >
      <SkillCategoryComponent :skills="category.categories" @init="init" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SkillListComponent from "@/components/skills/SkillListComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { accessManager } from "@/services/security/access-manager";

export default {
  name: "SkillCategoryComponent",
  components: { FontAwesomeIcon, SkillListComponent },
  props: {
    skills: {
      type: [],
      required: true,
      default: [],
    },
    currentSession: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      triad: (state) => state.triad.triad,
    }),
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    deleteCategory(id) {
      studeaManagerManager
        .remove(
          "skill-category",
          this.$route.params.trainingCourseId,
          id,
          2,
          true,
        )
        .then(() => {
          this.$emit("init");
        });
    },

    copyCategory(id) {
      studeaManagerManager
        .copy("skill-category", this.$route.params.trainingCourseId, id, 2)
        .then(() => {
          this.$emit("init");
        });
    },

    init() {
      this.$emit("init");
    },
  },
};
</script>

<style lang="scss" scoped>
.grip {
  cursor: grab;
}

.list {
  &__button {
    background: none;
    border: none;
    color: $white;
    font-size: 16px;
    position: relative;

    &.-skill {
      color: var(--primary-color);
    }
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }
}

.subCategories {
  padding: 10px;
  background: $m-color_1;
  border: 5px solid $white;
  border-radius: 6px;

  .widget {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
