<template>
  <FormBlockComponent
    :models="models"
    :title="$t('admin.modulet.trainingCourse.edit.title')"
    @formCancel="cancel"
    @submit="submit"
  />
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "ModulesTrainingCoursesView",
  components: {
    FormBlockComponent,
  },
  data() {
    return {
      models: [],
      loading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getForm();
    },

    getForm() {
      this.loading = true;
      adminManager
        .form("module", this.$route.params.id, {
          trainingCourseModuleTriadEdit: 1,
        })
        .then((form) => {
          this.models = form;
          this.loading = false;
        });
    },

    cancel() {
      this.$router.push({ name: "adminModuleList" });
    },

    submit() {
      let datas = formManager.processForm(this.models, false);
      datas.trainingCourseModuleTriads = datas.trainingCourseModuleTriads.map(
        (x) => {
          return { id: parseInt(x) };
        },
      );
      adminManager.putModule(datas, this.$route.params.id).then((res) => {
        if (res && !res.hasError) {
          notificationManager.showNotification(
            "success",
            this.$t("admin.modulet.trainingCourse.edit.success"),
          );
          this.cancel();
        } else if (res.responses && res.responses[0].validationErrors) {
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
          formValidator.processResponses(
            res.responses[0].validationErrors,
            this.models,
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
