import { apiConnection } from "@/services/api-connection";
import store from "@/store";
import { pictureManager } from "@/services/utilities/picture-manager";
import router from "@/router";

const URL = "/app/training/list";

class TrainingCourseManager {
  MODULE_STUDEA_SKILL = "MODULE_STUDEA_SKILL";
  MODULE_STUDEA_QUESTIONNAIRE = "MODULE_STUDEA_QUESTIONNAIRE";
  MODULE_STUDEA_ABSENCE = "MODULE_STUDEA_ABSENCE";
  MODULE_STUDEA_DOCUMENT = "MODULE_STUDEA_DOCUMENT";
  MODULE_STUDEA_MESSAGE = "MODULE_STUDEA_MESSAGE";
  MODULE_STUDEA_COURSE = "MODULE_STUDEA_COURSE";
  MODULE_STUDEA_NOTE = "MODULE_STUDEA_NOTE";
  MODULE_STUDEA_NOTEPAD = "MODULE_STUDEA_NOTEPAD";
  MODULE_STUDEA_REGISTRATION = "MODULE_STUDEA_REGISTRATION";
  MODULE_STUDEA_WEBLINKS = "MODULE_STUDEA_WEBLINKS";

  loadTrainingCourseList = (archive = 1, search = "", page = 0) => {
    return apiConnection
      .get(
        URL,
        {
          archive: archive,
          length: 10,
          page: page ? page : 0,
          search: search,
        },
        true,
      )
      .then((response) => response);
  };

  getPageDatas(code) {
    const triad = store.state.triad.triad;
    let menu = null;
    if (triad && triad.trainingCourse && triad.trainingCourse.menu) {
      menu = Object.values(triad.trainingCourse.menu).find(
        (menu) => menu.code === code,
      );
    }
    return menu && menu.title ? menu.title : "";
  }

  getPageHelp(code) {
    const triad = store.state.triad.triad;
    let menu = null;
    if (triad && triad.trainingCourse && triad.trainingCourse.menu) {
      menu = Object.values(triad.trainingCourse.menu).find(
        (menu) => menu.code === code,
      );
    }
    return menu && menu.helpDocument ? menu.helpDocument : null;
  }

  setPageTitle(code) {
    const title = this.getPageDatas(code);
    router.currentRoute.value.meta.title = title;
    return title;
  }

  setNewCfaLogo(trainingCourse) {
    if (trainingCourse && trainingCourse.training) {
      store.commit(
        "SET_LOGO_CFA_PROMOTION",
        pictureManager.setPicture(
          trainingCourse.training.institution.logoInstitution,
        ),
      );
    }
  }
}

export const trainingCourseManager = new TrainingCourseManager();
