<template>
  <div class="container-fluid">
    <SideMenuComponent :admin="true" :menu="menu" />
    <div class="content-wrapper">
      <ErrorView v-if="errorStatus" />
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script>
import SideMenuComponent from "@/components/SideMenuComponent.vue";
import { mapState } from "vuex";
import ErrorView from "@/views/Error/ErrorView.vue";

export default {
  name: "LayoutView",
  data() {
    return {};
  },
  beforeRouteUpdate() {
    this.getMenu();
  },
  created() {
    this.getMenu(true);
  },
  mounted() {
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  updated() {
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  computed: {
    ...mapState({
      menu: (state) => state.admin.menu,
      errorStatus: (state) => state.error.status,
      needUpdate: (state) => state.platform.needUpdate,
    }),
  },
  methods: {
    getMenu(init = false) {
      this.$store.dispatch("fetchMenu", init);
    },

    getConfig() {
      this.$store.dispatch("fetchConfig");
      this.$store.commit("SET_NEED_UPDATE", false);
    },
  },
  components: { ErrorView, SideMenuComponent },
};
</script>

<style lang="scss" scoped></style>
