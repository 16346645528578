const state = {
  data: {
    categories: [
      {
        id: 123,
        title: "Compétences Transversales (CT)",
        totalColumn: 5,
        skills: [
          {
            skillId: 12,
            skillName: "Compétence Transversale 1",
            skillBlocks: [
              {
                skillBlockId: 512,
                skillBlockName:
                  "Avoir le sens du résultat et la considération du client",
              },
              {
                skillBlockId: 513,
                skillBlockName:
                  "Être capable de décrypter un phénomène complexe et d’appréhender l’incertitude",
              },
              {
                skillBlockId: 514,
                skillBlockName:
                  "Être capable de décrypter un phénomène complexe et d’appréhender l’incertitude",
              },
              {
                skillBlockId: 514,
                skillBlockName:
                  "Être capable de décrypter un phénomène complexe et d’appréhender l’incertitude",
              },
              {
                skillBlockId: 514,
                skillBlockName:
                  "Être capable de décrypter un phénomène complexe et d’appréhender l’incertitude",
              },
            ],
          },
          {
            skillId: 12,
            skillName: "Compétence Transversale 2",
            skillBlocks: [
              {
                skillBlockId: 512,
                skillBlockName:
                  "Avoir le sens du résultat et la considération du client",
              },
              {
                skillBlockId: 513,
                skillBlockName:
                  "Être capable de décrypter un phénomène complexe et d’appréhender l’incertitude",
              },
            ],
          },
        ],
      },
      {
        id: 124,
        title: "Compétences Spécifiques (CT)",
        totalColumn: 1,
        skills: [
          {
            skillId: 1,
            skillName:
              "Être capable de décrypter un phénomène complexe et d’appréhender l’incertitude",
            skillBlocks: [
              {
                skillBlockId: 512,
                skillBlockName:
                  "Avoir le sens du résultat et la considération du client",
              },
              {
                skillBlockId: 513,
                skillBlockName:
                  "Être capable de décrypter un phénomène complexe et d’appréhender l’incertitude",
              },
            ],
          },
        ],
      },
      {
        id: 125,
        title: "Compétences Spécifiques (CT)",
        totalColumn: 1,
        skills: [
          {
            skillId: 1,
            skillName:
              "Être capable de décrypter un phénomène complexe et d’appréhender l’incertitude",
          },
        ],
      },
      {
        id: 126,
        title: "Compétences Spécifiques (CT)",
        totalColumn: 1,
        skills: [],
      },
    ],
    crews: [
      {
        id: 23,
        title: "groupe 1",
        triads: [
          {
            id: 55,
            apprentice: {
              id: 38,
              fullName: "John Doe",
            },
            tutor: {
              id: 11,
              fullName: "Pierre Dupont",
            },
            apprenticeMaster: {
              id: 12,
              fullName: "Henri Durand",
            },
            leaManager: {
              id: 66,
              fullName: "Paul Hello",
            },
            contract: {
              startDate: "2023-09-01",
              endDate: "2024-06-25",
            },
          },
          {
            id: 62,
            apprentice: {
              id: 39,
              fullName: "Damien Abc",
            },
            tutor: {
              id: 14,
              fullName: "Albert Azerty",
            },
            apprenticeMaster: {
              id: 15,
              fullName: "Michel Ytreza",
            },
            leaManager: {
              id: 66,
              fullName: "Paul Hello",
            },
            contract: {
              startDate: "2023-09-01",
              endDate: "2024-06-25",
            },
          },
        ],
      },
      {
        id: 6,
        title: "groupe 2",
        triads: [
          {
            id: 56,
            apprentice: {
              id: 16,
              fullName: "Henri 123",
            },
            tutor: {
              id: 17,
              fullName: "James Bond",
            },
            apprenticeMaster: {
              id: 18,
              fullName: "Marion Dfgh",
            },
            leaManager: {
              id: 66,
              fullName: "Paul Hello",
            },
            contract: {
              startDate: "2023-09-01",
              endDate: "2024-06-25",
            },
          },
        ],
      },
      {
        id: 8,
        title: "groupe 3",
        triads: [
          {
            id: 57,
            apprentice: {
              userId: 16,
              fullName: "Henri 123",
            },
            tutor: {
              userId: 17,
              fullName: "James Bond",
            },
            apprenticeMaster: {
              userId: 18,
              fullName: "Marion Dfgh",
            },
            leaManager: {
              userId: 66,
              fullName: "Paul Hello",
            },
            contract: {
              startDate: "2023-09-01",
              endDate: "2024-06-25",
            },
          },
        ],
      },
      {
        id: 10,
        title: "groupe 4",
        triads: [
          {
            id: 58,
            apprentice: {
              userId: 16,
              fullName: "Henri 123",
              lastName: "123",
            },
            tutor: {
              userId: 17,
              fullName: "James Bond",
              lastName: "Bond",
            },
            apprenticeMaster: {
              userId: 18,
              fullName: "Marion Dfgh",
              lastName: "Dfgh",
            },
            leaManager: {
              userId: 66,
              fullName: "Paul Hello",
              lastName: "Hello",
            },
            contract: {
              startDate: "2023-09-01",
              endDate: "2024-06-25",
            },
          },
        ],
      },
    ],
    replies: [
      {
        replyId: 900,
        triadId: 55,
        skillId: 12,
        apprenticeReply: 1,
        tutorReply: 2,
      },
      {
        replyId: 900,
        triadId: 62,
        skillId: 12,
        apprenticeReply: 3,
        tutorReply: undefined,
      },
    ],
  },
};

const mutations = {
  SET_DASHBOARD_SKILL_DATA(state, data) {
    state.data = data;
  },
};

const getters = {};

const actions = {
  getDashboardSkillData({ commit }) {
    commit.SET_DASHBOARD_SKILL_DATA(this.data);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
