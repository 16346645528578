export default {
  header: {
    dark: "Thème sombre",
    light: "Thème clair",
    system: "Thème du navigateur",
  },
  timeline: "Timeline",
  indicator: "Indicateurs",
  shared_docs: "Documents partagés",
  registration: "Inscriptions",
  welcome_to_studea: "Bienvenue sur votre portail Studea !",
  your_actually_are: "Vous êtes actuellement",
  welcome_message: ({ named }) =>
    `Bienvenue <strong>${named("name")}</strong> sur votre portail Studea !`,
};
