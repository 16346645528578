<template>
  <div id="documentTab" class="documentTab">
    <div v-if="Object.keys(documents).length" class="documentTab__item -login">
      <div
        class="documentTab__button btnStyle"
        v-tooltip="{ title: $t('globalDocuments') }"
        @click="openContent($event)"
      >
        <FontAwesomeIcon
          class="documentTab__icon"
          icon="fa-light fa-download"
        />
      </div>
      <div class="documentTab__content -login">
        <h2 class="mb-3">{{ $t("globalDocuments") }}</h2>
        <SimpleListComponent :actions="actions" :datas="documents" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SimpleListComponent from "@/components/table/SimpleListComponent.vue";

export default {
  name: "LoginDocumentComponent",
  components: { SimpleListComponent },
  data() {
    return {
      content: "",
      label: "",
      actions: [
        {
          translation: "download_file",
          icon: "download",
          type: "primary",
          actionType: "externalLink",
          downloadKey: "documentFile",
          cfaStyle: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      documents: (state) => state.platform.documents,
    }),
  },
  methods: {
    openContent(event) {
      const target = event.currentTarget.parentNode;
      if (!target.classList.contains("expanded")) {
        const backdrop = document.getElementById("backdrop");
        const documentTab = document.getElementById("documentTab");
        const $this = this;

        this.resetContent();
        backdrop.classList.add("show");
        documentTab.classList.add("open");
        backdrop.addEventListener("click", function () {
          $this.resetContent();
          backdrop.classList.remove("show");
          setTimeout(function () {
            documentTab.classList.remove("open");
          }, 600);
        });

        setTimeout(function () {
          target.classList.add("expanded");
        }, 300);
      }
    },

    resetContent() {
      const items = [].slice.call(
        document.querySelectorAll(".documentTab__item"),
      );
      const backdrop = document.getElementById("backdrop");
      const documentTab = document.getElementById("documentTab");
      if (documentTab.classList.contains("open")) {
        setTimeout(function () {
          documentTab.classList.remove("open");
        }, 600);
      }
      backdrop.classList.remove("show");
      items.map((item) => {
        item.classList.remove("expanded");
        item.classList.add("closing");
        setTimeout(function () {
          item.classList.remove("closing");
        }, 600);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.documentTab {
  position: absolute;
  display: inline-block;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1051;
  min-height: 60px;
  max-width: 600px;
  min-width: 0;
  transition: all 300ms ease-in-out;

  &.open {
    height: auto;
  }

  @include up-to-md {
    position: fixed;
  }

  &__item {
    max-height: 60px;
    max-width: 60px;
    width: 100%;
    position: relative;
    right: 0;
    transform: translateY(100%);
    transition-property: max-width, transform;
    transition-duration: 300ms, 300ms;
    transition-timing-function: ease, ease-in-out;

    @include up-to-lg {
      max-height: 600px;
    }

    &.closing {
      transition-delay: 300ms, 0ms;
    }

    &.expanded {
      transform: translateY(0);
      max-width: 600px;
      max-height: 600px;
      transition-delay: 0ms, 300ms;

      .documentTab__button {
        box-shadow: none;
      }
    }
  }

  &__label {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: $blue;

    @include dark-theme {
      color: $white;
    }
  }

  &__button {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-radius: $radius $radius 0 0;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

    &:hover {
      background: $white !important;

      .documentTab__icon {
        color: var(--secondary-color);
      }
    }

    @include dark-theme {
      background: $m-color_10;
      border: none;
    }
  }

  &__icon {
    font-size: 25px;
    color: $white;

    @include dark-theme {
      color: $white;
    }
  }

  &__content {
    background: $white;
    border-radius: $radius $radius 0 0;
    border: 1px solid $m-color_3;
    position: relative;
    z-index: 1;
    overflow: scroll;
    padding: 20px;
    min-height: 350px;
    max-height: 350px;
    width: 600px;

    @include up-to-md {
      width: 100%;
    }

    .widget {
      margin-bottom: 0;
      border: none;
    }

    @include dark-theme {
      background: $m-color-10;
      color: $white;
      border: none;
    }
  }
}
</style>
