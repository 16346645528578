<template>
  <div class="container-fluid">
    <SideMenuComponent :menu="menu" />
    <div class="content-wrapper">
      <ErrorView v-if="errorStatus" />
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script>
import SideMenuComponent from "@/components/SideMenuComponent.vue";
import { mapState } from "vuex";
import ErrorView from "@/views/Error/ErrorView.vue";
import { colorManager } from "@/services/platform/color-manager";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";

export default {
  name: "LayoutView",
  components: {
    ErrorView,
    SideMenuComponent,
  },
  computed: {
    ...mapState({
      trainingCourseList: (state) => state.trainingCourse.trainingCourseList,
      trainingCourse: (state) => state.trainingCourse.trainingCourse,
      menu: (state) => state.general.menu,
      errorStatus: (state) => state.error.status,
      design: (state) => state.platform.design,
      needUpdate: (state) => state.platform.needUpdate,
    }),
  },
  beforeRouteUpdate() {
    this.getMenu(this.$route.params.trainingCourseId, false);
  },
  created() {
    this.getMenu(this.$route.params.trainingCourseId, true);
  },
  mounted() {
    if (this.needUpdate) {
      this.getConfig();
    }
    window.localStorage.setItem(
      "studea-oldTrainingCourse",
      parseInt(this.$router.currentRoute.value.params.trainingCourseId),
    );
    if (
      Object.keys(this.trainingCourseList).length &&
      this.trainingCourse.id !==
        parseInt(this.$router.currentRoute.value.params.trainingCourseId)
    ) {
      this.$store.dispatch("fetchTrainingCourseTriads", {
        id: parseInt(this.$router.currentRoute.value.params.trainingCourseId),
        toggle: true,
        archive: 1,
      });
    }

    if (
      !this.trainingCourseList ||
      !Object.keys(this.trainingCourseList).length
    ) {
      this.$store.dispatch("fetchTrainingCourseList", {}).then(() => {
        this.$store.commit("SET_APP_LOADING", false);
        if (this.trainingCourse) {
          this.setCfaDesign();
        }
      });
    }

    if (this.trainingCourse && Object.keys(this.trainingCourse).length) {
      this.setCfaDesign();
    }
  },
  methods: {
    getMenu(id, refresh) {
      this.$store.dispatch("fetchStudeaManagerMenu", {
        trainingCourseId: id,
        refresh: refresh,
      });
    },

    setCfaDesign() {
      colorManager.setPlatformStyle(this.trainingCourse);
      trainingCourseManager.setNewCfaLogo(this.trainingCourse, this.$store);
    },
    getConfig() {
      this.$store.dispatch("fetchConfig");
      this.$store.commit("SET_NEED_UPDATE", false);
    },
  },
  updated() {
    if (this.needUpdate) {
      this.getConfig();
    }
  },
};
</script>

<style lang="scss" scoped></style>
