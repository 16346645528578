<template>
  <div :class="'mb-lg-0 mb-3' + (isExport ? ' isExport' : '')">
    <div
      v-for="signator in getSignatoryInfos.signed"
      :key="signator"
      :class="'signatory widget ' + signator.type"
    >
      <div class="signatory__identity">
        {{ signator.identity }} ({{ apprenticeProfileLabel.title }})
      </div>
      <div v-if="isExport" class="signatory__row">
        <div class="signatory__text">{{ $t("expected_signature") }}</div>
        <div class="signatory__value">{{ signator.endDate }}</div>
      </div>
      <div class="signatory__row">
        <div class="signatory__text">{{ $t("signed_on") }}</div>
        <div class="signatory__value">
          {{ signator.signature ? signator.signature : "" }}
        </div>
      </div>
      <div v-if="signator.mark" class="signatory__row">
        <div class="signatory__text">{{ $t("note") }}</div>
        <div class="signatory__value">
          {{ signator.mark }}
        </div>
      </div>
    </div>

    <div
      v-if="
        Object.keys(getSignatoryInfos.signed).length &&
        Object.keys(getSignatoryInfos.unsigned).length
      "
      class="line"
    ></div>

    <div
      v-for="signator in getSignatoryInfos.unsigned"
      :key="signator"
      class="signatory widget incoming"
    >
      <div class="signatory__identity">
        {{ signator.identity }} ({{ $t(signator.type) }})
      </div>
      <div class="signatory__row">
        <div class="signatory__text">{{ $t("expected_signature") }}</div>
        <div class="signatory__value">{{ signator.endDate }}</div>
      </div>
      <div v-if="signator.mark" class="signatory__row">
        <div class="signatory__text">{{ $t("note") }}</div>
        <div class="signatory__value">{{ signator.mark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "QuestionnaireSignatoryBlockComponent",
  components: {},
  props: {
    isExport: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      apprenticeProfileLabel: (state) => state.platform.apprenticeProfileLabel,
    }),
    getSignatoryInfos() {
      let signatories = {
        signed: [],
        unsigned: [],
      };
      const types = [
        "apprentice",
        "tutor",
        "apprenticeMaster",
        "studeaManager",
      ];

      types.forEach((type) => {
        if (this.questionnaire.lastReply && this.questionnaire.profileStatus) {
          const reply = this.questionnaire.lastReply[type];
          const profileStatus = this.questionnaire.profileStatus[type];
          const triadMember = this.triad[type] ? this.triad[type] : null;
          let identity = triadMember
            ? triadMember.firstName + " " + triadMember.lastName
            : "";
          if (type === "studeaManager") {
            identity = this.$tc("admin.studeaManager.title", 1);
          }
          if (profileStatus && profileStatus.hasToSign) {
            const object = {
              identity: identity,
              endDate: this.questionnaire.completenessDate
                ? dateFormatter.format(
                    this.questionnaire.completenessDate,
                    "short",
                  )
                : "",
              signature:
                reply && reply.signedAt
                  ? dateFormatter.format(reply.signedAt, "short")
                  : "",
              mark:
                reply &&
                reply.totalMark &&
                reply.totalMark.mark &&
                reply.totalMark.maximalMark
                  ? reply.totalMark.mark + "/" + reply.totalMark.maximalMark
                  : "",
              type: type,
            };
            if (!reply || !reply.signedAt) {
              signatories.unsigned.push(object);
            } else {
              signatories.signed.push(object);
            }
          }
        }
      });

      return signatories;
    },
    ...mapState({
      questionnaire: (state) => state.questionnaire.questionnaire,
      triad: (state) => state.triad.triad,
    }),
  },
};
</script>

<style lang="scss" scoped>
.widget {
  margin: 0;
}

.isExport {
  .signatory {
    &__identity {
      font-size: 12px;
    }

    &__row {
      font-size: 10px;
    }
  }
}

.signatory {
  padding: 15px;
  border-radius: 9px;

  &__identity {
    font-size: 15px;
  }

  &__identity,
  &__value {
    font-weight: 700;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    @include dark-theme {
      color: $white;
    }
  }

  &.incoming {
    background: $lighter-grey;
    border: 1px solid $dark-grey;
  }

  &.apprentice {
    background: $light-green;
    border: 1px solid $green;

    .signatory__identity {
      color: $green;
    }
  }

  &.tutor {
    background: $light-purple;
    border: 1px solid $purple;

    .signatory__identity {
      color: $purple;
    }
  }

  &.apprenticeMaster {
    background: $light-red;
    border: 1px solid $red;

    .signatory__identity {
      color: $red;
    }
  }

  &.studeaManager {
    background: $light-orange;
    border: 1px solid $orange;

    .signatory__identity {
      color: $orange;
    }
  }
}

.signatory + .signatory {
  margin-top: 10px;
}
</style>
