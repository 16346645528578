export const tooltip = {
  mounted: function (el, binding) {
    el.setAttribute("aria-label", binding.value.title);
    el.dataset.tooltipId = Math.random().toString(16).slice(2);
    el.addEventListener("mouseenter", () => {
      create(el, binding);
    });
    el.addEventListener("focusin", () => {
      create(el, binding);
    });
    el.addEventListener("focusout", function () {
      remove(el);
    });
    el.addEventListener("mouseleave", function () {
      remove(el);
    });
    el.addEventListener("click", function () {
      remove(el);
    });
  },
};

function create(el, binding) {
  const tooltipId = el.dataset.tooltipId;
  let tooltipContainer = document.getElementById("tooltip-container");
  let tooltip = document.querySelector("#tooltip-" + tooltipId);
  let position = binding.value.position ? binding.value.position : "top";

  if (!tooltip) {
    tooltip = document.createElement("span");
    observer.observe(tooltip);
  }

  tooltip.title = binding.value.title;
  tooltip.id = "tooltip-" + tooltipId;
  tooltip.className = "tooltip-i " + position;
  tooltip.textContent = binding.value.title;

  setTooltipContainerPosition(el, tooltipContainer, position);
  if (tooltip.title) {
    tooltipContainer.appendChild(tooltip);
  }
}

function remove(el) {
  const tooltipContainer = document.getElementById("tooltip-container");
  const tooltip = tooltipContainer.querySelector(
    "#tooltip-" + el.dataset.tooltipId,
  );
  if (tooltip) {
    tooltip.classList.remove("fadeIn");
    tooltip.classList.add("fadeOut");
    tooltipContainer.removeChild(tooltip);
  }
  clean(tooltipContainer);
}

const observer = new IntersectionObserver(observerCallback, {
  root: document.querySelector("#app"),
  rootMargin: "0px",
  threshold: 1.0,
});

function clean(tooltipContainer) {
  const tooltips = tooltipContainer.querySelectorAll(".tooltip-i");
  for (let i = 0; i < tooltips.length; i++) {
    const trigger = document.querySelector(
      "[data-tooltip-id='" + tooltips[i].id.split("-")[1] + "']",
    );
    if (!trigger) {
      tooltipContainer.removeChild(tooltips[i]);
    }
  }
}

function observerCallback(entries) {
  entries.forEach((entry) => {
    if (
      !entry.isIntersecting &&
      entry.intersectionRect.width &&
      entry.intersectionRect.x
    ) {
      const sideIntersecting = getSideIntersectingWith(entry);
      let position = null;
      if (
        entry.target.classList.contains("top") ||
        entry.target.classList.contains("bottom")
      ) {
        entry.target.classList.remove("top");
        entry.target.classList.remove("bottom");
        position = sideIntersecting === "right" ? "left" : "right";
      } else if (
        entry.target.classList.contains("left") ||
        entry.target.classList.contains("right")
      ) {
        entry.target.classList.remove("left");
        entry.target.classList.remove("right");
        position = sideIntersecting === "top" ? "bottom" : "top";
      }
      entry.target.classList.add(position);

      let tooltipContainer = document.getElementById("tooltip-container");
      const tooltipId = entry.target.id.split("-")[1];
      const el = document.querySelector(
        "[data-tooltip-id='" + tooltipId + "']",
      );
      setTooltipContainerPosition(el, tooltipContainer, position);
    }
    entry.target.classList.add("fadeIn");
  });
}

function getSideIntersectingWith(entry) {
  if (entry.boundingClientRect.right !== entry.intersectionRect.right) {
    return "right";
  } else if (entry.boundingClientRect.left !== entry.intersectionRect.left) {
    return "left";
  } else if (entry.boundingClientRect.top !== entry.intersectionRect.top) {
    return "top";
  } else if (
    entry.boundingClientRect.bottom !== entry.intersectionRect.bottom
  ) {
    return "bottom";
  }
}

function setTooltipContainerPosition(el, tooltipContainer, position) {
  if (el) {
    const elPosition = el.getBoundingClientRect();
    const bodyPosition = document.body.getBoundingClientRect();
    let top = elPosition.top - bodyPosition.top;

    if (position === "left" || position === "right") {
      top = top + elPosition.height / 2;
    } else if (position === "bottom") {
      top = top + elPosition.height;
    }

    tooltipContainer.style.cssText = "top: " + top + "px;";
    tooltipContainer.style.cssText += "left: " + elPosition.left + "px;";
    tooltipContainer.style.cssText += "width: " + elPosition.width + "px;";
  }
}
