import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import Vuex from "vuex";
import { createHead } from "@vueuse/head";
import * as Sentry from "@sentry/vue";

// bootstrap
import * as bootstrap from "bootstrap";
// modals
import "@/assets/sass/components/custom-modal.scss";

//Sweetalert
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue3-apexcharts";

// perfect scrollbar
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

//VueTelInput
import "vue-tel-input/dist/vue-tel-input.css";
import { i18n } from "@/i18n";

// set default settings
import appSetting from "./app-setting";
//Font Awesome and icon library
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";

//tooltip
import { tooltip } from "@/directives/tooltip";
import { access } from "@/directives/access";

window.bootstrap = bootstrap;

//vue-meta
const head = createHead();

window.$appSetting = appSetting;
window.$appSetting.init();

library.add(fas, fal);
dom.watch();

const app = createApp(App)
  .use(Vuex)
  .use(router)
  .use(store)
  .use(head)
  .use(i18n)
  .use(VueSweetalert2)
  .use(VueApexCharts)
  .use(PerfectScrollbar)
  .component("FontAwesomeIcon", FontAwesomeIcon);

app.directive("tooltip", tooltip).directive("access", access);

if (process.env.VUE_APP_SENTRY_ENV === "production") {
  Sentry.init({
    app,
    environment: process.env.VUE_APP_SENTRY_ENV,
    dsn: "https://e5e3525696bb4e550c90671372c6d809@sentry.effetb.com/4",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

router.isReady().then(() => {
  app.mount("#app");
});
