class TabsManager {
  displayTab(tab) {
    if (tab) {
      var el = window.bootstrap.Tab.getOrCreateInstance(
        document.getElementById("tab-" + tab.code + "-tab"),
      ); // Returns a Bootstrap tab instance
      el.show();
    }
  }
}

export const tabsManager = new TabsManager();
