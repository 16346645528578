<template>
  <div class="checkbox-primary custom-control custom-checkbox">
    <input
      :id="'item-' + id"
      v-model="listCheck"
      :value="id"
      class="custom-control-input"
      name="listCheck"
      type="checkbox"
      @input="checked($event)"
    />
    <label :for="'item-' + id" class="custom-control-label"></label>
  </div>
</template>

<script>
export default {
  name: "ListCheckBoxInputComponent",
  props: {
    id: {
      default: 0,
      type: Number,
      required: true,
    },
    checkedList: {
      default: [],
      type: [],
      required: true,
    },
  },
  data() {
    return {
      listCheck: this.checkedList,
    };
  },
  methods: {
    checked(e) {
      this.$emit("checked", { id: this.id, checked: e.target.checked });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-control-label {
  position: initial;
  margin-right: 10px;

  &:before,
  &:after {
    left: 0;
  }
}
</style>
