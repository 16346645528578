export default {
  document: "Document",
  online_since: "En ligne depuis le",
  file: "Fichier",
  note: "Note",
  document_remove_confirm: "Êtes-vous sûr de vouloir supprimer ce document ?",
  note_remove_confirm: "Êtes-vous sûr de vouloir supprimer cette note ?",
  document_remove_success: "Votre document a été supprimé avec succès !",
  note_remove_success: "Votre note a été supprimée avec succès !",
  document_remove_error: "Oups, il y'a eu une erreur lors de la suppression",
  document_success: "Votre document a été envoyé avec succès !",
  note_success: "Votre note a été envoyée avec succès !",
  new_doc: "Ajouter un document",
  new_note: "Ajouter une note",
  new_doc_title: "Nouveau document",
  new_note_title: "Nouvelle note",
  file_integrate_learning_book:
    "Intégrer le fichier (PDF) au contenu du livret d'apprentissage",
  email_notif: "Notification par email",
  notes_summary: "Notes et synthèse",
  by: "par",
  on: "Le",
  pedagogicDocuments: "Documents pédagogiques",
  generalDocuments: "Documents généraux",
  "Documents to download": "Documents à télécharger",
  Watch: "Voir",
  Download: "Télécharger",
};
