<template>
  <label
    v-if="itemModel.vars.label"
    :class="
      'form-label' +
      (itemModel.vars.label_attr && itemModel.vars.label_attr.class
        ? ' ' + itemModel.vars.label_attr.class
        : '') +
      (itemModel.vars.required ? ' required' : '')
    "
  >
    {{ index ? +"." : "" }} {{ itemModel.vars.label
    }}{{ itemModel.vars.required ? "*" : "" }}
  </label>
  <SortTableComponent
    :checkable="true"
    :columns="itemModel.vars.columns"
    :datas="itemModel.vars.datas"
    @updated="updated"
  />
  <div v-if="itemModel.vars.error" class="invalid-feedback d-block">
    {{ itemModel.vars.error }}
  </div>
</template>

<script>
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "ListTypeComponent",
  props: {
    model: {
      default: [],
      type: [],
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      itemModel: this.model,
    };
  },
  methods: {
    updated(content) {
      this.itemModel.vars.value = content;
      formValidator.validModel(this.itemModel);
      this.$emit("updated", this.itemModel);
      this.$emit("draft", this.itemModel);
    },
  },
  components: {
    SortTableComponent,
  },
};
</script>

<style scoped></style>
