<template>
  <span class="me-3">Entre</span>
  <span v-for="recipient in recipients" :key="recipient.id" class="me-3">
    <AvatarComponent
      :user="recipient.recipient"
      :profile="getProfileKey(recipient.profile)"
    />
    <span class="name">{{ recipient.recipient.fullName }}</span>
  </span>
  <span class="me-3">
    <AvatarComponent :user="message.sender" :profile="senderProfile" />
    <span class="name">{{ message.sender.fullName }}</span>
  </span>
</template>

<script>
import { mapState } from "vuex";
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";
import { userManager } from "@/services/security/user-manager";

export default {
  name: "ChatRecipientsListComponent",
  components: { AvatarComponent },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      recipients: [],
      senderProfile: "",
    };
  },
  computed: {
    ...mapState({
      triad: (state) => state.triad.triad,
    }),
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.checkRecipients();
        this.checkSenderProfile();
      }
    },
  },
  mounted() {
    this.checkRecipients();
    this.checkSenderProfile();
  },
  methods: {
    getProfileKey(profile) {
      return userManager.getProfileKey(profile);
    },

    checkRecipients() {
      this.recipients = [];
      this.message.messageRecipients.forEach((recipient) => {
        if (recipient.recipient.id !== this.message.sender.id) {
          this.recipients.push(recipient);
        }
      });
    },

    checkSenderProfile() {
      this.senderProfile = "";
      if (
        this.triad.apprentice &&
        this.triad.apprentice.id === this.message.sender.id
      ) {
        this.senderProfile = "apprentice";
      } else if (
        this.triad.tutor &&
        this.triad.tutor.id === this.message.sender.id
      ) {
        this.senderProfile = "tutor";
      } else if (
        this.triad.apprenticeMaster &&
        this.triad.apprenticeMaster.id === this.message.sender.id
      ) {
        this.senderProfile = "apprenticeMaster";
      } else {
        this.senderProfile = "studeaManager";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
