<template>
  <template v-for="choice in choices" :key="choice.value">
    <div
      v-if="!filtered || (choice.checked && filtered)"
      class="checkbox-primary custom-control custom-checkbox"
    >
      <input
        :id="'checkbox-' + itemModel.vars.name + '-' + choice.value"
        v-model="choice.checked"
        :class="
          (itemModel.vars.error
            ? 'custom-control-input is-invalid'
            : 'custom-control-input') +
          (itemModel.vars.attr && itemModel.vars.attr.class
            ? ' ' + itemModel.vars.attr.class
            : '')
        "
        :disabled="itemModel.vars.disabled || choice.disabled"
        :readonly="
          (itemModel.vars.attr && itemModel.vars.attr.readonly) || readonly
        "
        :value="choice.value"
        type="checkbox"
        @change="setChecked"
        @focus="focus"
      />
      <label
        :for="'checkbox-' + itemModel.vars.name + '-' + choice.value"
        :class="'custom-control-label' + (choice.badge ? ' badged' : '')"
      >
        <span :class="choice.label ? 'ms-2' : ''">{{ choice.label }}</span>
        <span v-if="choice.badge" class="badge bg-success mb-1">{{
          choice.badge
        }}</span>
      </label>
    </div>
  </template>
</template>

<script>
export default {
  name: "CheckboxInputComponent",
  data() {
    return {
      itemModel: this.model,
    };
  },
  props: {
    model: {
      type: [],
      required: true,
      default: [],
    },
    choices: {
      type: [],
      required: true,
      default: [],
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    filtered: {
      tye: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    setChecked() {
      this.$emit("setChecked");
    },

    focus() {
      this.$emit("focus");
    },
  },
};
</script>

<style scoped lang="scss">
.badged {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.is-invalid + label {
  &:before {
    border-color: $red;
  }
}
</style>
