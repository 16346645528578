import { documentManager } from "@/services/document/document-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import store from "@/store";
import { triadManager } from "@/services/triad/triad-manager";

const state = {};

const mutations = {};

const actions = {
  sendDocument(context, object) {
    return documentManager.postNewDocument(object).then((success) => {
      if (!success.error) {
        notificationManager.showNotification(
          "success",
          i18n.global.t(
            object.type === 1 ? "document_success" : "note_success",
          ),
        );
      } else {
        notificationManager.showNotification(
          "success",
          i18n.global.t("message_error"),
        );
      }
      return success;
    });
  },
  removeDocument(context, payload) {
    return documentManager
      .removeDocument(payload.id, store.state.triad.triad.id)
      .then((success) => {
        if (!success.error) {
          notificationManager.showNotification(
            "success",
            i18n.global.t(
              payload.type === 1
                ? "document_remove_success"
                : "note_remove_success",
            ),
          );
          const oldTriad = window.localStorage.getItem("studea-oldTriad");
          triadManager
            .loadOne(oldTriad ? oldTriad : store.state.triad.triad.id)
            .then((triad) => {
              store.commit("SET_TRIAD", triad);
              setTimeout(function () {
                store.commit("SET_APP_LOADING", false);
              }, 300);
            });
        } else {
          notificationManager.showNotification(
            "danger",
            i18n.global.t("document_remove_error"),
          );
        }
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
