export default {
  timetable: "Timetable",
  yourCourses: "Your courses",
  "There are no events on this day.": "There are no events on this day.",
  Today: "Today",
  Month: "Month",
  Week: "Week",
  Day: "Day",
  "Application for authorization of exceptional absence":
    "Application for authorization of exceptional absence",
  "Add a new event": "Add a new event",
};
