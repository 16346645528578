const state = {
  messages: [
    {
      content: "Bonjour, en quoi puis-je vous aider ?",
      writer: "chatbot",
    },
  ],
  loading: false,
};

const mutations = {
  STORE_MESSAGE(state, message) {
    state.messages.push(message);
  },
  CHATBOT_REQUEST(state) {
    state.loading = true;
  },
  CHATBOT_SUCCESS(state) {
    state.loading = false;
  },
};

const actions = {};

export default {
  state,
  mutations,
  actions,
};
