<template>
  <FormTabsBlockComponent
    :key="componentKey"
    :loading="loading"
    :tabs="tabs"
    :invalidTab="invalidTab"
    @formCancel="cancel"
    @formSubmit="submit"
    @update="update"
  />
</template>

<script>
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { mapState } from "vuex";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "SharedDocumentAddEditView",
  components: { FormTabsBlockComponent },
  data() {
    return {
      tabs: [],
      componentKey: 0,
      loading: false,
      params: {},
      title: "",
      invalidTab: {},
      id: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    submit() {
      let response = [];
      Object.values(this.tabs).forEach((tab) => {
        if (!tab.hidden) {
          let form = formManager.processForm(tab.models, false);
          if (tab.code === "documentInfo") {
            form["uploader"] = { id: this.user.id };
          }
          response.push({
            form: tab.models ? form : [],
            code: tab.code,
          });
        }
      });

      this.loading = true;
      const urlCall =
        "/app/studea-manager/document/post" + (this.id ? "/" + this.id : "");
      (this.id
        ? apiConnection.put(urlCall, response)
        : apiConnection.post(urlCall, response)
      ).then((data) => {
        if (data && !data.hasError) {
          this.$router.push({
            name: "studeaManagerSharedDocumentList",
          });
          const message = this.$tc("global.add.success", this.gender, {
            name: this.$t("studea_manager.document.title", 1),
          });
          notificationManager.showNotification("success", message);
        } else if (data.responses && data.responses[0].validationErrors) {
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
          Object.entries(this.tabs).forEach((tab) => {
            const errors = formValidator.processResponses(
              data.responses[0].validationErrors,
              tab[1].models,
            );
            if (errors.length) {
              this.invalidTab = this.id ? tab[1] : tab[0];
            }
          });
        }
        this.loading = false;
      });
    },

    cancel() {
      this.$router.push({
        name: "TrainingCourseList",
      });
    },

    update() {
      if (this.tabs[0].models) {
        this.tabs[0].models.visibleHomeLea.vars.hide =
          !!this.tabs[0].models.category.vars.value;
        this.tabs[0].models.pedagogic.vars.hide =
          !!this.tabs[0].models.category.vars.value;
      }
      console.log();
    },

    init() {
      this.id = this.$route.params.id;
      this.loading = true;
      this.title = this.$t("studea_manager.document.shared_document_add");

      studeaManagerManager.form("document", null, this.id).then((data) => {
        this.tabs = data.tabs;
        this.componentKey++;
        this.loading = false;
        this.$store.commit("SET_APP_LOADING", false);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
