<template>
  <div>
    <div class="header-container fixed-top">
      <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item theme-logo">
            <router-link :to="homeTo" class="logo">
              <span v-if="design.logoType" class="logotype">
                {{ design.logoType }}
              </span>
              <img
                v-else
                alt="logo"
                class="navbar-logo"
                :src="'/assets/images/logo_white.png'"
              />
            </router-link>
          </li>
        </ul>
        <ul class="navbar-item flex-row ms-md-0 ms-auto">
          <li
            :class="{ 'show-search': $store.state.is_show_search }"
            class="nav-item align-self-center search-animated"
          >
            <svg
              class="feather feather-search toggle-search"
              fill="none"
              height="24"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              @click="
                $store.commit('toggleSearch', !$store.state.is_show_search)
              "
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            <form
              :class="{ 'input-focused': $store.state.is_show_search }"
              class="form-inline search-full form-inline search"
            >
              <div class="search-bar">
                <input
                  :placeholder="$t('page.search') + '...'"
                  class="form-control search-form-control ms-lg-auto"
                  type="text"
                />
              </div>
            </form>
          </li>
        </ul>

        <div class="navbar-item flex-row align-items-center">
          <div
            v-if="switched"
            class="pe-0 pe-md-5 d-flex align-items-center"
            style="color: white"
          >
            <div class="d-none d-md-block">
              {{ $t("admin.switchAdmin") }}
              <span class="fw-semibold">{{ user.fullName }}</span>
            </div>
            <button
              class="usurp bgStyle ms-2"
              v-if="switchedFrom === 'front'"
              v-tooltip="{
                title: $t('back_to_my_account'),
                position: 'bottom',
              }"
              @click="restoreUser()"
            >
              <FontAwesomeIcon icon="'fa-light fa-user" />
            </button>
          </div>

          <div
            v-if="
              user && user.roles && user.roles.includes('ROLE_STUDEA_ADMIN')
            "
            class="dark-mode d-flex align-items-center"
          >
            <router-link :to="to" class="switchAdmin">
              <label class="switchAdmin__label" for="switch-admin">
                <span class="d-none d-md-inline">Admin</span>
                <FontAwesomeIcon
                  class="d-inline d-md-none"
                  icon="fa-light fa-cog"
                />
              </label>
              <SwitchTypeComponent :model="switchModel" />
            </router-link>
          </div>

          <div class="dropdown nav-item user-profile-dropdown btn-group">
            <a
              id="ddluser"
              aria-expanded="false"
              class="dropdown-toggle btn-icon-only user nav-link"
              data-bs-toggle="dropdown"
            >
              <AvatarComponent
                v-if="Object.keys(user).length"
                :user="user"
                :profile="userProfileName"
              />
            </a>
            <ul
              aria-labelledby="ddluser"
              class="dropdown-menu dropdown-menu-right"
            >
              <li role="presentation">
                <router-link
                  v-if="user && user.id"
                  :to="{ name: 'profile', params: { id: user.id } }"
                  class="dropdown-item"
                >
                  <svg
                    class="feather feather-user"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  {{ $t("admin.profile") }}
                </router-link>
              </li>
              <li role="presentation">
                <button
                  v-if="dark_mode === 'light'"
                  class="dropdown-item"
                  @click="toggleMode('dark')"
                >
                  <FontAwesomeIcon class="icon" icon="fa-light fa-sun-bright" />
                  Clair
                </button>
                <button
                  v-else-if="dark_mode === 'dark'"
                  class="dropdown-item"
                  @click="toggleMode('system')"
                >
                  <FontAwesomeIcon class="icon" icon="fa-light fa-moon" />
                  Sombre
                </button>
                <button
                  v-else-if="dark_mode === 'system'"
                  class="dropdown-item"
                  @click="toggleMode('light')"
                >
                  <FontAwesomeIcon class="icon" icon="fa-light fa-airplay" />
                  Système
                </button>
              </li>
              <li role="presentation">
                <a href="javascript:" class="dropdown-item" @click="logout">
                  <svg
                    class="feather feather-log-out"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  {{ $t("logout") }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div id="sub-header" class="sub-header-container">
        <div class="header navbar navbar-expand">
          <a
            v-if="isAdminPage"
            class="sidebarCollapse"
            data-placement="bottom"
            href="javascript:void(0);"
            @click="closeSideBar()"
          >
            <svg
              class="feather feather-menu"
              fill="none"
              height="24"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="3" x2="21" y1="12" y2="12"></line>
              <line x1="3" x2="21" y1="6" y2="6"></line>
              <line x1="3" x2="21" y1="18" y2="18"></line>
            </svg>
          </a>
          <BreadCrumbComponent :admin="isAdminPage" />
          <div
            v-if="$route.query.breadcrumbName"
            class="trainingCourse textStyle text-center"
            v-html="$route.query.breadcrumbName"
          ></div>
          <div
            v-if="
              trainingCourse &&
              trainingCourse.displayName &&
              !this.$router.currentRoute.value.matched.find(
                (item) => item.path === '/admin',
              ) &&
              this.$router.currentRoute.value.path !==
                '/dashboard/training-course-list'
            "
            class="trainingCourse textStyle text-center"
          >
            <span>
              {{ trainingCourse.displayName }}
            </span>
          </div>
          <div
            v-if="
              this.$router.currentRoute.value.path ===
              '/dashboard/training-course-list'
            "
            class="trainingCourse text-center welcome"
          >
            <div
              class="d-md-block d-none"
              v-html="
                $tc('dashboard.welcome_message', {
                  name:
                    user && user.firstName && user.lastName
                      ? user.firstName + ' ' + user.lastName
                      : '',
                })
              "
            ></div>
            <div class="d-md-none d-block fw-bold">
              {{ user.firstName }} {{ user.lastName }}
            </div>
            <div
              v-if="Object.keys(trainingCourseList).length"
              class="subTitle"
              v-html="getSubTitle()"
            ></div>
          </div>
          <div class="logo-ufa d-md-block d-none">
            <img
              v-if="logoCfa"
              alt="Logo CFA"
              :src="logoCfaPromotion ? logoCfaPromotion : logoCfa"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";
import { mapState } from "vuex";
import { userManager } from "@/services/security/user-manager";
import { colorManager } from "@/services/platform/color-manager";
import BreadCrumbComponent from "@/components/BreadCrumbComponent.vue";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export default {
  name: "HeaderComponent",
  components: {
    SwitchTypeComponent,
    BreadCrumbComponent,
    AvatarComponent,
  },
  props: {
    isAdminPage: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      selectedLang: {},
      switched: false,
      switchedFrom: "",
      switchModel: {
        vars: {
          label: false,
          name: "admin-switch",
          id: "admin-switch",
          value: false,
        },
      },
      to: {
        name: this.$route.path.includes("admin")
          ? "TrainingCourseList"
          : "adminHome",
      },
      homeTo: { name: "Dashboard" },
    };
  },
  computed: {
    ...mapState({
      countryList: (state) => state.theme.countryList,
      dark_mode: (state) => state.theme.dark_mode,
      trainingCourse: (state) => state.trainingCourse.trainingCourse,
      trainingCourseList: (state) => state.trainingCourse.trainingCourseList,
      user: (state) => state.user.user,
      logoCfa: (state) => state.platform.logoCfa,
      logoCfaPromotion: (state) => state.platform.logoCfaPromotion,
      design: (state) => state.platform.design,
      userProfileName: (state) => state.user.userProfileName,
    }),
  },
  mounted() {
    this.selectedLang = window.$appSetting.toggleLanguage();
    this.toggleMode();
    this.setSwitchAdmin();
    this.setHomeTo();
    this.switched = sessionStorage.getItem("studea-switched") === "1";
    this.switchedFrom = sessionStorage.getItem("initial-studea-token")
      ? "front"
      : "zama";
  },
  watch: {
    $route(to, from) {
      this.watchSwitchAdmin(to, from);
      this.setHomeTo();
    },
  },
  methods: {
    restoreUser() {
      studeaManagerManager.restoreUser();
      this.switched = sessionStorage.getItem("studea-switched") === "0";
    },
    logout() {
      userManager.logout();
    },
    toggleMode(mode) {
      window.$appSetting.toggleMode(mode);
      colorManager.setPlatformStyle();
    },
    getSubTitle() {
      let label = "";
      switch (this.user.type) {
        case "tutor":
          label = this.$tc(
            "tutorPart",
            Object.keys(this.trainingCourseList).length,
            { number: Object.keys(this.trainingCourseList).length },
          );
          break;
        case "apprentice_master":
          label = this.$tc(
            "apprenticeMasterPart",
            Object.keys(this.trainingCourseList).length,
            { number: Object.keys(this.trainingCourseList).length },
          );
          break;
        case "lea_manager":
          label = this.$tc(
            "studeaManagerPart",
            Object.keys(this.trainingCourseList).length,
            { number: Object.keys(this.trainingCourseList).length },
          );
          break;
      }

      return label;
    },
    setHomeTo() {
      if (this.$route.matched.find((item) => item.name === "adminLayout")) {
        this.homeTo = { name: "adminHome" };
      } else if (
        this.$route.matched.find((item) => item.name === "studeaManagerLayout")
      ) {
        this.homeTo = {
          name: "studeaManagerHome",
          params: { trainingCourseId: this.$route.params.trainingCourseId },
        };
      } else {
        this.homeTo = { name: "Dashboard" };
      }
    },
    setSwitchAdmin() {
      if (this.$route.matched.find((item) => item.name === "adminLayout")) {
        this.switchModel.vars.value = true;
      }
      if (
        window.localStorage.getItem("studea-returnPathName") &&
        this.$route.matched.find((item) => item.name === "adminLayout")
      ) {
        this.to = JSON.parse(
          window.localStorage.getItem("studea-returnPathName"),
        );
      }
    },
    watchSwitchAdmin(to, from) {
      if (
        to.matched.find((item) => item.name === "adminLayout") &&
        !from.matched.find((item) => item.name === "adminLayout")
      ) {
        this.switchModel.vars.value = true;
        this.to = { name: from.name, params: from.params, query: from.query };
        window.localStorage.setItem(
          "studea-returnPathName",
          JSON.stringify(this.to),
        );
      } else {
        if (!to.matched.find((item) => item.name === "adminLayout")) {
          this.switchModel.vars.value = false;
          this.to = { name: "adminHome" };
        }
      }
    },
    changeLanguage(item) {
      this.selectedLang = item;
      window.$appSetting.toggleLanguage(item);

      this.$i18n.locale = item.code;
    },
    back() {
      if (
        this.$router.currentRoute.value.name !== "Login" &&
        this.$router.currentRoute.value.name !== "Dashboard"
      ) {
        this.$router.back();
      }
    },
    closeSideBar() {
      this.$store.commit("TOGGLE_SIDE_BAR");
    },
  },
};
</script>

<style lang="scss" scoped>
header.header {
  background: var(--primary-color);
  flex-wrap: nowrap;
}

.navbar-logo {
  @include up-to-md {
    height: 35px !important;
  }
}

.trainingCourse {
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.6;

  span {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  @include up-to-md {
    line-height: 1.2;
    align-items: start;
    max-width: 75%;
    font-size: 14px;
  }

  &.welcome {
    font-weight: 400;

    strong {
      font-weight: 800 !important;
    }
  }

  .subTitle {
    font-size: 15px;

    @include up-to-md {
      font-size: 12px;
    }
  }
}

.icon {
  width: 18px;
  height: 18px;
}

.backToTriad {
  position: absolute;
  white-space: nowrap;
  font-weight: 700;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.switchAdmin {
  display: flex;
  align-items: center;

  &__label > span {
    color: $white !important;
  }

  svg {
    @include up-to-md {
      width: 18px !important;
      margin-top: 2px;
    }
  }
}

.logo-ufa {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.logotype {
  font-size: 33px;
  color: $white;
  letter-spacing: 3px;
  position: relative;
  font-weight: 600;
  top: -1px;
  text-transform: uppercase;
}

.usurp {
  background: none;
  border: none;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  color: $white;
  font-size: 18px;
  transition: all 300ms ease-in-out;

  &:hover {
    background: $white;
    color: var(--primary-color);
  }
}
</style>
