<template>
  <div v-if="answer" :class="'gridRow --' + answer.writerProfile">
    <div class="row align-items-center">
      <div class="col-md-3 textStyle" v-html="answer.writerName"></div>
      <div class="col-md-9">
        <div class="gridRow__values">
          <div
            v-for="(column, index) in cluster.valueList"
            :key="column"
            class="gridRow__value"
          >
            <div class="textStyle">
              <FontAwesomeIcon
                v-if="
                  isCheckboxRadio &&
                  answer.arrayValue.answers[index] === column.value
                "
                icon="fa-light fa-check"
              />
              <span v-else>{{ answer.arrayValue.answers[index] }}</span>
            </div>
          </div>
          <div v-if="cluster.comments" class="gridRow__value comments"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionnaireRowReplyComponent",
  // components: { FontAwesomeIcon },
  props: {
    answer: {
      type: [],
      required: true,
      default: [],
    },
    cluster: {
      type: [],
      required: true,
      default: [],
    },
    row: {
      type: [],
      required: true,
      default: [],
    },
  },
  computed: {
    isCheckboxRadio() {
      return [3, 4].includes(this.cluster.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.gridRow {
  padding: 10px 20px 10px 0;
  position: relative;
  border: none;

  &.--2 {
    background: $light-blue;
  }

  &.--1 {
    background: $light-green;
  }

  &.--3 {
    background: $light-red;
  }

  &.--4 {
    background: $light-orange;
  }

  &__values {
    display: flex;
    align-items: center;
  }

  &__value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.comments {
      padding-right: 15px;
    }
  }

  &__value + &__value {
    margin-left: 10px;
  }
}
</style>
