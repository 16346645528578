import { adminManager } from "@/services/admin/admin-manager";

const state = {
  news: [],
  newsItem: [],
  tagCorrespondance: [
    { id: 10, name: "Bug", color: "redBadge" },
    { id: 11, name: "Actualités", color: "blueBadge" },
    { id: 12, name: "Maintenance", color: "redBadge" },
    {
      id: 13,
      name: "Nouvelle fonctionnalité",
      color: "greenBadge",
    },
    { id: 14, name: "Tutoriel", color: "yellowBadge" },
  ],
};

const mutations = {
  SET_NEWS(state, news) {
    state.news = news;
  },

  TOGGLE_NEWS(state, id) {
    state.newsItem = getters.findNewsItem(state.news, id);
  },
};

const getters = {
  findNewsItem(list, id) {
    return list.find((item) => item.id === Number(id));
  },
};

const actions = {
  fetchNews({ commit }) {
    return adminManager.list("news", false).then((news) => {
      commit("SET_NEWS", news);
    });
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
