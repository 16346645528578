<template>
  <div v-if="skillReply === 1" class="reply">
    <FontAwesomeIcon class="success" icon="fa-light fa-check"></FontAwesomeIcon>
    {{ $t("studea_manager.skillDashboard.skill.acquired") }}
  </div>
  <div v-if="skillReply === 2" class="reply">
    <FontAwesomeIcon class="warning" icon="fa-light fa-clock"></FontAwesomeIcon>
    <span>
      {{ $t("studea_manager.skillDashboard.skill.in_progress") }}
    </span>
  </div>
  <div v-if="skillReply === 3" class="reply">
    <FontAwesomeIcon class="fail" icon="fa-light fa-xmark"></FontAwesomeIcon>
    <span>
      {{ $t("studea_manager.skillDashboard.skill.not_acquired") }}
    </span>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "AccordionSkillReplyComponent",
  components: { FontAwesomeIcon },
  props: {
    skillReply: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.reply {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 5px;
  }

  span {
    white-space: nowrap;
  }
}
.success {
  color: $green;
}

.fail {
  color: $red;
}

.warning {
  color: $orange;
}
</style>
