<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-3 col-lg-4 order-2 order-lg-0">
        <TriadMemberBlock />
      </div>
      <div class="col-xl-7 col-lg-8 order-1">
        <SessionTabsBlock
          :currentSession="currentSession"
          :sessions="sessions"
          @changeSession="changeSession"
        />
        <div class="widget">
          <div class="widget-heading">
            <h2>{{ $t("page.skills_stats") }}</h2>
          </div>
          <div class="widget-content">
            <ApexChart
              v-if="isSeries"
              ref="chart"
              :key="key"
              :options="chartOptions"
              :series="series"
              type="radar"
            />
            <div v-else>{{ $t("stats.no_data") }}</div>
            <div v-if="isMobile">
              <div v-for="(skill, index) in labels" :key="skill.id">
                <span class="fw-bold">{{ index + 1 }}</span> : {{ skill }}
              </div>
            </div>
          </div>
          <BlockLoader :loading="loading" />
        </div>
      </div>
      <div class="col-xl-2 order-0 order-lg-2">
        <div class="sticky">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            @pdf="exportChartToPdf"
            @svg="exportChartToSVG"
            @png="exportChartToPNG"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import ApexChart from "vue3-apexcharts";
import jsPDF from "jspdf";
import SessionTabsBlock from "@/components/blocks/SessionTabsBlock.vue";
import { skillManager } from "@/services/skill/skill-manager";

export default {
  name: "SkillsStatsView",
  components: {
    SessionTabsBlock,
    ApexChart,
    TriadMemberBlock,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      loading: false,
      key: 0,
      labels: [],
      indexes: [],
      chart: {
        fontFamily: "Noto sans",
      },
      chartOptions: {
        chart: {
          id: "skillChart",
          toolbar: {
            show: false,
            offsetY: 10,
          },
          fontFamily: "Noto sans",
        },
        legend: {
          position: "left",
          floating: true,
          offsetY: -10,
          itemMargin: {
            vertical: 5,
          },
          markers: {
            offsetX: -5,
          },
          fontSize: "16px",
          labels: {
            colors: "var(--primary-color)",
          },
        },
        colors: ["#6ac7a4", "#00275e", "#da5164", "#f8a007"],
        responsive: [
          {
            breakpoint: 792,
            options: {
              chart: {
                height: 350,
              },
              legend: {
                position: "top",
                floating: false,
                offsetY: 0,
                fontSize: "14px",
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "10px",
                  },
                },
              },
            },
          },
        ],
      },
      series: null,
      skillOrder: [],
      actions: [
        {
          translation: "PDF export",
          icon: "file-pdf",
          type: "primary",
          action: "pdf",
          cfaStyle: true,
        },
        {
          translation: "PNG export",
          icon: "file-excel",
          type: "primary",
          action: "png",
          cfaStyle: true,
        },
        {
          translation: "SVG export",
          icon: "file",
          type: "primary",
          action: "svg",
          cfaStyle: true,
        },
        {
          translation: this.$t("page.skills"),
          icon: "medal",
          type: "primary",
          actionType: "router",
          action: "MODULE_STUDEA_SKILL",
          cfaStyle: true,
        },
      ],
      isMobile: false,
      isSeries: false,
    };
  },
  computed: {
    ...mapState({
      triad: (state) => state.triad.triad,
      skills: (state) => state.triad.skills,
      user: (state) => state.user.user,
      windowWidth: (state) => state.windowWidth,
      saving: (state) => state.saving,
      sessions: (state) => state.triad.sessions,
      currentSession: (state) => state.triad.currentSession,
      currentSessionSkillReply: (state) => state.triad.currentSessionSkillReply,
    }),
  },
  watch: {
    "triad.trainingCourse.skillCategories"() {
      this.init();
    },

    windowWidth() {
      this.isMobile = this.windowWidth < 768;
    },

    isMobile() {
      if (this.isMobile) {
        this.updateChart(this.indexes);
      } else {
        this.updateChart(this.labels);
      }
    },
    currentSession() {
      this.init();
    },
    sessions() {
      this.initSession();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    updateChart(labels) {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: labels,
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 700,
              },
            },
          },
        },
      };
      this.key++;
    },

    init() {
      window.innerWidth < 768
        ? (this.isMobile = true)
        : (this.isMobile = false);
      this.setLabels();
      this.setSeries();
      this.isMobile
        ? this.updateChart(this.indexes)
        : this.updateChart(this.labels);
    },

    setLabels() {
      const labels = [];
      const indexes = [];
      const order = [];
      let count = 0;
      if (
        this.currentSession &&
        this.currentSession.skillCategories &&
        Object.keys(this.currentSession.skillCategories).length
      ) {
        Object.values(this.currentSession.skillCategories).forEach(
          (category) => {
            Object.values(category.skills).forEach((skill) => {
              labels.push(skill.name);
              indexes.push(count);
              count++;
              order.push(skill.id);
            });
          },
        );
      }
      this.skillOrder = order;
      this.indexes = indexes;
      this.labels = labels;
    },

    setSeries() {
      const series = [];
      if (this.currentSessionSkillReply) {
        Object.entries(this.currentSessionSkillReply).forEach((profile) => {
          const key = profile[0];
          const object = profile[1];
          let answer;
          let data = [];

          if (object && object.skillAnswers) {
            Object.values(this.skillOrder).forEach((id) => {
              answer = object.skillAnswers.find(
                (answer) => answer.skill.id === id,
              );
              data.push(answer ? answer.value : "");
            });
          }
          series.push({ name: this.$t(key), data: data });
        });
      }
      this.series = series;
      this.setIsSeries();
    },

    exportChartToPdf() {
      this.$refs.chart.chart.dataURI().then(({ imgURI }) => {
        const pdf = new jsPDF({ orientation: "l" });
        pdf.addImage(imgURI, "PNG", 10, 10);
        pdf.save("Graphique des compétences");
      });
    },

    exportChartToSVG() {
      this.$refs.chart.chart.exports.exportToSVG();
    },

    exportChartToPNG() {
      this.$refs.chart.chart.exports.exportToPng();
    },

    changeSession(id) {
      this.$store.commit(
        "SET_CURRENT_SESSION",
        this.sessions.find((session) => session.id === id),
      );
    },

    initSession() {
      this.currentSessionSkillReply = {};
      skillManager.initSession();
      if (!Object.keys(this.currentSessionSkillReply).length) {
        if (
          this.triad &&
          this.triad.lastSkillReply &&
          this.triad.lastSkillReply.length
        ) {
          this.$store.commit(
            "SET_CURRENT_SESSION_SKILL_REPLY",
            this.triad.lastSkillReply.find(
              (session) => session.skillSessionId === this.currentSession.id,
            ),
          );
        }
      }
    },

    setIsSeries() {
      this.isSeries = false;
      if (this.series) {
        this.series.forEach((serie) => {
          if (serie.data.length) {
            serie.data.forEach((data) => {
              if (typeof data === "number") {
                this.isSeries = true;
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
