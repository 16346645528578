<template>
  <div class="widget form-widget pb-2">
    <div class="widget-content">
      <div class="panel-body rounded-pills-icon tabs">
        <ul
          id="languageTab"
          class="nav nav-pills mb-4 mt-3 justify-content-center flex-md-row flex-column"
          role="tablist"
        >
          <li
            v-for="(tab, index) in tabs"
            :key="tab.code"
            class="nav-item ms-md-2 me-md-2 me-0 ms-0"
            role="presentation"
          >
            <a
              :id="'tab-' + tab.code + '-tab'"
              :ref="'ref-' + tab.code"
              :aria-controls="'tab-' + tab.code"
              :aria-selected="!index"
              :class="
                'nav-link mb-2 text-center bgStyle' +
                (!index ? ' active' : '') +
                (tab.hidden ? ' d-none' : '')
              "
              :href="'#tab-' + tab.code"
              data-bs-toggle="pill"
              role="tab"
            >
              <FontAwesomeIcon :icon="'fa-light fa-' + tab.icon" />
              {{ tab.title }}
            </a>
          </li>
        </ul>
        <div id="languageTabContent" class="tab-content">
          <div
            v-for="(tab, index) in tabs"
            :id="'tab-' + tab.code"
            :key="tab.code"
            :aria-labelledby="'tab-' + tab.code + '-tab'"
            :class="
              'tab-pane fade' +
              (!index ? ' show active' : '') +
              (tab.hidden ? ' d-none' : '')
            "
            role="tabpanel"
          >
            <FormComponent
              v-if="!index && tab.models"
              :models="tab.models"
              @toggle="toggle"
              @formSubmit="submit"
              @formCancel="cancel"
            />
            <SortTableComponent
              v-else
              :columns="columns"
              :datas="datas"
              :actions="actions"
              @modify="modify"
              @remove="remove"
              @embody="embody"
            />
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
  <ModalFormComponent
    :models="modifyModels"
    :title="$t('admin.user.search.title')"
    @submit="submitAddEdit"
  />
</template>

<script>
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import FormComponent from "@/components/FormComponent.vue";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { tabsManager } from "@/services/utilities/tabs-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { adminManager } from "@/services/admin/admin-manager";

export default {
  name: "UserAddEditView",
  data() {
    return {
      loading: false,
      tabs: [],
      invalidTab: null,
      columns: [],
      datas: [],
      actions: [],
      modifyModels: [],
      editingId: null,
      baseUrl: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.initActions();
      this.baseUrl = "/app/admin/training-course/" + this.$route.params.id;

      apiConnection.get(this.baseUrl).then((data) => {
        this.tabs = data.tabs;
        if (data && data.tabs && data.tabs[1] && data.tabs[1].models) {
          if (Object.keys(data.tabs[1].models).length) {
            this.columns = sortableTableManager.getColumns(
              data.tabs[1].models[0],
              "admin",
            );
            this.datas = data.tabs[1].models;
          }
        }
        this.loading = false;
      });
    },

    cancel() {
      this.$router.push({
        name: this.$route.meta.backPath,
        params: { entity: this.alias },
      });
    },

    toggle() {
      console.log("toggle");
    },

    initActions() {
      this.actions = [
        {
          translation: "admin.embody",
          icon: "user",
          type: "primary",
          action: "embody",
          cfaStyle: false,
        },
        {
          translation: "admin.modify",
          icon: "pen",
          type: "success",
          action: "modify",
          cfaStyle: false,
        },
        {
          translation: "admin.delete",
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
        },
      ];
    },

    submit() {
      this.loading = true;
      const response = formManager.processForm(this.tabs[0].models, false);
      apiConnection.put(this.baseUrl, response).then((data) => {
        formManager.processResponse(
          data,
          { name: "adminTrainings" },
          this.$tc("global.edit.success", 1, {
            name: this.$tc("admin.trainingCourse.title", 1),
          }),
          this.tabs[0].models,
        );
        this.loading = false;
      });
    },

    submitAddEdit(models) {
      const response = formManager.processForm(models, false, this.editingId);

      (this.editingId
        ? apiConnection.put(
            this.baseUrl + "/studea-manager/" + this.editingId,
            response,
          )
        : apiConnection.post(this.baseUrl + "/studea-manager", response)
      ).then((data) => {
        formManager.processResponse(
          data,
          "adminTrainings",
          this.$tc(
            "global." + (this.editingId ? "edit" : "add") + ".title",
            1,
            {
              name: this.$tc("admin.trainingCourse.title", 1).toLowerCase(),
            },
          ),
          this.tabs[0].models,
        );
        this.loading = false;
      });
      tabsManager.displayTab(this.tabs[1]);
    },

    modify(row) {
      this.editingId = row.id;
      this.getModifyModels(row.id);
    },

    embody(row) {
      adminManager.switchUser(row.id, true);
    },

    getModifyModels(id = null) {
      this.modifyModels = [];
      adminManager.form("studea-manager", id, {}, true).then((data) => {
        this.modifyModels = data;
      });
    },

    remove(row) {
      adminManager.remove("studea-manager", row.id).then(() => {
        this.init();
      });
    },
  },
  components: {
    ModalFormComponent,
    SortTableComponent,
    FormComponent,
    BlockLoader,
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
