export default {
  error: {
    error_occured: "Une erreur est survenue",
    reload_page: "Rafraichir la page",
    back: "Revenir en arrière",
    message: {
      not_found: "La page que vous avez demandée n'a pas été trouvée !",
      unavailable: "Service non disponible !",
      not_authorized_title: "Accès interdit",
      not_authorized: "Vous n'etes pas authorisé à accéder à cette page",
      unauthenticated: "Vous devez vous authentifier pour accéder à cette page",
    },
    go_back_to_home: "Revenir à l'accueil",
  },
};
