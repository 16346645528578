export default {
  yourSkills: "Vos compétences",
  skills: "Compétences",
  last_skill_update: "Dernier enregistrement",
  duration: "Durée",
  Category: "Categorie",
  "Skills saved successfully": "Compétences enregistrées avec succès",
  next: "Suivante",
  previous: "Précédente",
};
