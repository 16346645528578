<template>
  <div id="refresh">
    <FontAwesomeIcon class="icon" icon="fa-light fa-spinner" />
  </div>
</template>

<script>
export default {
  name: "RefreshComponent",
  created() {
    this.initRefresh();
  },
  methods: {
    initRefresh() {
      let touchstartY = 0;
      let touchendY = 0;
      let touchMoveY = 0;
      let movePurcent = 0;
      const threshold = window.screen.height / 4;
      let scrollY = 0;

      document.addEventListener(
        "touchstart",
        function (event) {
          const refreshElement = document.getElementById("refresh");
          if (refreshElement) {
            refreshElement.classList.remove("-trans");
          }
          touchstartY = event.changedTouches[0].screenY;
          scrollY = window.scrollY;
        },
        false,
      );

      document.addEventListener(
        "touchmove",
        function (event) {
          touchMoveY = event.touches[0].screenY - touchstartY;
          movePurcent = Math.round((touchMoveY / threshold) * 100);

          const refreshElement = document.getElementById("refresh");
          if (refreshElement && scrollY === 0 && touchMoveY < threshold) {
            refreshElement.setAttribute(
              "style",
              "transform: translate(-50%, " +
                movePurcent * 4 +
                "%) rotate(" +
                movePurcent * 4 +
                "deg); opacity: " +
                movePurcent / 50,
            );
          }
        },
        false,
      );

      document.addEventListener(
        "touchend",
        function (event) {
          touchendY = event.changedTouches[0].screenY;
          const refreshElement = document.getElementById("refresh");
          if (refreshElement) {
            refreshElement.classList.add("-trans");
            refreshElement.setAttribute("style", "");
            if (touchendY > touchstartY + threshold && scrollY === 0) {
              refreshElement.classList.remove("-trans");
              refreshElement.classList.add("-pending");
              setTimeout(function () {
                window.location.reload();
              }, 500);
            }
          }
        },
        false,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#refresh {
  background: $white;
  border-radius: 50%;
  position: fixed;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  width: 35px;
  height: 35px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  box-shadow:
    rgba(14, 30, 37, 0.12) 0 0 4px 0,
    rgba(14, 30, 37, 0.32) 0 0 8px 0;

  .icon {
    width: 24px;
  }

  &.-trans {
    transition: all 75ms ease-in;
  }

  &.-pending {
    transition: all 100ms ease-in-out;
    top: -75px;
    animation: pending 1000ms infinite ease-in;
    opacity: 1;
  }
}

@keyframes pending {
  0% {
    transform: translate(-50%, 400%) rotate(0);
  }
  100% {
    transform: translate(-50%, 400%) rotate(2turn);
  }
}
</style>
