export default {
  login: "Connexion",
  loginTo: "Connexion à",
  resetPassword: "Réinitialiser son mot de passe",
  loginMobile:
    "Veuillez saisir votre identifiant ou votre email pour vous connecter",
  loading: "Chargement...",
  password: "Mot de passe",
  reinitialize: "Réinitialiser",
  username: "Identifiant",
  organization: "Organisme",
  showPassword: "Voir le mot de passe",
  keepMeLoggedIn: "Rester connecté",
  forgotPasswordLink: "Mot de passe oublié ?",
  loginValidation: "Veuillez indiquer vos identifiants pour vous connecter",
  loginLoginValidation: "Veuillez saisir votre identifiant ou votre email",
  loginPasswordValidation: "Veuillez saisir votre mot de passe",
  alertIdentify: "Vous êtes identifiés",
  alertIdentifyError: "Erreur lors de l'identification",
  logout: "Déconnexion",
  badCredentials: "Identifiants erronés",
  password_reset_info:
    "Veuillez saisir votre identifiant ou votre email afin de réinitialiser votre mot de passe",
  reset_password: "Mot de passe oublié",
  reset_password_confirm:
    "Un lien pour générer un nouveau mot de passe a été envoyé dans votre boîte mail",
  new_password_confirm: "Votre mot de passe a été mis à jour avec succès !",
  new_password: "Nouveau mot de passe",
  not_available: "Indisponible",
  accessAccountDenied: "Vous n'avez pas accès à l'application sur ce compte",
  legal_notice: "Mentions légales",
  personal_data: "Données personnelles",
  learner_privacy: "Données personnelles : Alternant",
  professional_tutor_privacy: "Données personnelles : Tuteur professionel",
  pedagogic_tutor_privacy: "Données personnelles - Organismes de formation",
  contact_us: "Nous contacter",
  contact_help: "Assistance",
  terms_and_conditions: "Conditions d'utilisation",
  password_confirm: "Confirmer votre mot de passe",
  password_error: "Les deux mots de passe doivent être identiques",
  login_presentation: "Qu'est ce que le livret numérique",
  login_in_progress: "Connexion en cours",
  password_not_valid:
    "Veuillez saisir un mot de passe comportant 12 caractères dont une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial.",
  "Personal data - Confidentiality": "Données personnelles - Confidentialité",
  "Since May 25, 2018, the entry into force of the General Data Protection Regulation has been amended; regulations on personal data.":
    "Depuis le 25 mai 2018, l'entrée en vigueur du Règlement Général sur la Protection des Données a été modifié; la réglementation sur les données personnelles.",
  "We invite you to read the information notices below. The origin, purpose and processing carried out on the data collected by Studea depend, ... according to the profile of each user.":
    "Nous vous invitons à prendre connaissance des notices d'information ci-dessous. L'origine, la finalité et les traitements réalisées sur les données récoltées par Studea dépendent , ...en fonction du profil de chaque utilisateur.",
  "We offer 3 notices here, depending on your user profile, we invite you to read the one that corresponds to your case":
    "Nous vous proposons ici 3 notices, en fonction de votre profil utilisateur, nous vous invitons à prendre connaissance de celle qui correspond à votre cas",
  "You are a work-study student and you have been registered on the platform by your training organization":
    "Vous êtes un alternant et vous avez été inscrit sur la plateforme par votre organisme de formation",
  "Personal data - Confidentiality (Alternate access)":
    "Données personnelles - Confidentialité (Accès alternant)",
  "You are an employee of a company and you supervise a work-study program as a Tutor":
    "Vous êtes collaborateur/collaboratrice d'une entreprise et vous encadrez un alternant en tant que Tuteur",
  "Personal data - Confidentiality (apprenticeship masters and company tutor)":
    "Données personnelles - Confidentialité (Maîtres d'apprentissage et tuteur entreprise)",
  "You are an employee of a training organization, you administratively manage training courses or you supervise young people as an educational tutor":
    "Vous êtes collaborateur/collaboratrice d'un organisme de formation, vous gérez administrativement de formations ou vous encadrez des jeunes en tant que tuteur pédagogique",
  "Personal data - Confidentiality (Employees of training organizations, educational tutor)":
    "Données personnelles - Confidentialité (Collaborateurs des organismes de formation, tuteur pédagogique)",
};
