<template>
  <div v-for="category in stats" :key="category.lable">
    <h3>{{ category.label }}</h3>
    <div class="row">
      <div
        v-for="data in category.datas"
        :key="data.label"
        :class="
          'mb-4 ' +
          (data.columns ? data.columns : 'col-xl-3 col-lg-4 col-md-3 col-sm-6')
        "
      >
        <div class="summary-list h-100 blockStyle">
          <div class="summery-info">
            <div class="w-summary-details">
              <div class="w-summary-info">
                <h6>
                  {{ data.label }}<br />
                  <span class="summary-count"
                    >{{ data.total }}{{ data.unit ? data.unit : "" }}</span
                  >
                </h6>
                <button v-if="data.download" class="btn-icon -white">
                  <FontAwesomeIcon icon="fa-light fa-download" />
                </button>
              </div>
              <div v-if="data.connected || data.unconnected" class="connected">
                <div>Connecté : {{ data.connected }}%</div>
                <div>Non connecté : {{ data.unconnected }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberCardChart",
  components: {},
  props: {
    stats: {
      type: [],
      required: true,
      default: [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.summary-list {
  position: relative;
  padding: 15px;
  border-radius: 6px;

  h6 {
    font-size: 12px !important;
  }

  span {
    font-size: 20px !important;
  }

  h6,
  span {
    color: $white !important;
  }

  .connected {
    margin-top: 10px;
    color: $white;
    font-size: 12px;
    text-align: right;
  }
}
</style>
