<template>
  <div
    ref="contentModal"
    aria-hidden="true"
    aria-labelledby="contentModal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title textStyle">
            {{ title }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "ModalComponent",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    content: {
      type: String,
      required: true,
      default: "",
    },
    opened: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    opened() {
      const container = this.$refs.contentModal;
      const modal = new Modal(container);
      if (this.opened) {
        modal.show();
      } else {
        modal.hide();
        this.$emit("hidden");
      }
    },
  },
  mounted() {
    this.$refs.contentModal.addEventListener("hidden.bs.modal", this.hidden);
  },
  methods: {
    hidden() {
      this.$emit("hidden");
      const container = this.$refs.contentModal;
      container.removeEventListener("click", this.stop);
    },

    stop(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style scoped lang="scss"></style>
