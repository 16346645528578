<template>
  <div class="widget">
    <div class="widget-heading">
      <button class="btn-icon" @click="back">
        <FontAwesomeIcon icon="fa-light fa-arrow-left" />
      </button>
      <h2 class="textStyle">{{ $t("admin.user.users_profile_title_list") }}</h2>
    </div>
    <div class="widget-content">
      <SortTableComponent
        :columns="columns"
        :datas="profiles"
        :actions="actions"
      />
      <BlockLoader :loading="loading" />
    </div>
  </div>
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";

export default {
  name: "UsersProfileTitleListView",
  data() {
    return {
      profiles: [],
      columns: [
        { name: "title" },
        { name: "titleWithArticle", tradPrefix: "admin.profile_label" },
        { name: "titlePlural", tradPrefix: "admin.profile_label" },
        { name: "titleAbbreviation", tradPrefix: "admin.profile_label" },
      ],
      actions: [
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: "UserProfileEdit",
          cfaStyle: false,
          alias: "",
        },
      ],
      models: [],
      modalTitle: "",
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.loading = true;
    this.initList();
  },
  methods: {
    initList() {
      adminManager.list("user-profile-title", true, {}).then((data) => {
        this.profiles = data;
        this.loading = false;
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
  components: {
    BlockLoader,
    SortTableComponent,
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  justify-content: start;
}
</style>
