export default {
  choose_subject: "Choisissez une matière",
  show_lesson: "Voir le cours",
  course: "Cours",
  lessonLink: "Lien",
  activityDone: "Activités faites",
  workToDo: "Travail à préparer",
  generalCourses: "Cours généraux",
  attachments: "Pièces jointes",
  attachment: "Pièce jointe",
  download: "Télécharger",
  "Taught subject": "Matière enseignée",
};
