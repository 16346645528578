export default {
  admin: {
    lastEmail: "Lien du dernier mail envoyé",
    generatePassword: "Régénérer et envoyer le mot de passe",
    usurpUser: "Usurper le compte de {name}",
    modify: "Modifier {title}",
    delete: "Supprimer {title}",
    add: "Ajouter",
    name: "Nom",
    title: "Titre",
    role: "Rôle",
    trainingCourse: "Diplôme",
    year: "Année",
    trainingOpeningStart: "Début des ouvertures de la formation",
    trainingOpeningEnd: "Fin des ouvertures de la formation",
    level: "Niveau",
    registrationDate: "Date d'enregistrement",
    active: "Actif",
    management_of: "Gestion des",
    contents: "contenus",
    users: "Utilisateurs",
    trainings: "Formations",
    modules: "Modules",
    navigation: {
      users: "Utilisateurs",
      trainings: "Formations",
      modules: "Modules",
      settings: "Paramétrage",
      general: "Général",
      steering: "Pilotage",
    },
    home: {
      title: "Configuration générale",
      title2: "Vue d'ensemble",
    },
    user: {
      list: {
        title: "Les utilisateurs",
      },
      add: {
        title: "Ajouter un utilisateur",
        success: "Utilisateur ajouté avec succès !",
      },
      edit: {
        title: "Modifier un utilisateur",
        success: "Utilisateur modifié avec succès !",
      },
      delete: {
        title: "Confirmation",
        sub_title: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
        success: "Utilisateur supprimé avec succès",
      },
      search: {
        title: "Rechercher des utilisateurs",
      },
      profile_config: "Configuration des profils",
      fusion: "Fusion des utilisateurs",
    },
    training: {
      list: {
        title: "Les formations",
      },
      add: {
        title: "Ajouter une formation",
        success: "Formation ajoutée avec succès !",
      },
      edit: {
        title: "Modifier une formation",
        success: "Formation modifiée avec succès !",
      },
      delete: {
        title: "Confirmation",
        sub_title: "Êtes-vous sûr de vouloir supprimer cette formation ?",
        success: "Formation supprimé avec succès",
      },
      search: {
        title: "Rechercher une formation",
      },
      ufa_add: "Créer une formation",
      ufa_add_test: "Créer une formation test",
      ufa_import: "Importer",
      ufa_export: "Exporter",
      ufa_export_registrations: "Export des inscriptions",
    },
    institution: {
      list: {
        title: "Les établissements actifs",
      },
      add: {
        title: "Ajouter un établissement",
        success: "Établissement ajouté avec succès !",
      },
      edit: {
        title: "Modifier un établissement",
        success: "Établissement modifié avec succès !",
      },
      delete: {
        title: "Confirmation",
        sub_title: "Êtes-vous sûr de vouloir supprimer cet établissement ?",
        success: "Établissement supprimé avec succès",
      },
    },
    degree: {
      list: {
        title: "Les niveaux de formations",
      },
      add: {
        title: "Ajouter un niveau de formation",
        success: "Niveau de formation ajouté avec succès !",
      },
      edit: {
        title: "Modifier un niveau de formation",
        success: "Niveau de formation modifié avec succès !",
      },
      delete: {
        title: "Confirmation",
        sub_title:
          "Êtes-vous sûr de vouloir supprimer ce niveau de formation ?",
        success: "Niveau de formation supprimé avec succès",
      },
    },
    contractType: {
      list: {
        title: "Les types de contrat",
      },
      add: {
        title: "Ajouter un type de contrat",
        success: "Type de contrat ajouté avec succès !",
      },
      edit: {
        title: "Modifier un type de contrat",
        success: "Type de contrat modifié avec succès !",
      },
      delete: {
        title: "Confirmation",
        sub_title: "Êtes-vous sûr de vouloir supprimer ce type de contrat ?",
        success: "Type de contrat supprimé avec succès",
      },
    },
    planContract: {
      list: {
        title: "Les années de formation",
      },
      add: {
        title: "Ajouter une année de formation",
        success: "Année de formation ajoutée avec succès !",
      },
      edit: {
        title: "Modifier une année de formation",
        success: "Année de formation modifiée avec succès !",
      },
      delete: {
        title: "Confirmation",
        sub_title:
          "Êtes-vous sûr de vouloir supprimer cette année de formation ?",
        success: "Année de formation supprimé avec succès",
      },
      actions: {
        activate: {
          title: "Activer",
          success: "Année de formation activée avec succès !",
        },
        activate_recruitment: {
          title: "Activer pour le recrutement",
          success: "Activée pour le recrutement avec succès !",
        },
      },
    },
    titleRncp: {
      list: {
        title: "Les titres RNCP",
      },
      add: {
        title: "Ajouter un titre RNCP",
        success: "Titre RNCP ajouté avec succès !",
      },
      edit: {
        title: "Modifier un titre RNCP",
        success: "Titre RNCP modifié avec succès !",
      },
      delete: {
        title: "Confirmation",
        sub_title: "Êtes-vous sûr de vouloir supprimer ce titre RNCP ?",
        success: "Titre RNCP supprimé avec succès",
      },
    },
    module: {
      list: {
        title: "Gestion des modules",
      },
      edit: {
        title: "Modifier le module",
        success: "Module modifié avec succès !",
      },
      sort: {
        success: "Ordre des modules modifié avec succès !",
      },
    },
    design: {
      title: "Habillage",
    },
    data_agreement: {
      title: "Politique des données",
    },
    historic: {
      title: "Historique",
    },
    statistic: {
      title: "Statistiques",
    },
    export: {
      title: "Exports",
    },
    duplicate: {
      title: "Duplications",
    },
    archive: {
      title: "Archivage",
    },
  },
};
