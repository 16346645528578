<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-6 order-0 order-lg-1">
        <ProfileBlockComponent />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBlockComponent from "@/components/blocks/ProfileBlockComponent.vue";

export default {
  name: "ProfileView",
  components: {
    ProfileBlockComponent,
  },
};
</script>

<style scoped></style>
