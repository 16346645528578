<template>
  <!--  <label v-if="itemModel.vars.label" class="form-label">{{ index ? index + ". " : "" }}{{ itemModel.vars.label }}-->
  <!--    <span v-if="itemModel.vars.help && !isMobile()" :title="itemModel.vars.help" data-bs-placement="top" data-bs-toggle="tooltip">-->
  <!--          <FontAwesomeIcon icon="fa-light fa-circle-question"/>-->
  <!--        </span>-->
  <!--  </label>-->
  <div
    :data-upload-id="'upload-file-' + itemModel.vars.id"
    class="custom-file-container"
  >
    <label
      v-show="itemModel.vars.label"
      :class="
        'form-label d-flex align-items-center' +
        (itemModel.vars.label_attr && itemModel.vars.label_attr.class
          ? ' ' + itemModel.vars.label_attr.class
          : '') +
        (itemModel.vars.required ? ' required' : '')
      "
    >
      {{ index ? index + ". " : "" }}{{ itemModel.vars.label
      }}{{ itemModel.vars.required ? "*" : "" }}
      <button
        @click="download"
        v-tooltip="{ title: this.$t('download_file') }"
        v-if="itemModel.vars.value && itemModel.vars.value.id && !this.picture"
        class="ms-2 btn btn-success btnStyle download"
      >
        <FontAwesomeIcon icon="fa-light fa-download" />
      </button>
      <button
        @click="remove"
        v-tooltip="{ title: this.$t('remove_file') }"
        v-if="
          itemModel.vars.value &&
          itemModel.vars.value.id &&
          !itemModel.vars.disabled &&
          !this.picture
        "
        class="ms-2 btn btn-danger download"
      >
        <FontAwesomeIcon icon="fa-light fa-trash" />
      </button>
      <span
        v-if="itemModel.vars.help && !isMobile()"
        v-tooltip="{ title: itemModel.vars.help }"
      >
        <FontAwesomeIcon icon="fa-light fa-circle-question" />
      </span>
      <a
        :title="$t('clearFile')"
        class="custom-file-container__image-clear d-none"
        href="javascript:void(0)"
        >x</a
      >
    </label>
    <div v-if="isMobile() && itemModel.vars.help" class="form-info">
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
      {{ itemModel.vars.help }}
    </div>
    <button
      v-if="itemModel.vars.value && itemModel.vars.value.id && this.picture"
      class="btn trash btn-danger ms-2"
      @click="remove"
    >
      <FontAwesomeIcon class="icon" icon="fa-light fa-trash" />
    </button>
    <label
      v-if="!itemModel.vars.hide"
      :class="
        'custom-file-container__custom-file' +
        (itemModel.vars.disabled ? ' disabled' : '')
      "
    >
      <input
        :id="itemModel.vars.id"
        :accept="this.extensionsAccepted"
        :disabled="itemModel.vars.disabled"
        :name="itemModel.vars.name"
        class="custom-file-container__custom-file__custom-file-input"
        type="file"
        @focus="focus"
        @input="browse"
      />
      <input name="MAX_FILE_SIZE" type="hidden" value="10485760" />
      <span
        class="custom-file-container__custom-file__custom-file-control"
      ></span>
    </label>
    <div
      v-show="
        itemModel.vars.value &&
        (itemModel.vars.value.data || itemModel.vars.value.pathName) &&
        this.picture
      "
      class="custom-file-container__image-preview preview-img loading"
      @click="download"
    ></div>
  </div>
  <div v-if="itemModel.vars.error" class="invalid-feedback d-block">
    {{ itemModel.vars.error }}
  </div>
</template>

<script>
import { fileManager } from "@/services/file/file-manager";
import FileUploadWithPreview from "file-upload-with-preview";
import "@/assets/sass/forms/file-upload-with-preview.min.css";
import { formValidator } from "@/services/form/form-validator";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { apiConnection } from "@/services/api-connection";
import { loginManager } from "@/services/security/login-manager";
import { pictureManager } from "@/services/utilities/picture-manager";

export default {
  name: "FileTypeComponent",
  data() {
    return {
      itemModel: this.model,
      maxFiles: 1,
      minFiles: 0,
      maxFileSize: 5,
      maxTotalSize: 5,
      validationRules: null,
      validationMessages: null,
      extensionsAccepted: null,
      extensionsAcceptedMessage: null,
      uploadPath: "",
      valid: true,
      picture: false,
      platform: loginManager.getApiPath().slice(0, -3),
    };
  },
  mounted() {
    this.init();
  },
  props: {
    model: {
      type: [],
      required: true,
      default: [],
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: {
    init() {
      this.getFieldParams();
      this.createFileUploader();
    },

    createFileUploader() {
      let baseImage = require("@/assets/images/file-preview.png");
      if (this.itemModel.vars.value && this.itemModel.vars.value.pathName) {
        this.picture = new RegExp("([./])(gif|jpe?g|png|svg|webp|bmp)$").test(
          this.itemModel.vars.value.pathName,
        );
        baseImage = pictureManager.setPicture(this.itemModel.vars.value);
      } else if (this.itemModel.vars.value && this.itemModel.vars.value.data) {
        baseImage = this.itemModel.vars.value.data;
      }

      if (!this.itemModel.vars.hide) {
        new FileUploadWithPreview("upload-file-" + this.itemModel.vars.id, {
          images: {
            baseImage: baseImage,
            backgroundImage: "",
          },
          text: {
            browse: this.$t("browse"),
            chooseFile:
              this.itemModel.vars.value &&
              this.itemModel.vars.value.originalName
                ? this.itemModel.vars.value.originalName
                : this.$t("chooseFile"),
          },
        });
      }
    },

    getFieldParams() {
      const file =
        this.itemModel &&
        this.itemModel.children &&
        this.itemModel.children.file
          ? this.itemModel.children.file
          : null;
      if (file) {
        this.maxFiles = file.vars.max_files;
        this.minFiles = file.vars.min_files;
        this.maxFileSize = file.vars.max_file_size
          ? file.vars.max_file_size
          : this.maxFileSize;
        this.maxTotalSize = file.vars.min_total_size
          ? file.vars.min_total_size
          : this.maxTotalSize;
        this.validationRules = file.vars.validation_rules;
        this.validationMessages = file.vars.validation_messages;
        this.extensionsAccepted = file.vars.extensions_accepted;
        this.extensionsAcceptedMessage = file.vars.extensions_accepted_message;
        this.uploadPath = file.vars.upload_path;
      } else {
        this.extensionsAccepted =
          /^(.*)\/(webp|gif|pdf|doc|docx|xls|xslx|jpe?g|png|svg\+xml|bmp|csv|msword|vnd.ms-excel|vnd.openxmlformats-officedocument.wordprocessingml.document|vnd.openxmlformats-officedocument.spreadsheetml.sheet)$/;
        this.extensionsAcceptedMessage =
          "Merci de sélectionner un fichier de type pdf, doc, xls, xslx, csv, docx, jpeg, svg, bmp, gif ou png";
      }

      if (!this.uploadPath) {
        this.uploadPath = this.itemModel.vars.upload_path;
      }
    },

    validExtension(type) {
      if (
        this.extensionsAccepted &&
        !new RegExp(this.extensionsAccepted).test(type)
      ) {
        this.itemModel.vars.error = this.extensionsAcceptedMessage;
        this.valid = false;
      }
    },

    validSize(size) {
      if (size > this.maxFileSize * 1024 * 1024) {
        this.itemModel.vars.error =
          this.$t("file_size_error") + this.maxFileSize + "Mo";
        this.valid = false;
      }
    },

    async browse(event) {
      if (event.target.files.length) {
        const file = event.target.files[0];
        this.valid = true;
        this.picture = new RegExp("([./])(gif|jpe?g|png|svg+xml)$").test(
          file.type,
        );
        this.validExtension(file.type);
        this.validSize(file.size);
        if (this.valid) {
          fileManager.fileToB64(file).then((result) => {
            delete this.itemModel.vars.error;
            this.itemModel.vars.value = {
              data: result.split(",")[1],
              originalName: file.name,
              type: this.uploadPath,
            };

            this.$emit("uploading");
            apiConnection
              .post("/app/file/upload", this.itemModel.vars.value)
              .then((res) => {
                if (
                  !res.hasError &&
                  res.responses[0] &&
                  res.responses[0].object.id
                ) {
                  this.itemModel.vars.value = res.responses[0].object;
                  this.$emit("draft", this.itemModel);

                  this.$emit("uploaded", result);
                  this.$emit("update", this.itemModel.vars.value);
                }
              });
          });
        } else {
          this.remove(false);
        }
      }
    },

    async download(e) {
      e.preventDefault();
      if (this.itemModel.vars.value && this.itemModel.vars.value.data) {
        const data = this.itemModel.vars.value.data;
        await fileManager.saveFile(
          this.itemModel.vars.value.fileName,
          data,
          this.itemModel.vars.value.type,
        );
      } else if (
        this.itemModel.vars.value &&
        this.itemModel.vars.value.pathName
      ) {
        var a = document.createElement("a");
        a.href =
          this.platform +
          "download-file/path-name-" +
          this.itemModel.vars.value.pathName +
          "/original-name-" +
          this.itemModel.vars.value.originalName;
        a.download = this.itemModel.vars.value.originalName;
        a.click();
      }
    },

    isMobile() {
      return window.innerWidth < 992;
    },

    remove(valid = true) {
      this.itemModel.vars.value = {};
      let input = document.getElementById(this.itemModel.vars.id);
      input.value = null;
      new FileUploadWithPreview("upload-file-" + this.itemModel.vars.id, {
        images: {
          baseImage: require("@/assets/images/file-preview.png"),
          backgroundImage: "",
        },
        text: {
          browse: this.$t("browse"),
          chooseFile: this.$t("chooseFile"),
        },
      });
      if (valid) {
        formValidator.validModel(this.itemModel);
      }
    },

    focus() {
      this.$emit("focus");
    },
  },
  components: { FontAwesomeIcon },
};
</script>

<style lang="scss" scoped>
.preview-img {
  cursor: pointer;

  .loading {
    position: relative;
  }
}

.trash {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;

  .icon {
    width: 20px;
  }
}

.disabled {
  cursor: not-allowed;
}

.download {
  width: 25px;
  height: 25px;

  svg {
    width: 15px;
    height: 15px;
  }
}
</style>
