<template>
  <div class="pagination">
    <div v-if="nbPages > 1" class="pages">
      <button
        :disabled="activePage === 1"
        class="btn"
        type="button"
        @click="changePage(activePage - 1)"
      >
        <FontAwesomeIcon icon="fa-light fa-chevron-left" />
      </button>
      <button
        :class="'btn' + (1 === activePage ? ' active' : '')"
        type="button"
        @click="changePage(1)"
      >
        1
      </button>
      <button v-if="activePage > 4" :disabled="true" class="btn" type="button">
        ...
      </button>
      <div v-for="n in nbPages" :key="n">
        <button
          v-if="showButton(n)"
          :class="'btn' + (n === activePage ? ' active' : '')"
          type="button"
          @click="changePage(n)"
        >
          {{ n }}
        </button>
      </div>
      <button
        v-if="activePage <= nbPages - 4"
        :disabled="true"
        class="btn"
        type="button"
      >
        ...
      </button>
      <button
        :class="'btn' + (nbPages === activePage ? ' active' : '')"
        type="button"
        @click="changePage(nbPages)"
      >
        {{ nbPages }}
      </button>
      <button
        :disabled="activePage === nbPages"
        class="btn"
        type="button"
        @click="changePage(activePage + 1)"
      >
        <FontAwesomeIcon icon="fa-light fa-chevron-right" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SortTablePaginationComponent",
  components: {},
  props: {
    nbPages: {
      type: Number,
      required: true,
    },
    activePage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {
    changePage(payload) {
      this.$emit("changePage", payload);
    },

    showButton(n) {
      let mobile = window.innerWidth < 768;
      let rightOffset = mobile ? 2 : 5;
      let leftOffset = mobile ? 1 : 3;
      let delta = mobile ? 1 : 2;

      if (this.activePage >= this.nbPages - leftOffset) {
        leftOffset = mobile ? 2 : 4;
      }

      if (n === 1 || n === this.nbPages) {
        return false;
      }
      if (this.activePage < rightOffset && n <= rightOffset) {
        return true;
      } else if (
        this.activePage > this.nbPages - leftOffset &&
        n >= this.nbPages - leftOffset
      ) {
        return true;
      } else if (
        this.activePage >= rightOffset &&
        n < this.activePage + delta &&
        n > this.activePage - delta
      ) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 9px 20px 0;

    @include up-to-md {
      justify-content: flex-start;
    }

    @include dark-theme {
      color: $white;
    }

    .pages {
      display: flex;
      justify-content: flex-end;

      .btn {
        margin: 0 3px;
        width: 35px;
        height: 35px;
        background-color: $blue;
        color: $white;

        &.active,
        &:hover {
          border-color: $blue;
          background-color: $white;
          color: $blue;
        }
      }
    }

    .overPages {
      margin: 0 5px;
      font-weight: 700;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
