import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { messageManager } from "@/services/message/message-manager";

const state = {
  messages: {},
};

const mutations = {
  INIT_MESSAGE_LIST(state, list) {
    state.messages = list;
  },

  SET_USER_NEW_MESSAGE_COUNT(state, count) {
    state.messages.userNewMessage = count;
  },
};

const actions = {
  sendMessage(context, message) {
    return messageManager.postNewMessage(message).then((success) => {
      if (!success.error) {
        notificationManager.showNotification(
          "success",
          i18n.global.t("chat.message_success"),
        );
      } else {
        notificationManager.showNotification(
          "error",
          i18n.global.t("chat.message_error"),
        );
      }
      return success;
    });
  },
  getMessages({ commit }, archived = 0) {
    return messageManager.getMessages(archived).then((messages) => {
      commit("INIT_MESSAGE_LIST", messages);
    });
  },
  openMessage(context, id) {
    return messageManager.openMessage(id);
  },
  reportMessage({ dispatch }, message) {
    return messageManager.reportMessage(message).then(() => {
      dispatch("refreshMessages", false);
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
