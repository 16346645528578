import { i18n } from "@/i18n";
import ProfileView from "@/views/Account/ProfileView.vue";

export default {
  path: "/profile/:id",
  name: "profile",
  component: ProfileView,
  meta: {
    authRequired: true,
    title: i18n.global.t("page.profile"),
    breadcrumb: true,
  },
};
