<template>
  <div v-for="category in skills" :key="category" class="subCategory">
    <SkillFrontListComponent
      v-if="category.skills && Object.keys(category.skills).length"
      :index="index"
      :name="category.name"
      :skills="category.skills"
      :replyHistoric="replyHistoric"
      :historicLoading="historicLoading"
      :skillId="skillId"
      @update="update"
      @openJustificativeForm="openForm"
      @showHistoric="showHistoric"
    />
    <SkillSubCategoryComponent
      v-if="category.daughters && Object.keys(category.daughters).length"
      :index="index + 1"
      :skills="category.daughters"
      :replyHistoric="replyHistoric"
      :skillId="skillId"
      @update="update"
      @openJustificativeForm="openForm"
      @showHistoric="showHistoric"
      },
    />
  </div>
</template>

<script>
import SkillFrontListComponent from "@/components/skills/SkillFrontListComponent.vue";

export default {
  name: "SkillSubCategoryComponent",
  components: { SkillFrontListComponent },
  props: {
    skills: {
      type: [],
      required: true,
      default: [],
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    replyHistoric: {
      type: Object,
      required: false,
      default: null,
    },
    skillId: {
      type: Number,
      required: false,
      default: null,
    },
    historicLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    showHistoric(skill) {
      this.$emit("showHistoric", skill);
    },

    update(skill) {
      this.$emit("update", skill);
    },

    openForm(id) {
      this.$emit("openJustificativeForm", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.subCategory {
  margin-top: 20px;

  &__title {
    border-top: 2px solid var(--primary-color);
    padding: 6px 0;
  }
}
</style>
