<template>
  <div class="row">
    <div class="col-lg-10">
      <div class="widget">
        <div class="widget-heading -list">
          <h1 class="textStyle">
            {{ $t("admin.training.list.title") }}
            {{
              isFiltered
                ? "(" +
                  Object.keys(datas).length +
                  " résultat" +
                  (Object.keys(datas).length > 1 ? "s" : "") +
                  " filtré" +
                  (Object.keys(datas).length > 1 ? "s" : "") +
                  ")"
                : ""
            }}
          </h1>
          <div>
            <button
              v-if="isFiltered"
              class="btn-icon me-1"
              v-tooltip="{ title: $t('init_search') }"
              type="button"
              @click="resetSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
            </button>
            <button
              class="btn-icon"
              v-tooltip="{ title: $t('search_motor') }"
              type="button"
              @click="openSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
            </button>
          </div>
        </div>
        <div class="widget-content">
          <SortTableComponent
            :actions="actions"
            :apiFilters="false"
            :columns="columns"
            :datas="datas"
            :idKey="5"
            :loading="loading"
            path="studeaManagerLayout"
            @remove="remove"
          />
        </div>
      </div>
    </div>
    <div class="col-lg-2">
      <div id="collapseContainer">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">Actions</h2>
          </div>
          <div class="widget-content">
            <router-link
              :to="{ name: 'adminTrainingsAdd' }"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{ $t("admin.training.ufa_add") }}
            </router-link>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{ $t("admin.training.ufa_add_test") }}
            </button>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-upload" />
              {{ $t("admin.training.ufa_import") }}
            </button>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-download" />
              {{ $t("admin.training.ufa_export") }}
            </button>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-download" />
              {{ $t("admin.training.ufa_export_registrations") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :title="$t('admin.training.search.title')"
    storageKey="studea-trainings"
    @search="search"
  />
</template>

<script>
import { mapState } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { apiConnection } from "@/services/api-connection";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";

export default {
  name: "HistoricListView",
  components: {
    ModalFormComponent,
    SortTableComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      loading: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      isFiltered: false,
      actions: [
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: "adminTrainingsEdit",
          cfaStyle: false,
        },
        {
          translation: "admin.delete",
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
        },
      ],
      columns: [],
      filterModels: [],
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.usersList,
      locale: (state) => state.theme.locale,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    remove(row) {
      adminManager.remove("historic", row.id).then(() => {
        this.init();
      });
    },

    search(params) {
      this.loading = true;
      this.listParams = {
        search: params,
      };
      this.getDatas();
      this.isFiltered = true;
    },

    init() {
      this.loading = true;
      this.getDatas();
    },

    getDatas() {
      adminManager.list("training", false, this.listParams).then((data) => {
        this.columns = sortableTableManager.getEntriesColumns(data.columns);
        this.datas = data.data;
        this.loading = false;
      });
    },

    getFilters() {
      this.filterModels = [];
      apiConnection.get("/app/admin/training/search/form").then((data) => {
        this.filterModels = data;
      });
    },

    openSearch() {
      this.getFilters();
      this.isSearchOpen = true;
    },

    resetSearch() {
      this.listParams = [];
      this.isFiltered = false;
      this.getDatas();
      window.localStorage.removeItem("studea-trainings");
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.-list {
    cursor: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}
</style>
