<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">{{ $t("admin.export.title") }}</h2>
    </div>
    <div class="filters">
      <FormComponent
        @update="update"
        :models="filter"
        :disableFocusAnimation="true"
        :hideSubmit="true"
      />
    </div>
    <div class="widget-content">
      <h3 class="h6 textStyle mb-3">
        {{ $t("admin.export.available_exports") }}
      </h3>
      <div class="row">
        <div
          class="col-lg-2 col-md-4 mb-3"
          v-for="exportItem in exports"
          :key="exportItem.type"
        >
          <div class="export">
            <button
              class="export__btn blockStyle"
              @click="download(exportItem)"
            >
              <FontAwesomeIcon icon="fa-light fa-download" />
              <h6>
                {{ exportItem.name }}
              </h6>
            </button>
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { adminManager } from "@/services/admin/admin-manager";

export default {
  name: "ExportView",
  components: { BlockLoader, FormComponent },
  data() {
    return {
      loading: false,
      params: [],
      filter: {
        year: {
          vars: {
            name: "year",
            label: this.$i18n.t("admin.export.year"),
            id: "year",
            value: "",
            block_prefixes: ["", "choice"],
            expandable: false,
            multiple: false,
            row_attr: {
              class: "col-md-12",
            },
            choices: [
              {
                label: "Toutes",
                value: "all",
                data: "all",
              },
              {
                label: "2024",
                value: "2024",
                data: "2024",
              },
              {
                label: "2023",
                value: "2023",
                data: "2023",
              },
            ],
          },
        },
      },
      exports: [
        {
          name: "Exporter les signatures des questionnaires de tous les diplômes",
          type: 1,
        },
        {
          name: "Exporter le cumul des absences par diplôme",
          type: 2,
        },
        {
          name: "Exporter les absences par apprenti",
          type: 3,
        },
        {
          name: "Exporter les visites en entreprise",
          type: 4,
        },
        {
          name: "Exporter les contrats",
          type: 5,
        },
        {
          name: "Exporter tableau de bord de suivi",
          type: 6,
        },
        {
          name: "Exporter tableau récapitulatif",
          type: 7,
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getDatas();
    },

    update() {
      this.params = formManager.processForm(this.filter);
      this.getDatas();
    },

    getDatas() {
      this.loading = true;
      adminManager.list("training").then((data) => {
        console.log(data);
        this.loading = false;
      });
    },

    download(item) {
      this.$store.commit("FILE_DOWNLOAD_REQUEST");
      setTimeout(() => {
        this.$store.commit("FILE_DOWNLOAD_SUCCESS");
        notificationManager.showNotification(
          "success",
          item.name + " réussis !",
        );
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.export {
  height: 100%;

  &__btn {
    width: 100%;
    color: $white;
    padding: 10px 15px;
    height: 100%;
    border-radius: 6px;
    border: 1px dashed;

    &:hover {
      background: $white !important;
      color: $admin-color !important;
    }
  }

  h6 {
    color: inherit;
    font-size: 14px;
    margin-top: 5px;
  }
}

.filters {
  padding: 20px 20px 10px 20px;
  border-bottom: 1px dashed $m-color_3;
}
</style>
