import { pictureManager } from "@/services/utilities/picture-manager";
import store from "@/store";

class ColorManager {
  buildStyle(trainingCourse) {
    let primaryColor = "#000";
    let secondaryColor = "#000";

    if (store.state.platform.design) {
      primaryColor = store.state.platform.design.principalColor
        ? store.state.platform.design.principalColor
        : "#000";
      secondaryColor = store.state.platform.design.secondaryColor
        ? store.state.platform.design.secondaryColor
        : "#000";
    }

    if (
      trainingCourse &&
      trainingCourse.training &&
      trainingCourse.training.institution
    ) {
      if (trainingCourse.training.institution.principalColor) {
        primaryColor = trainingCourse.training.institution.principalColor;
      }
      if (trainingCourse.training.institution.secondaryColor) {
        secondaryColor = trainingCourse.training.institution.secondaryColor;
      }
    }

    const css =
      "" +
      "body {" +
      "--primary-color: " +
      primaryColor +
      " !important;" +
      "--secondary-color: " +
      secondaryColor +
      " !important;" +
      "}";

    let style = document.createElement("style");
    style.type = "text/css";
    style.id = "platformStyle";
    style.innerHTML = css;
    document.getElementsByTagName("head")[0].appendChild(style);
  }

  setFavicon() {
    const favicon = document.querySelector("link[rel~='icon']");
    if (favicon && store.state.platform.design) {
      favicon.href =
        store.state.platform.design && store.state.platform.design.favicon
          ? pictureManager.setPicture(
              store.state.platform.design.favicon,
              "favicon",
            )
          : "/favicon.png";
    }
  }

  setPlatformStyle(trainingCourse = null) {
    let style = document.getElementById("platformStyle");
    if (style) {
      style.remove();
    }
    this.buildStyle(trainingCourse);
    this.setFavicon();
  }
}

export const colorManager = new ColorManager();
