import { i18n } from "@/i18n";
import ErrorView from "@/views/Error/ErrorView.vue";

export default {
  path: "/error",
  name: "Error",
  component: ErrorView,
  meta: {
    breadcrumb: true,
    title: i18n.global.t("page.error"),
  },
};
