export default {
  timetable: "Emploi du temps",
  yourCourses: "Vos cours",
  "There are no events on this day.": "Il n'y aucun évenement ce jour la",
  Today: "Aujourd'hui",
  Month: "Mois",
  Week: "Semaine",
  Day: "Jour",
  "Application for authorization of exceptional absence":
    "Demande d'autorisation d'absence exceptionnelle",
  "Add a new event": "Ajouter un nouvel évènement",
};
