export default {
  notepad: {
    pinned: "Épinglés",
    unpinned: "Non épinglés",
    all: "Toutes les notes",
    pinned_success: "Note épinglée avec succès !",
    unpinned_success: "Note désépinglée avec succès !",
    profiles: "Profils",
  },
};
