class PictureManager {
  setPicture(itemPicture, prefix = null) {
    if (itemPicture && itemPicture.pathName) {
      let pathName = itemPicture.pathName;

      if (prefix) {
        let split = itemPicture.pathName.split("/");
        split.pop();
        pathName = split.join("/") + "/" + prefix + "_" + itemPicture.name;
      }

      return (
        this.getApiPath().slice(0, -3) +
        "inline-file/path-name-" +
        pathName +
        "/original-name-" +
        itemPicture.originalName
      );
    }
  }

  getApiPath() {
    return window.localStorage.getItem("studea-apiPath")
      ? window.localStorage.getItem("studea-apiPath")
      : window.sessionStorage.getItem("studea-apiPath");
  }

  preloadImages(design) {
    const container = document.getElementById("preload");
    let urls = [];
    if (design && design.logoCfa) {
      urls.push(this.setPicture(design.logoCfa, "login"));
    }
    if (design && design.logoHomePage) {
      urls.push(this.setPicture(design.logoHomePage, "home"));
    }
    if (design && design.favicon) {
      urls.push(this.setPicture(design.favicon, "favicon"));
    }
    return Promise.all(
      urls.map((url) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = resolve;
          img.onerror = resolve;
          container.append(img);
        });
      }),
    );
  }
}

export const pictureManager = new PictureManager();
