import { i18n } from "@/i18n";

const state = {
  dark_mode: "light",
  is_dark_mode: false,
  locale: "fr",
  countryList: [
    { code: "en", name: "English" },
    { code: "fr", name: "French" },
    { code: "ca", name: "Catalan" },
  ],
};

const mutations = {
  TOGGLE_DARK_MODE(state, value) {
    //light|dark|system
    value = value || "light";
    localStorage.setItem("studea-dark_mode", value);
    state.dark_mode = value;
    if (value == "light") {
      state.is_dark_mode = false;
    } else if (value == "dark") {
      state.is_dark_mode = true;
    } else if (value == "system") {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        state.is_dark_mode = true;
      } else {
        state.is_dark_mode = false;
      }
    }

    if (state.is_dark_mode) {
      document.querySelector("body").classList.add("dark");
    } else {
      document.querySelector("body").classList.remove("dark");
    }
  },
  TOGGLE_LOCALE(state, value) {
    value = value || "en";
    i18n.global.locale = value;
    localStorage.setItem("studea-i18n_locale", value);
    state.locale = value;
  },
};

const actions = {};

export default {
  state,
  mutations,
  actions,
};
