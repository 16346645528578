import store from "@/store";
import { colorManager } from "@/services/platform/color-manager";
import { platformManager } from "@/services/platform/platform-manager";
import { pictureManager } from "@/services/utilities/picture-manager";
import { loginManager } from "@/services/security/login-manager";

const state = {
  platforms: {},
  platform: {},
  design: {},
  dataAgreement: {},
  documents: {},
  questionnaireConfig: {},
  logoCfa: null,
  logoCfaPromotion: null,
  logoHomepage: null,
  apprenticeProfileLabel: {},
  tutorProfileLabel: {},
  apprenticeMasterProfileLabel: {},
  companyManagerProfileLabel: {},
  studeaManagerProfileLabel: {},
  needUpdate: true,
  name: window.localStorage.getItem("studea-platformName")
    ? window.localStorage.getItem("studea-platformName")
    : "Studea",
};

const mutations = {
  SET_LOGO_CFA_PROMOTION(state, logo) {
    state.logoCfaPromotion = logo;
  },
  SET_PLATFORMS(state, list) {
    state.platforms = list;
  },
  SET_PLATFORM(state, platform) {
    state.platform = platform;
    loginManager.setApiPath(platform.hostname);
  },
  SET_PLATFORM_NAME(state, name) {
    window.localStorage.setItem("studea-platformName", name);
    state.name = name;
  },
  SET_DESIGN(state, design) {
    state.design = design;
    state.logoCfa =
      design && design.logoCfa
        ? pictureManager.setPicture(design.logoCfa, "login")
        : "";
    state.logoHomepage =
      design && design.logoHomepage
        ? pictureManager.setPicture(design.logoHomepage, "home")
        : "";
  },
  SET_DATA_AGREEMENT(state, dataAgreement) {
    state.dataAgreement = dataAgreement;
  },
  SET_DOCUMENT(state, documents) {
    state.documents = documents;
  },
  SET_QUESTIONNAIRE_CONFIG(state, questionnaireConfig) {
    state.questionnaireConfig = questionnaireConfig;
  },
  INIT_WEBSITE_MODULE(state) {
    state.websiteList = {};
    state.website = {};
    state.logo = null;
  },
  SET_APPRENTICE_PROFILE_LABEL(state, apprenticeProfileLabel) {
    state.apprenticeProfileLabel = apprenticeProfileLabel;
  },
  SET_TUTOR_PROFILE_LABEL(state, tutorProfileLabel) {
    state.tutorProfileLabel = tutorProfileLabel;
  },
  SET_APPRENTICE_MASTER_PROFILE_LABEL(state, apprenticeMasterProfileLabel) {
    state.apprenticeMasterProfileLabel = apprenticeMasterProfileLabel;
  },
  SET_COMPANY_MANAGER_PROFILE_LABEL(state, companyManagerProfileLabel) {
    state.companyManagerProfileLabel = companyManagerProfileLabel;
  },
  SET_STUDEA_MANAGER_PROFILE_LABEL(state, studeaManagerProfileLabel) {
    state.studeaManagerProfileLabel = studeaManagerProfileLabel;
  },
  SET_NEED_UPDATE(state, needUpdate) {
    state.needUpdate = needUpdate;
  },
};

const actions = {
  fetchWebsiteFromUser({ commit }, username) {
    return platformManager.loadWebsites(username).then((platforms) => {
      commit("SET_PLATFORMS", platforms);
    });
  },

  fetchDataAgreement({ commit }) {
    if (store.state.isInternet) {
      return platformManager.loadDataAgreement().then((dataAgreement) => {
        commit("SET_DATA_AGREEMENT", dataAgreement);
      });
    }
  },

  fetchDocuments({ commit }) {
    if (store.state.isInternet) {
      return platformManager.loadDocuments().then((documents) => {
        commit("SET_DOCUMENT", documents);
      });
    }
  },

  fetchDesign({ commit, dispatch }, payload) {
    return platformManager.loadDesign().then((design) => {
      dispatch("fetchDataAgreement");
      dispatch("fetchDocuments");
      if (!design) {
        dispatch("fetchDesign", { disableLoading: false });
      }
      commit("SET_DESIGN", design);
      colorManager.setPlatformStyle();
      pictureManager.preloadImages(design).then(() => {
        if (payload.disableLoading) {
          commit("SET_APP_LOADING", false);
        }
      });
    });
  },

  fetchConfig({ commit }) {
    if (store.state.isInternet) {
      return platformManager.loadQuestionnaireConfig().then((data) => {
        commit("SET_QUESTIONNAIRE_CONFIG", data.questionnaireConfig);
        commit("SET_APPRENTICE_PROFILE_LABEL", data.userProfileTitleApprentice);
        commit("SET_TUTOR_PROFILE_LABEL", data.userProfileTitleTutor);
        commit(
          "SET_APPRENTICE_MASTER_PROFILE_LABEL",
          data.userProfileTitleApprenticeMaster,
        );
        commit(
          "SET_STUDEA_MANAGER_PROFILE_LABEL",
          data.userProfileTitleStudeaManager,
        );
        commit(
          "SET_COMPANY_MANAGER_PROFILE_LABEL",
          data.userProfileTitleCompanyManager,
        );
      });
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
