import { i18n } from "@/i18n";
import SharedDocumentAddEditView from "@/views/StudeaManager/Document/SharedDocumentAddEditView.vue";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";
import SharedDocumentListView from "@/views/StudeaManager/Document/SharedDocumentListView.vue";

export default {
  path: "/studea-manager/shared-document",
  name: "studeaManagerSharedDocument",
  component: GlobalLayoutView,
  meta: {
    authRequired: true,
    title: i18n.global.t("studea_manager.document.shared_documents"),
    breadcrumb: true,
  },
  children: [
    {
      path: "add",
      name: "studeaManagerSharedDocumentAdd",
      component: SharedDocumentAddEditView,
      meta: {
        title: i18n.global.t("studea_manager.document.shared_document_add"),
        breadcrumb: true,
      },
    },
    {
      path: "edit/:id",
      name: "studeaManagerSharedDocumentEdit",
      component: SharedDocumentAddEditView,
      meta: {
        title: i18n.global.t("studea_manager.document.shared_document_edit"),
        breadcrumb: true,
      },
    },
    {
      path: "list",
      name: "studeaManagerSharedDocumentList",
      component: SharedDocumentListView,
      meta: {
        title: i18n.global.t("studea_manager.document.shared_document_list"),
        breadcrumb: true,
      },
    },
  ],
};
