const state = {
  status: false,
  code: null,
  message: null,
};

const mutations = {
  SET_ERROR(state, status) {
    state.status = status;
  },
  SET_ERROR_CODE(state, code) {
    state.code = code;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
};

export default {
  state,
  mutations,
};
