import { Capacitor } from "@capacitor/core";
import WebLoginView from "@/views/Login/WebLoginView.vue";
import MobileLoginView from "@/views/Login/MobileLoginView.vue";
import { i18n } from "@/i18n";
import LayoutView from "@/views/Login/LayoutView.vue";
import ResetPasswordView from "@/views/Login/ResetPasswordView.vue";
import NewPasswordView from "@/views/Login/NewPasswordView.vue";
import SwitchView from "@/views/Login/SwitchView.vue";

export default {
  path: "/login",
  name: "loginLayout",
  component: LayoutView,
  redirect: { name: "Login" },
  meta: {
    authRequired: false,
    title: i18n.global.t("page.login"),
  },
  children: [
    {
      path: "",
      name: "Login",
      component:
        Capacitor.getPlatform() === "web" ? WebLoginView : MobileLoginView,
      meta: {
        authRequired: false,
        title: i18n.global.t("page.login"),
      },
    },
    {
      path: "reset-password",
      name: "loginResetPassword",
      component: ResetPasswordView,
      meta: {
        authRequired: false,
        title: i18n.global.t("page.reset_password"),
      },
    },
    {
      path: "new-password/:hash",
      name: "loginNewPassword",
      component: NewPasswordView,
      meta: {
        authRequired: false,
        title: i18n.global.t("page.new_password"),
      },
    },
    {
      path: "switch",
      name: "loginSwitch",
      component: SwitchView,
      meta: {
        authRequired: false,
        title: i18n.global.t("page.switch"),
      },
    },
  ],
};
