export default {
  choose_subject: "Choose a subject",
  show_lesson: "Show lesson",
  course: "Cours",
  lessonLink: "Link",
  activityDone: "Activity done",
  workToDo: "Work to do",
  generalCourses: "General courses",
  attachments: "Attachments",
  attachment: "Attachment",
  "Taught subject": "Taught subject",
};
