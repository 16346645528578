import absence from "@/translations/absence/en";
import account from "@/translations/account/en";
import document from "@/translations/document/en";
import forms from "@/translations/forms/en";
import questionnaire from "@/translations/questionnaire/en";
import gradebook from "@/translations/gradebook/en";
import lesson from "@/translations/lesson/en";
import login from "@/translations/login/en";
import chat from "@/translations/chat/en";
import page from "@/translations/page/en";
import skills from "@/translations/skills/en";
import timetable from "@/translations/timetable/en";
import trainingCourse from "@/translations/training-course/en";
import triad from "@/translations/triad/en";
import website from "@/translations/platform/en";
import admin from "@/translations/admin/en";
import studeaManager from "@/translations/studea-manager/en";
import dashboard from "@/translations/dashboard/en";
import global from "@/translations/global/en";
import error from "@/translations/error/en";
import notepad from "@/translations/notepad/en";

export const en = {
  ...absence,
  ...account,
  ...document,
  ...forms,
  ...questionnaire,
  ...gradebook,
  ...lesson,
  ...login,
  ...chat,
  ...page,
  ...skills,
  ...timetable,
  ...trainingCourse,
  ...triad,
  ...website,
  ...admin,
  ...studeaManager,
  ...dashboard,
  ...global,
  ...error,
  ...notepad,
};
