<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-lg-4" v-if="Object.keys(configMenu).length">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">Paramétrage</h2>
          </div>
          <div class="widget-content">
            <div class="menu">
              <router-link
                v-for="item in configMenu"
                :key="item.id"
                :to="{ name: item.pathName }"
                class="menu__item menuStyle bgStyle w-100"
                v-access:[$store.state.user.permissions]="{
                  access: accessManager.VIEW,
                  code: item.access,
                }"
              >
                <FontAwesomeIcon
                  v-if="item.icon"
                  :icon="'fa-light fa-' + item.icon"
                  class="iconStyle icon"
                />
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4" v-if="Object.keys(moduleMenu).length">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">Modules</h2>
          </div>
          <div class="widget-content">
            <div class="menu">
              <VueDraggableNext
                :animation="200"
                :delay="200"
                :delay-on-touch-only="true"
                :list="moduleMenu"
                class="w-100"
                @change="sortModule"
              >
                <template v-for="item in moduleMenu" :key="item.id">
                  <div
                    v-access:[$store.state.user.permissions]="{
                      access: accessManager.VIEW,
                      code: item.access,
                    }"
                    class="menu__item -module menuStyle bgStyle w-100"
                  >
                    <router-link
                      v-if="
                        !item.disabled &&
                        (!editing || editing !== item.id) &&
                        redirectToModulePage(item)
                      "
                      :to="{ name: item.pathName, params: item.params }"
                      class="menu__link textStyle"
                    >
                      <FontAwesomeIcon
                        v-if="item.icon"
                        :icon="'fa-light fa-' + item.icon"
                        class="iconStyle"
                      />
                      {{ item.title }}
                    </router-link>
                    <div
                      v-if="
                        !redirectToModulePage(item) &&
                        (!editing || editing !== item.id) &&
                        !item.disabled
                      "
                      class="menu__link"
                    >
                      <FontAwesomeIcon
                        v-if="item.icon"
                        :icon="'fa-light fa-' + item.icon"
                        class="iconStyle"
                      />
                      {{ item.title }}
                    </div>
                    <div v-if="item.disabled" class="menu__link -disabled">
                      <FontAwesomeIcon
                        v-if="item.icon"
                        :icon="'fa-light fa-' + item.icon"
                        class="iconStyle"
                      />
                      {{ item.title }}
                    </div>
                    <form
                      v-if="editing && editing === item.id"
                      class="menu__input"
                    >
                      <FontAwesomeIcon
                        v-if="item.icon"
                        :icon="'fa-light fa-' + item.icon"
                        class="iconStyle"
                      />
                      <InputTypeComponent
                        :model="moduleTitle(item)"
                        @update="updateTitle"
                      />
                      <button
                        class="btn btnStyle btn-success ms-2"
                        type="submit"
                        @click="submitTitle($event, !item.disabled)"
                      >
                        <FontAwesomeIcon class="m-0" icon="fa-light fa-check" />
                      </button>
                    </form>
                    <div class="menu__switch">
                      <button
                        v-if="
                          !item.disabled && (!editing || editing !== item.id)
                        "
                        class="btn-icon"
                        @click="edit(item.id)"
                        v-access:[$store.state.user.permissions]="{
                          access: accessManager.EDIT,
                          code: item.access,
                        }"
                      >
                        <FontAwesomeIcon
                          class="iconStyle"
                          icon="fa-light fa-pen"
                        />
                      </button>
                      <div
                        v-access:[$store.state.user.permissions]="{
                          access: accessManager.EDIT,
                          code: item.access,
                        }"
                      >
                        <SwitchTypeComponent
                          v-if="!editing || editing !== item.id"
                          :model="moduleSwitch(item)"
                          @draft="active"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </VueDraggableNext>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4" v-if="Object.keys(pilotageMenu).length">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">Pilotage</h2>
            <FontAwesomeIcon
              class="menu__icon"
              icon="fa-light fa-clipboard-list-check"
            />
          </div>
          <div class="widget-content">
            <div class="menu -pilotage">
              <router-link
                v-for="item in pilotageMenu"
                :key="item.id"
                :to="{ name: item.pathName }"
                class="menu__item menuStyle bgStyle -pilotage"
              >
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="widget widget-summary-1 accordion" id="statistic-block">
          <div
            class="widget-heading collapsed"
            id="statistic-toggle"
            :aria-expanded="false"
            data-bs-target="#collapse-statistic"
            data-bs-toggle="collapse"
            aria-controls="collapse-statistic"
            role="tab"
          >
            <h2 class="textStyle">Statistiques</h2>
            <FontAwesomeIcon class="chevron" icon="fa-light fa-chevron-up" />
          </div>
          <div
            id="collapse-statistic"
            class="collapse"
            aria-labelledby="collapse-statistic"
            data-bs-parent="#statistic-block"
          >
            <div class="widget-content">
              <NumberCardChart :stats="stats" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="!Object.keys(menu).length || loading" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import NumberCardChart from "@/components/charts/NumberCardChart.vue";
import { mapState } from "vuex";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { accessManager } from "@/services/security/access-manager";

export default {
  name: "StudeaManagerHomeView",
  data() {
    return {
      editing: null,
      title: null,
      loading: false,
      stats: [
        {
          label: "Utilisateurs sur promotions actives",
          datas: [
            {
              label: "Utilisateurs",
              total: 0,
              connected: 0,
              unconnected: 0,
              columns: "col-lg-6",
            },
            {
              label: "Apprentis",
              total: 0,
              connected: 0,
              unconnected: 0,
              download: true,
              columns: "col-lg-6",
            },
            {
              label: "Tuteurs",
              total: 0,
              connected: 0,
              unconnected: 0,
              download: true,
              columns: "col-lg-6",
            },
            {
              label: "Maîtres d'apprentissage",
              total: 0,
              connected: 0,
              unconnected: 0,
              download: true,
              columns: "col-lg-6",
            },
            {
              label: "Taux de remplissage des livrets",
              total: 0,
              unit: "%",
              columns: "col-lg-12",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      menu: (state) => state.general.menu,
      configMenu: (state) => state.general.configMenu,
      moduleMenu: (state) => state.general.moduleMenu,
      pilotageMenu: (state) => state.general.pilotageMenu,
      trainingCourse: (state) => state.trainingCourse.trainingCourse,
      design: (state) => state.platform.design,
    }),
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    moduleSwitch(module) {
      return {
        vars: {
          name: "active-" + module.id,
          id: "active-" + module.id,
          block_prefixes: ["", "switch"],
          value: !module.disabled,
          objectId: module.id,
        },
      };
    },

    moduleTitle(module) {
      return {
        vars: {
          name: "title-" + module.id,
          id: "title-" + module.id,
          block_prefixes: ["", "text"],
          value: module.title,
          objectId: module.id,
        },
      };
    },

    updateTitle(value) {
      this.title = value;
    },

    submitTitle(e, visible) {
      e.preventDefault();
      this.loading = true;
      studeaManagerManager
        .editModule(this.$route.params.trainingCourseId, this.editing, {
          title: this.title,
          visible: visible,
        })
        .then(() => {
          this.title = null;
          this.editing = null;
          this.loading = false;
        });
    },

    redirectToModulePage(item) {
      return ![
        "STUDEA_MANAGER_NOTEPAD",
        "STUDEA_MANAGER_REGISTRATION",
        "STUDEA_MANAGER_NOTE",
      ].includes(item.access);
    },

    active(model) {
      studeaManagerManager.editModule(
        this.$route.params.trainingCourseId,
        model.vars.objectId,
        { visible: model.vars.value },
      );
    },

    sortModule(event) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        this.loading = true;
        studeaManagerManager
          .sort(
            "training-course-module-triad",
            this.$route.params.trainingCourseId,
            id,
            position,
          )
          .then(() => {
            this.$store.dispatch("fetchStudeaManagerMenu", {
              trainingCourseId: this.$route.params.trainingCourseId,
              refresh: false,
            });
            this.loading = false;
          });
      }
    },

    edit(id) {
      this.editing = id;
      setTimeout(() => {
        const input = document.getElementById("title-" + id);
        input.focus();
      }, 20);
    },
  },
  components: {
    VueDraggableNext,
    InputTypeComponent,
    SwitchTypeComponent,
    BlockLoader,
    NumberCardChart,
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &.-pilotage {
    flex-direction: column;
    align-items: flex-end;

    @include mobile {
      align-items: flex-start;
    }
  }

  &__item {
    width: 50%;
    padding: 15px 15px 15px 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    position: relative;
    animation: all 300ms ease-in-out;
    border-radius: 6px;

    .icon {
      margin-right: 10px;
      font-size: 20px;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.-module {
      justify-content: space-between;
      padding: 0 8px 0 0;
    }

    &.-pilotage {
      padding: 15px;
      justify-content: flex-end;
      text-align: right;
      width: 75%;

      @include mobile {
        justify-content: flex-start;
        text-align: start;
      }
    }

    &:hover {
      background: var(--primary-color);
      color: $white;

      .menu__link {
        color: $white;
      }
    }
  }

  &__link {
    padding: 15px 15px 15px 50px;
    border-radius: 6px;
    display: block;
    width: 100%;
    color: var(--primary-color);

    &:hover {
      color: $white;
    }

    &.-disabled {
      font-style: italic;
      color: $m-color_5;
    }

    svg {
      margin-right: 10px;
      font-size: 20px;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__icon {
    font-size: 30px;
  }

  &__input {
    padding: 8px 15px 8px 50px;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    svg {
      margin-right: 10px;
      font-size: 20px;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    button svg {
      position: relative;
      top: initial;
      transform: none;
      left: initial;
    }
  }

  &__switch {
    display: flex;
    align-items: center;
  }
}

.sortable-ghost {
  border: 1px solid var(--primary-color);
}
</style>
