<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-0">
        <div class="sticky">
          <TriadMemberBlock />
        </div>
      </div>
      <div class="col-lg-9 order-1 order-lg-1">
        <div v-if="Object.keys(webLinks).length" class="widget">
          <div class="widget-heading">
            <h2 class="textStyle text-capitalize">{{ $t("Useful links") }}</h2>
          </div>
          <div class="widget-content">
            <SimpleListComponent
              :actions="actions"
              :datas="webLinks"
              picture="logoFile"
              href="url"
              mime="mime_type"
              titleKey="name"
            />
          </div>
        </div>
        <div v-else>
          {{
            $tc("global.empty.dashboard", 1, {
              name: this.$tc("studea_manager.webLink.title", 2).toLowerCase(),
            })
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleListComponent from "@/components/table/SimpleListComponent.vue";
import { mapState } from "vuex";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";

export default {
  name: "LessonView",
  data() {
    return {
      watching: false,
      pageTitle: "",
      actions: [
        {
          translation: "Watch",
          icon: "eye",
          type: "primary",
          action: "lesson",
          cfaStyle: true,
          actionType: "externalLink",
          url: "url",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      webLinks: (state) => state.triad.webLinks,
      design: (state) => state.platform.design,
    }),
  },
  watch: {
    weblinks() {
      trainingCourseManager.setPageTitle(
        trainingCourseManager.MODULE_STUDEA_WEBLINKS,
      );
    },
  },
  methods: {},
  created() {
    trainingCourseManager.setPageTitle(
      trainingCourseManager.MODULE_STUDEA_WEBLINKS,
    );
  },
  components: {
    TriadMemberBlock,
    SimpleListComponent,
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
}
</style>
