export default {
  studea_manager: {
    navigation: {
      general: "Général",
      modules: "Modules",
      pilotage: "Pilotage",
    },
    home: {
      title: "Configuration de la promotion",
    },
    questionnaire: {
      add: {
        title: "Créer un questionnaire",
        success: "Questionnaire ajouté avec succès !",
      },
      finish: {
        title: "Terminer ma configuration",
      },
      edit: {
        title: "Modifier un questionnaire",
        success: "Questionnaire modifié avec succès !",
      },
      sort: {
        success: "Ordre des questionnaires mis à jour avec succès !",
      },
      copy: {
        success: "Questionnaire copié avec succès !",
      },
      delete: {
        title: "Supprimer ce questionnaire",
        sub_title: "Êtes-vous sûr de vouloir supprimer ce questionnaire",
        success: "Questionnaire supprimé avec succès !",
      },
      empty_title:
        "Pour commencer, veuillez ajouter un titre, une question ou une grille de réponse !",
      add_question: "Ajouter une question",
      add_title: "Ajouter un titre",
      add_question_cluster: "Ajouter une grille de questions",
      required: "Obligatoire",
      question_settings: "Paramètre de la question",
      question_access: "Paramètre des accès",
      cluster_settings: "Paramètre de la grille",
      remove_question: "Supprimer la question",
      remove_cluster: "Supprimer la grille",
      remove_title: "Supprimer le titre",
      duplicate_cluster: "Dupliquer la grille",
      duplicate_question: "Dupliquer la question",
      tip: "Infobulle",
      display_mode: "Grille de réponse",
      save: "Enregistrer",
      save_success: "Questionnaire enregistré avec succès !",
      informations: "Informations",
      deadlines_reminders: "Échéance & relances",
      advanced_parameter: "Paramètres avancés",
      company_visit: "Visite en entreprise",
      summary: "Synthèse",
      add_column: "Ajouter une colonne",
      add_row: "Ajouter une ligne",
      row_settings: "Paramétrer les signataires",
      duplicate_row: "Dupliquer la ligne",
      remove_row: "Supprimer la ligne",
      remove_column: "Supprimer la colonne",
      empty_line: "Ligne sans titre",
      empty_column: "Titre de la colonne",
      remove_row_warning: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
      remove_column_warning:
        "Êtes-vous sûr de vouloir supprimer cette colonne ?",
      untitled_questionnaire: "Formulaire sans titre",
      placeholder_description: "Ajouter votre description",
      access_profil: "Profils répondant à la question",
      opened_question_access: "Accès au questions ouvertes",
      opened_questions: "Questions ouvertes",
      opened_questions_type: "Type de questions ouvertes",
      grid_title: "Titre de la grille",
      comments_enabled: "Commentaires autorisés",
      comments_label: "Intitulé des commentaires",
      comments_type: "Type de champs commentaires",
      one_line: "Une seule ligne",
      multi_line: "Multiligne",
      maximal_mark: "Note maximum",
      display_mark: "Utiliser les notes",
      display_radar: "Afficher le radar",
      question_title: "Titre de la question",
      infobulle_content: "Contenu de l'infobulle",
      field_type_choose: "Choisissez un type",
      short_answer: "Réponse courte",
      long_answer: "Réponse longue",
      textarea: "Zone de texte",
      number: "Nombre",
      dropdown_list: "Liste déroulante",
      unique_choice: "Choix unique",
      multiple_choices: "Choix multiples",
      file: "Fichier",
      date: "Date",
      date_time: "Date et heure",
      remove_question_warning:
        "Êtes-vous sûr de vouloir supprimer cette question ?",
      option: "Option",
      untitled_cluster: "Grille sans titre",
      untitled_title: "Title",
      title: "Titre",
      remove_cluster_warning:
        "Êtes-vous sûr de vouloir supprimer cette grille ?",
      value: "Valeur",
      choose_option: "Choisissez une option",
      questionnaire_settings: "Paramétrage du questionnaire",
      confirm: "Confirmer",
      cancel: "Annuler",
      rights: "Droits",
      notes_summary: "Notes et synthèse",
      option_placeholder: "Option",
      value_placeholder: "Note",
      comment_placeholder: "Ajouter un commentaire",
    },
    category: {
      add: {
        title: "Créer une catégorie",
        success: "Catégorie ajouté avec succès !",
      },
      edit: {
        title: "Modifier une catégorie",
        success: "Catégorie modifié avec succès !",
      },
      sort: {
        success: "Ordre des catégories mis à jour avec succès !",
      },
      copy: {
        success: "Catégorie copiée avec succès !",
      },
      delete: {
        title: "Supprimer cette catégorie",
        sub_title: "Êtes-vous sûr de vouloir supprimer cette categorie",
        success: "Catégorie supprimée avec succès !",
      },
    },
    skill: {
      list: {
        title: "Configuration des compétences",
      },
      add: {
        title: "Créer une compétence",
        success: "Compétence ajoutée avec succès !",
      },
      edit: {
        title: "Modifier une compétence",
        success: "Compétence modifiée avec succès !",
      },
      sort: {
        success: "Ordre des compétences mis à jour avec succès !",
      },
      copy: {
        success: "Compétence copiée avec succès !",
      },
      delete: {
        title: "Supprimer cette compétence",
        sub_title: "Êtes-vous sûr de vouloir supprimer cette compétence",
        success: "Compétence supprimée avec succès !",
      },
      actions: {
        skill_actions: "Gérer les compétences",
        import_export_actions: "Import/Export",
        reminder_actions: "Relances",
      },
      name: "Nom",
      level: "Niveau requis",
    },
    skill_configuration: {
      title: "Configuration du module",
      success: "Configuration mis à jour avec succès !",
    },
    skill_generate: {
      title: "Générer un questionnaire à partir des compétences",
      success: "Questionnaire généré avec succès !",
    },
    skill_remove: {
      title: "Supprimer le référentiel (sans les questionnaires)",
      sub_title: "Êtes-vous sûr de vouloir supprimer le référentiel",
      success: "Référentiel supprimé avec succès !",
    },
    skill_import: {
      title: "Importer vos compétences",
      success: "Compétences importées avec succès !",
    },
    skill_import_france_competence: {
      title: "Importer le référentiel France Compétences",
      confirm:
        "Êtes-vous sûr de vouloir charger le référentiel France Compétences sur cette formation ?",
      success: "Référentiel importé avec succès !",
    },
    skill_export: {
      title: "Exporter les compétences",
      success: "Compétences exportées avec succès !",
    },
    skill_export_taught: {
      title: "Exporter les matières",
      success: "Matières exportées avec succès !",
    },
    skillCategory: {
      add: {
        title: "Créer une catégorie",
        success: "Catégorie ajouté avec succès !",
      },
      edit: {
        title: "Modifier une catégorie",
        success: "Catégorie modifié avec succès !",
      },
      sort: {
        success: "Ordre des catégories mis à jour avec succès !",
      },
      copy: {
        success: "Catégorie copiée avec succès !",
      },
      delete: {
        title: "Supprimer cette catégorie",
        sub_title: "Êtes-vous sûr de vouloir supprimer cette categorie",
        success: "Catégorie supprimée avec succès !",
      },
    },
    assiduite: "Gestion de l'assiduité",
    subscriptions_dashboard: "Tableau de bord des inscriptions",
  },
};
